<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import Close from "../Icons/Close.vue";
import Previous from "../Icons/Previous.vue";
import { computed } from "vue";

const { t } = useI18n();
const emit = defineEmits(["leave", "previous"]);

const props = defineProps<{
    step?: number; // Étape du tunnel (numéro de l'écran desktop)
    intermediateStep?: number | null; // Étape sous écran mobile
}>();

const stepLabel = computed(() => {
    let label = "";

    switch (props.step) {
        case 1:
            label = t("tunnel.breadcrumbs.profil");
            break;
        case 2:
            label = t("tunnel.breadcrumbs.profil");
            break;
        case 3:
            label = t("tunnel.breadcrumbs.simulation");
            break;
        default:
            break;
    }

    return label;
});
</script>

<template>
    <div class="simulator__header">
        <div class="simulator__header__extremity">
            <button
                type="button"
                class="simulator__header__prev mobile-only"
                @click="() => emit('previous')"
                v-if="step == 1 && intermediateStep && intermediateStep > 1"
            >
                <Previous />
            </button>

            <img
                src="../../../images/joinsteer-logo.svg"
                class="simulator__header__logo mobile-hidden"
                alt="Joinsteer Logo"
                width="78"
                height="39"
            />
        </div>

        <div class="simulator__header__center">
            <img
                src="../../../images/joinsteer-logo.svg"
                class="simulator__header__logo mobile-only"
                alt="Joinsteer Logo"
            />

            <div class="simulator__breadcrumbs" v-if="step && step < 4">
                <span class="current-step">
                    <span class="current-step__active">
                        {{ t("tunnel.breadcrumbs.step") }}
                        {{ step >= 2 ? step - 1 : step }}
                    </span>
                    <span class="current-step__total"> /2 </span>
                </span>

                <span class="current-step__separator">|</span>

                {{ stepLabel }}
            </div>
        </div>

        <div class="simulator__header__extremity -reverse">
            <button
                class="simulator__header__leave-button"
                @click="() => emit('leave')"
            >
                <Close class="mobile-only" />
                <span class="mobile-hidden"> {{ t("actions.leave") }}</span>
            </button>
        </div>
    </div>
</template>
<style lang="scss">
.current-step {
    display: flex;
    align-items: flex-end;
    margin-right: 0.5rem;

    &__active {
        font-weight: 600;
    }
    &__separator {
        opacity: 0.2;
        margin-right: 0.5rem;
    }
}
.simulator {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.5rem;
        @include medium-up {
            padding: 0 2.5rem;
            background: white;
            grid-column: 1 / span 2;
        }

        &__center {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
        }

        &__extremity {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 80px;

            &.-reverse {
                justify-content: flex-end;
            }
        }

        &__prev {
            background: transparent;
            border: none;
            color: $dark-font;

            &:focus {
                outline: none;
                border: none;
            }

            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
        &__leave-button {
            background: $grey-card;
            border: none;
            padding: 0.25rem 0.5rem;
            font-weight: 600;
            color: $dark-font;
            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        &__logo {
            width: 78px;
            height: 39px;
        }
    }

    &__breadcrumbs {
        display: flex;
        align-items: center;

        @include medium-down {
            display: none;
        }

        &__item {
            margin-right: 0.5rem;
            font-weight: 400;
            opacity: 0.4;

            &.-past {
                font-weight: 400;
                opacity: 1;
            }

            &.-active {
                font-weight: 600;
                opacity: 1;
            }
        }

        &__arrow {
            rotate: 180deg;
            margin-right: 0.5rem;
        }
    }
}
</style>
