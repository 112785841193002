<script lang="ts" setup>
import { Article } from "@/types";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

const duration = 4000;

defineProps<{
    items: Article[];
}>();
</script>
<template>
    <Carousel
        class="pressCarousel"
        :autoplay="duration"
        :wrap-around="items.length > 1"
    >
        <Slide v-for="article in items" :key="article.extract">
            <a class="pressCarousel__item" :href="article.link">
                <img :src="article.logo_url" alt="logo" />
                <p>{{ article.extract }}</p>
            </a>
        </Slide>

        <template #addons>
            <Pagination class="pressCarousel__pagination" />
        </template>
    </Carousel>
</template>
<style lang="scss" scoped>
.pressCarousel {
    --vc-pgn-width: 8px;
    --vc-pgn-height: 8px;
    --vc-pgn-border-radius: 8px;
    --vc-pgn-background-color: #ffffff80;
    --vc-pgn-active-color: white;
    --vc-nav-background: #{$primary};
    --vc-pgn-margin: 0 3px;

    &__item {
        text-align: left;
        text-decoration: none;

        img {
            max-width: 260px;
            max-height: 100px;
            @media (max-width: $breakpoint-m) {
                max-width: 160px;
                max-height: 60px;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
        }
        @include medium-up {
            display: grid;
            align-items: center;
            column-gap: 2rem;
            grid-template-columns: 1fr 2fr;

            img {
                width: auto;
            }
        }
    }
}
</style>

<style lang="scss">
.pressCarousel__pagination {
    padding: 0;
    @include medium-up {
        display: none;
    }
}
</style>
