import { ref } from "vue";
export const useTimeout = () => {
    const timeout = ref<number | null>(null);

    const cancelTimeout = () => {
        if (timeout.value) {
            window.clearTimeout(timeout.value);
            timeout.value = null;
        }
    };

    const launchTimeout = (delay: number, callback: () => void) => {
        cancelTimeout();
        timeout.value = window.setTimeout(callback, delay);
    };

    return {
        launchTimeout,
        cancelTimeout,
    };
};
