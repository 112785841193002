<script lang="ts" setup>
import { computed } from "vue";
import Joinsteer from "@/Components/Icons/Joinsteer.vue";

type Item = {
    id: number;
};

const props = withDefaults(
    defineProps<{
        itemNumber?: number;
    }>(),
    {
        itemNumber: 8,
    },
);

const items = computed<Item[]>(() =>
    [...Array(props.itemNumber * 2)].map((_, id) => ({
        id,
    })),
);
</script>
<template>
    <div class="banner">
        <div class="banner__content">
            <div
                v-for="item in items"
                :key="item.id"
                :aria-hidden="item.id !== 0"
            >
                <Joinsteer />
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
$gap: 10vw;
@keyframes infiniteScroll {
    to {
        transform: translateX(calc(-50% - math.div($gap, 2)));
    }
}

.banner {
    background: $dark-bg;
    width: 100%;
    overflow: hidden;
    padding: clampGenerator(1, 2, 40, 120) 0;
    &__content {
        display: flex;
        width: max-content;
        justify-content: space-around;
        gap: $gap;
        animation: infiniteScroll 20s infinite linear;
        & > * {
            flex-grow: 1;
        }
        svg {
            width: clampGenerator(7.5, 14.375, 40, 120);
            color: white;
            height: clampGenerator(0.625, 1.875, 40, 120);
        }
    }
}
</style>
