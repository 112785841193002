<script lang="ts" setup>
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { Vehicle } from "@/types";

const props = defineProps<{ vehicle: Vehicle }>();

const toast = useToast();
const { t } = useI18n();

const copyLink = () => {
    navigator.clipboard.writeText(props.vehicle.url);
    toast.info(t("toast.urlCopied"));
};

const fbMessengerLink = computed(() => {
    return `fb-messenger://share/?link=${props.vehicle.url}`;
});

const whatsappLink = computed(() => {
    return `whatsapp://send?text=${props.vehicle.url}`;
});

const emailLink = computed(() => {
    return `mailto:?subject=${props.vehicle.display_name2}&body=${props.vehicle.url}`;
});
</script>

<template>
    <div class="share-panel">
        <div class="share-panel__title">
            {{ t("actions.shareVehicle") }}
        </div>

        <div class="share-panel__actions">
            <a class="share-panel__action" :href="fbMessengerLink">
                <span class="share-panel__action__icon">
                    <img
                        src="../../../images/icons/messenger.svg"
                        alt="Logo Messenger"
                    />
                </span>

                <span class="share-panel__action__label">Messenger</span>
            </a>

            <a class="share-panel__action" :href="whatsappLink">
                <span class="share-panel__action__icon">
                    <img
                        src="../../../images/icons/whatsapp.svg"
                        alt="Logo WhatsApp"
                    />
                </span>

                <span class="share-panel__action__label">WhatsApp</span>
            </a>

            <button class="share-panel__action" @click="copyLink">
                <span class="share-panel__action__icon">
                    <img src="../../../images/icons/link.svg" alt="Icon link" />
                </span>

                <span class="share-panel__action__label">{{
                    t("actions.copyLink")
                }}</span>
            </button>

            <a class="share-panel__action" :href="emailLink">
                <span class="share-panel__action__icon">
                    <img
                        src="../../../images/icons/envelope.svg"
                        alt="Logo WhatsApp"
                    />
                </span>

                <span class="share-panel__action__label">E-mail</span>
            </a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.share-panel {
    display: none;
    background: $grey-card;
    padding: 1rem;
    border-radius: 0.25rem;

    @media (max-width: $breakpoint-m) {
        display: block;
    }

    &__title {
        font-size: 1.25rem;
        font-family: $bebas;
        margin-bottom: 0.5rem;
    }

    &__actions {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    &__action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        background: transparent;
        border: none;
        text-decoration: none;
        padding: 0;
        margin: 0;

        &:focus {
            outline: none;
        }

        &__icon {
            display: flex;
            width: 3rem;
            height: 3rem;
            background: white;
            border-radius: 50%;
            margin-bottom: 0.5rem;
            justify-content: center;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
            }
        }

        &__label {
            font-size: 0.75rem;
            color: $dark-font;
        }
    }
}
</style>
