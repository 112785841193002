<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import TitleTabulatedMultiLine from "@/Components/Typography/TitleTabulatedMultiLine.vue";
import Link from "@/Components/Home/Link/Link.vue";
import { Link as InertiaLink } from "@inertiajs/vue3";
import Tag from "@/Components/Home/Tag/Tag.vue";
import Cross from "@/Components/Icons/Cross.vue";

const { t } = useI18n();
</script>

<template>
    <section
        class="section"
        data-scroll
        data-scroll-css-progress
        data-scroll-offset="0, 90%"
    >
        <header class="section__title">
            <h2>{{ t("home.accelerate.mainTitle") }} <Cross /></h2>
        </header>

        <div class="section__container container">
            <div class="section__img" data-scroll data-scroll-offset="200, 200">
                <div class="section__img__wrapper">
                    <Tag class="section__tag" :href="route('vehicles.index')">{{
                        t("home.accelerate.tags[0]")
                    }}</Tag>
                    <Tag class="section__tag" :href="route('vehicles.index')">{{
                        t("home.accelerate.tags[1]")
                    }}</Tag>

                    <picture>
                        <source
                            media="(max-width: 560px)"
                            srcset="
                                ../../../../../resources/images/home/joinsteer-accelerate-small.webp,
                                ../../../../../resources/images/home/joinsteer-accelerate-small@2x.webp 2x
                            "
                        />
                        <source
                            media="(min-width: 561px)"
                            srcset="
                                ../../../../../resources/images/home/joinsteer-accelerate.webp,
                                ../../../../../resources/images/home/joinsteer-accelerate@2x.webp 2x
                            "
                        />
                        <img
                            src="../../../../../resources/images/home/joinsteer-accelerate.webp"
                            alt=""
                            loading="lazy"
                        />
                    </picture>
                </div>
            </div>
            <div class="section__content">
                <TitleTabulatedMultiLine
                    :level="3"
                    :labels="[
                        {
                            isInterpolated: false,
                            content: t('home.accelerate.title[0]'),
                            gap: '0',
                        },
                        {
                            isInterpolated: false,
                            content: t('home.accelerate.title[1]'),
                            gap: '0',
                        },
                        {
                            isInterpolated: false,
                            content: t('home.accelerate.title[2]'),
                            gap: '0',
                        },
                    ]"
                />
                <p>
                    <b>{{ t("home.accelerate.text[0]") }}</b
                    >{{ t("home.accelerate.text[1]") }}
                </p>
                <Link
                    :as="InertiaLink"
                    :href="route('vehicles.index')"
                    class="section__link"
                    >{{ t("home.accelerate.cta") }}</Link
                >
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
html.lenis {
    .section {
        &__img {
            &.is-inview {
                a,
                p {
                    transform: translateX(0);

                    transition: 1s all ease-in-out;

                    &:last-of-type {
                        transition-delay: 0.8s;
                    }
                }
            }
            a,
            p {
                transform: translateX(-100vw);
            }
        }
    }
}
.section {
    --cross-size: #{clampGenerator(3.1875, 9.375, 40, 120)};
    --cross-line-size: #{clampGenerator(0.0625, 0.375, 40, 120)};
    background: rgba(217, 226, 227, var(--progress));
    padding: var(--hp-y-space) 0;
    --img-width-proportion: 100vw;
    --img-right: calc(calc(var(--margin-container) * -1) / 2);

    @include medium-up {
        &__container {
            display: grid;
            grid-template-columns: var(--grid-columns);
        }
    }

    &__tag {
        position: relative;
        z-index: 10;
    }

    &__title {
        svg {
            position: absolute;
            width: var(--cross-size);
            height: var(--cross-size);
            color: white;
            top: 100%;
            transform: translate(
                calc(-50% - var(--cross-line-size)),
                calc(-50% - var(--cross-line-size))
            );

            left: 0;
            z-index: 2;
        }
        h2 {
            display: inline-block;
            font-family: $bebas;
            margin: 0;
            padding: 0 1rem 1rem 1rem;
            font-style: italic;
            font-weight: 500;
            font-size: clampGenerator(3.4375, 6.25, 40, 120);
            position: relative;
            @include medium-up {
                padding: 0 3rem 2rem 3rem;
            }
        }

        text-align: center;
        text-transform: uppercase;
    }
    &__img {
        img {
            width: 100%;
        }
        position: relative;

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            height: calc(374 * var(--img-width-proportion) / 667);

            picture {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: var(--img-width-proportion);
                right: var(--img-right);
            }
        }
    }

    &__content {
        p {
            line-height: var(--description-line-height);
        }
        > :not(:last-child) {
            margin-top: 0;
            margin-bottom: 2rem;
        }
    }

    @include medium-up {
        --img-width-proportion: 50vw;
        --img-right: -2rem;

        &__content {
            grid-column: 3/4;

            align-self: center;
        }
    }

    @include medium-down {
        &__content {
            padding: var(--hp-y-space) 0;
            text-align: center;
            > * {
                text-align: left;
            }
        }
    }
}
</style>
