<script lang="ts" setup>
import { PropType } from "vue";
type HTMLInputTypeAttribute =
    | "date"
    | "datetime-local"
    | "email"
    | "hidden"
    | "month"
    | "number"
    | "password"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";

const props = defineProps({
    modelValue: {
        type: [String, Number],
        required: false,
        default: null,
    },
    isNumber: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: "",
    },
    required: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: "",
    },
    type: {
        type: String as PropType<HTMLInputTypeAttribute>,
        default: "text",
    },
    unit: {
        type: String,
        default: "",
    },
    hasError: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits<{
    (e: "update:modelValue", value: string): void;
}>();

const updateModelValue = (event: Event) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value = (event.target as HTMLInputElement).value as any;

    if (props.isNumber && value !== "") {
        value = parseFloat(value);
    }

    emit("update:modelValue", value);
};

const checkModelValue = (value) => {
    return !isNaN(value);
};

const isControlKey = (event) => {
    const controlKeys = [
        "Backspace",
        "Tab",
        "Enter",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Delete",
    ];
    return controlKeys.includes(event.key);
};

const handleKeydown = (event) => {
    if (props.isNumber && !isControlKey(event) && !checkModelValue(event.key)) {
        event.preventDefault();
    }
};
</script>

<template>
    <div
        class="input-inner-label"
        :class="{ 'input-inner-label--error': hasError }"
    >
        <span class="input-inner-label__unit">{{ unit }}</span>
        <label class="input-inner-label__label">{{ label }}</label>
        <input
            class="input-inner-label__input"
            :type="type"
            :value="modelValue"
            @input="updateModelValue"
            @keydown="handleKeydown"
            :placeholder="placeholder"
        />
    </div>
</template>

<style scoped lang="scss">
.input-inner-label {
    position: relative;

    &__label {
        position: absolute;
        top: 0.75rem;
        left: 0.75rem;
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.75rem;
        font-weight: 400;
    }

    &__unit {
        position: absolute;
        font-size: 0.75rem;
        right: 0.5rem;
        bottom: 1rem;
    }

    &__input {
        border: 1px solid $light-bg;
        border-radius: 4px;
        padding: 2.125rem 1.75rem 0.75rem 0.75rem;
        width: 100%;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: textfield;

        color: #000;
        font-size: 1rem;
        font-weight: 500;
        transition: border-color 0.3s ease-in-out;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            outline: none;
            border-color: $dark-font;
        }
    }
}

.input-inner-label--error {
    .input-inner-label {
        &__input {
            border-color: red;
        }

        &__label {
            color: red;
        }
    }
}
</style>
