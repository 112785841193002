<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_1_52"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="18"
            height="18"
        >
            <rect width="18" height="18" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1_52)">
            <path
                d="M17.1475 18.3899L18.3899 17.1475L10.4597 9.21734L9.21734 10.4597L17.1475 18.3899Z"
                fill="currentColor"
            />
            <path
                d="M7.89859 9.14098L9.14097 7.89859L1.21077 -0.0316124L-0.031617 1.21077L7.89859 9.14098Z"
                fill="currentColor"
            />
            <path
                d="M18.3899 1.21077L17.1475 -0.0316124L9.21734 7.89859L10.4597 9.14098L18.3899 1.21077Z"
                fill="currentColor"
            />
            <path
                d="M9.14097 10.4597L7.89859 9.21734L-0.0316162 17.1475L1.21077 18.3899L9.14097 10.4597Z"
                fill="currentColor"
            />
        </g>
    </svg>
</template>
