<script setup lang="ts">
import { Question } from "@/types";
import AccordionList from "@/Components/Accordions/AccordionList.vue";

const props = defineProps<{
    items: Question[];
}>();
</script>

<template>
    <div class="container faq landing-make">
        <h2 class="landing-make__title">FAQ Joinsteer</h2>

        <AccordionList
            :items="props.items"
            :is-show-more="true"
            icon-color-closed="#AAB2B2"
        />
    </div>
</template>
