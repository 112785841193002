<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { Question } from "@/types";
import Accordion from "@/Components/Accordions/Accordion.vue";
import {
    DEFAULT_ACCORDION_BORDER_COLOR,
    DEFAULT_ACCORDION_ICON_COLOR,
    DEFAULT_ACCORDION_ICON_COLOR_CLOSED,
    DEFAULT_ACCORDION_TEXT_COLOR,
    DEFAULT_ACCORDION_TEXT_OPACITY,
    DEFAULT_ACCORDION_TITLE_COLOR,
} from "@/constants/components";
const { t } = useI18n();

const props = withDefaults(
    defineProps<{
        items: Question[];
        displayIndex?: boolean | null;
        isShowMore?: boolean;
        iconColor?: string;
        iconColorClosed?: string;
        borderColor?: string;
        titleColor?: string;
        textColor?: string;
        textOpacity?: number;
    }>(),
    {
        displayIndex: false,
        isShowMore: false,
        iconColor: DEFAULT_ACCORDION_ICON_COLOR,
        iconColorClosed: DEFAULT_ACCORDION_ICON_COLOR_CLOSED,
        borderColor: DEFAULT_ACCORDION_BORDER_COLOR,
        titleColor: DEFAULT_ACCORDION_TITLE_COLOR,
        textColor: DEFAULT_ACCORDION_TEXT_COLOR,
        textOpacity: DEFAULT_ACCORDION_TEXT_OPACITY,
    },
);

const isShowingMore = ref(false);

onMounted(() => {
    isShowingMore.value = props.isShowMore;
});
</script>

<template>
    <div class="accordions">
        <div class="accordions__list">
            <Accordion
                v-for="(item, index) in isShowingMore
                    ? items.slice(0, 3)
                    : items"
                :key="index"
                icon="cross"
                :icon-color="iconColor"
                :icon-color-closed="iconColorClosed"
                :border-color="borderColor"
                :title-color="titleColor"
            >
                <template #header>
                    <span class="accordion__index" v-if="displayIndex">
                        {{ index + 1 }}
                    </span>
                    <h3>{{ item.question }}</h3>
                </template>
                <template #default>
                    <p v-html="item.answer" class="accordion__answer"></p>
                </template>
            </Accordion>
        </div>
        <button
            v-if="items.length > 3 && isShowMore"
            class="accordions__actions"
            @click="isShowingMore = !isShowingMore"
        >
            {{
                isShowingMore
                    ? t("actions.showingMore")
                    : t("actions.showingLess")
            }}
        </button>
    </div>
</template>

<style lang="scss" scoped>
.accordion__answer {
    padding: 0;
    margin: 0;
    line-height: 1.5rem;
    opacity: v-bind("textOpacity");
    color: v-bind("textColor");
}

.accordion__index {
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    color: $primary;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.3;
    background-color: $dark-font;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin-right: 1rem;
}

.accordions {
    &__list {
        display: grid;
        row-gap: 2rem;
    }

    &__actions {
        appearance: none;
        border: none;
        background: transparent;
        font-weight: 700;
        line-height: normal;
        text-decoration-line: underline;
        margin-top: 1rem;
    }
}

h3 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 0;
    padding: 0;
    @media (max-width: $breakpoint-l) {
        font-size: 1.125rem;
    }
}
</style>
