<template>
    <svg
        width="28"
        height="13"
        viewBox="0 0 28 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.469 0.319824L13.769 7.90882L7.025 0.319824H0V0.764824L11.206 12.9998H16.25L27.59 0.764824V0.319824H20.469Z"
            fill="currentColor"
        />
    </svg>
</template>
