<script setup lang="ts">
import { watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
    modelValue?: number;
    step: number;
    showButtons: boolean;
    classic: boolean;
    minFirstRent: number | null;
}>();

const { inputRef, formattedValue, setValue } = useCurrencyInput({
    currency: "EUR",
    hideCurrencySymbolOnFocus: false,
    hideGroupingSeparatorOnFocus: false,
    precision: 0,
    valueRange: { min: props.minFirstRent ?? 500 },
});

const decrease = () => {
    setValue((props.modelValue || 0) - props.step);
};

const increase = () => {
    setValue((props.modelValue || 0) + props.step);
};

watch(
    () => props.modelValue,
    (value) => {
        if (value != 0)
            setValue(value || 0);
    },
);
</script>

<template>
    <div :class="{ 'currency-field': showButtons && !classic }">
        <button
            @click="decrease"
            class="currency-field__button"
            type="button"
            v-if="showButtons"
        >
            <img src="../../../../images/icons/minus-algolia.svg" alt="Minus" class="currency-field__icon" />
        </button>
        <input
            :placeholder="t('calculator.pricePlaceholder')"
            ref="inputRef"
            type="text"
            v-model="formattedValue"
            class="currency-field__input"
            :class="{ '-classic': classic }"
        />
        <button
            @click="increase"
            class="currency-field__button"
            type="button"
            v-if="showButtons"
        >
            <img src="../../../../images/icons/plus-algolia.svg" alt="Plus" class="currency-field__icon" />
        </button>
    </div>
</template>

<style lang="scss">
.currency-field {
    display: flex;
    gap: 4px;

    &__input {
        flex-grow: 1;
        border: none;
        background: $grey-card;
        text-align: center;
        padding: 0.875rem;
        min-width: 0;
        border-radius: 0;

        &:focus {
            border: none;
            outline: none;
        }

        &.-classic {
            background: white;
            font-family: $bebas;
            font-size: 1rem;
            border: 1px solid $grey-input;
            border-radius: 4px;
            box-sizing: border-box;
            width: 100%;
            max-height: 3rem;
            padding: 0.75rem;
            letter-spacing: 1px;
            text-align: left;
        }
    }

    &__button {
        flex-shrink: 0;
        width: 48px;
        background: $grey-card;
        border: none;
        box-shadow: none;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        touch-action: manipulation;
        padding: 0;

        &:hover {
            cursor: pointer;
            background: $primary;

            .currency-field__icon {
                filter: invert(1);
            }
        }

        &:focus {
            border: none;
            outline: none;
        }
    }

    &__icon {
        width: 18px;
        height: 18px;
    }
}
</style>
