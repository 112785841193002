<script setup lang="ts">
import Rating from "@/Components/Rating.vue";
import { Review, VehicleMake } from "@/types";
import ReviewItem from "@/Components/Sections/Review/ReviewItem.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

defineProps<{
    vehicleMake?: VehicleMake;
    review?: Review;
    itemSeo: string;
}>();
</script>

<template>
    <section class="reviewList" v-if="review">
        <div class="container">
            <h2 class="reviewList__title">
                {{
                    t("sections.reviews.title", {
                        make: itemSeo ?? "Joinsteer",
                    })
                }}
            </h2>

            <div class="reviewList__wrapper">
                <div class="reviewList__intro">
                    <div class="reviewList__intro__text">
                        <p>
                            {{ t("sections.reviews.description") }}
                        </p>
                    </div>
                    <Rating />
                </div>

                <div class="reviewList__review">
                    <ReviewItem :review="review" />
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
.reviewList {
    padding: clampGenerator(2, 4) 0;
    background-color: #000000;
    color: #ffffff;

    &__title {
        margin: 0 0 1rem 0;
        font-family: $bebas;
        font-weight: 700;
        font-size: clampGenerator(1.875, 2);
        line-height: clampGenerator(2.22, 2.375);
        text-transform: uppercase;
        white-space: pre-line;
    }

    &__wrapper {
        display: grid;
        row-gap: 4rem;
    }

    &__intro {
        position: relative;
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2rem;
            width: 100%;
            height: 1px;
            background-color: #ffffff;
        }

        &__text {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        .rating {
            width: fit-content;
            margin: 2rem 0 0 0;
        }
    }

    @media (min-width: 76rem) {
        &__review {
            .reviewItem {
                display: flex;
                gap: clampGenerator(3, 5);
                margin-top: 1rem;

                .reviewItem__customer {
                    flex-shrink: 0;
                }
            }
        }
    }

    @media (max-width: 76rem) {
        &__review {
            .reviewItem__review {
                margin-top: 1.5rem;
            }
        }
    }

    @media (min-width: $breakpoint-l) {
        &__wrapper {
            grid-template-columns: 1fr 1fr;
            column-gap: 8rem;
        }

        &__intro {
            &:after {
                width: 1px;
                height: 100%;
                top: 0;
                bottom: 0;
                left: auto;
                right: -4rem;
            }
        }
    }
}
</style>
