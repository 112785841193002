<template>
    <div class="loader">
        <div class="loader__dot"></div>
        <div class="loader__dot"></div>
        <div class="loader__dot"></div>
    </div>
</template>
<style lang="scss" scoped>
@keyframes dot-loader {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-0.25rem);
    }
}
.loader {
    display: flex;
    gap: 0.25rem;
    &__dot {
        --message-animation-delay: 0s;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        background: $light-bg;
        animation: dot-loader 0.6s linear reverse infinite
            var(--message-animation-delay);
        &:nth-child(2) {
            --message-animation-delay: 0.2s;
        }
        &:nth-child(3) {
            --message-animation-delay: 0.4s;
        }
    }
}
</style>
