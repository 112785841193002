<script setup lang="ts">
import NoMoreConcessions from "@/Components/Sections/NoMoreConcessions.vue";
import Faq from "@/Components/Sections/Faq.vue";
import Story from "@/Components/Sections/Story.vue";
import Press from "@/Components/Sections/Press/Press.vue";
import TravelCompanion from "@/Components/Sections/TravelCompanion.vue";
import Concept from "@/Components/Sections/Concept.vue";
import {
    Article,
    DynamicContent,
    Question,
    Review,
    Vehicle,
    VehicleMake,
    VehicleModel,
} from "@/types";
import ReviewList from "@/Components/Sections/Review/ReviewList.vue";
import { PropType } from "vue";
import VehiclesOffer from "@/Components/Sections/VehiclesOffer.vue";
import TopOffer from "@/Components/Sections/TopOffer.vue";
import { usePage } from "@inertiajs/vue3";

defineProps({
    isVehicleShow: {
        type: Boolean,
        default: false,
    },
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: false,
        default: undefined,
    },
    vehicleMake: { type: Object as PropType<VehicleMake>, required: true },
    articles: { type: Array as PropType<Article[]>, required: true },
    dynamicContent: {
        type: Array as PropType<DynamicContent[]>,
        required: true,
    },
    faqJoinsteer: {
        type: Array as PropType<Question[]>,
        required: true,
    },
    faqStory: {
        type: Array as PropType<Question[]>,
        required: true,
    },
    minMonthly: { type: Number, default: 0 },
    customerReview: {
        type: Object as PropType<Review>,
        required: false,
        default: undefined,
    },
    isLanding: { type: Boolean },
    topModels: {
        type: Array<VehicleModel>,
        required: false,
        default: [],
    },
    itemSeo: {
        type: String,
        required: true,
    },
});

const page = usePage();
</script>

<template>
    <div class="sectionsList">
        <Story
            v-if="isLanding"
            :vehicle-make="vehicleMake"
            :dynamic-content="dynamicContent"
            :faq-story="faqStory"
            :min-monthly="minMonthly"
            :item-seo="itemSeo"
        />

        <VehiclesOffer
            :vehicle="vehicle"
            :vehicle-make="vehicleMake"
            :item-seo="itemSeo"
            v-if="
                page.component === 'Vehicle/LandingMake' ||
                page.component === 'Vehicle/LandingModel' ||
                page.component === 'Vehicle/Show'
            "
        />

        <ReviewList
            :review="customerReview"
            :vehicle-make="vehicleMake"
            :item-seo="itemSeo"
        />

        <NoMoreConcessions :vehicle-make="vehicleMake" :item-seo="itemSeo" />

        <Press :items="articles" />

        <Concept
            :vehicle-make="vehicleMake"
            :item-seo="itemSeo"
            v-if="isLanding || isVehicleShow"
        />

        <TravelCompanion
            :vehicle-make="vehicleMake"
            v-if="isLanding || isVehicleShow"
            :item-seo="itemSeo"
        />

        <TopOffer
            :vehicle-make="vehicleMake"
            :top-models="topModels"
            :item-seo="itemSeo"
            v-if="
                page.component === 'Vehicle/LandingMake' ||
                page.component === 'Vehicle/LandingModel'
            "
        />

        <Faq
            :items="faqJoinsteer"
            v-if="faqJoinsteer && (isLanding || isVehicleShow)"
        />
    </div>
</template>

<style lang="scss">
.sectionsList {
    margin-top: clampGenerator(2, 7);

    .noMoreConcessions {
        margin-bottom: clampGenerator(2, 7);
        margin-top: clampGenerator(2, 7);
    }

    .story {
        margin-bottom: clampGenerator(2, 5);
    }

    .landing-offer {
        margin-bottom: clampGenerator(2, 5);
    }

    .top-offer {
        margin-top: clampGenerator(2, 7);
    }
}
</style>
