<script setup lang="ts">
import { useI18n } from "vue-i18n";
import Autocomplete from "../../Components/Algolia/Autocomplete.vue";
import { SearchClient } from "algoliasearch/lite";
import { usePage } from "@inertiajs/vue3";
import { LandingData } from "@/types";
import ArrowDown from "@/Components/Icons/ArrowDown.vue";
import CurrentRefinement from "@/Components/Algolia/CurrentRefinement.vue";
import { ref } from "vue";
const page = usePage();

const { t } = useI18n();

const emit = defineEmits(["filtersOpen"]);

const contentRef = ref();

defineExpose({
    contentRef,
});

defineProps<{
    searchClient: SearchClient;
    indexName: string;
    research?: string | null;
    landingData?: LandingData;
    minMonthly?: number;
}>();
</script>

<template>
    <div
        class="marketplace-header"
        :data-page="page.component"
        ref="contentRef"
    >
        <div class="marketplace-header__search-box">
            <div v-if="landingData">
                <h1 class="marketplace-header__landing">
                    <img
                        v-if="landingData['vehicleMake']['logo']"
                        :src="landingData['vehicleMake']['logo']"
                        :alt="landingData['vehicleMake']['logo']"
                        class="marketplace-header__logo"
                    />
                    <div v-if="landingData['vehicleSpecification']">
                        {{ landingData["vehicleSpecification"]["label"] }}

                        en location longue durée <br />
                        à partir de {{ minMonthly }} €/mois
                    </div>
                    <div v-else>
                        {{ landingData["vehicleModel"]["label"] }}

                        en location longue durée <br />
                        à partir de {{ minMonthly }} €/mois
                    </div>
                </h1>
                <div class="marketplace-header__landingspec">
                    <ais-stats>
                        <template #default="{ nbHits }">
                            <p class="hits-action__stats">
                                {{
                                    t("nbVehicleAvailable", {
                                        nbHits: $filters.number(nbHits),
                                        designation:
                                            landingData["vehicleModel"][
                                                "label"
                                            ],
                                    })
                                }}
                            </p>
                        </template>
                    </ais-stats>
                    <button
                        class="marketplace-header__filter"
                        @click="emit('filtersOpen')"
                        :aria-label="t('filtersOpen')"
                    >
                        Trier par
                        <ArrowDown />
                    </button>
                </div>
            </div>
            <h1 class="marketplace-header__title" v-else>
                Pour le véhicule de vos rêves, ne faites aucune concession.
            </h1>

            <div
                class="marketplace-header__search"
                v-show="page.component === 'Vehicle/Index'"
            >
                <Autocomplete
                    identifiant="autocomplete"
                    :search-client="searchClient"
                    :index-name="indexName"
                    :class="'autocomplete'"
                    :research="research"
                />
                <button
                    class="marketplace-header__filter"
                    @click="emit('filtersOpen')"
                    :aria-label="t('filtersOpen')"
                >
                    Trier par
                    <ArrowDown />
                </button>
            </div>

            <div class="marketplace-header__filters">
                <div v-if="landingData"></div>
                <CurrentRefinement :landing-data="landingData" />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.marketplace-header {
    padding: 0 1.5rem;

    @media (min-width: 50rem) {
        padding: 1.5rem;
        margin-top: -75px;

        &[data-page="Vehicle/Index"],
        &[data-page="Vehicle/LandingModelSpecification"] {
            margin-top: -95px;
        }
    }

    &__title {
        color: #fff;
        font-family: $bebas;
        font-size: 2.5rem;
        font-weight: 400;
        line-height: 1.2;
        margin: 0 0 1.5rem;
        text-transform: uppercase;

        strong {
            color: $primary;
            font-weight: 400;
        }
    }

    &__landing {
        color: #fff;
        font-family: $bebas;
        font-size: 2.5rem;
        font-weight: 400;
        line-height: 1.2;
        margin: 0 0 1.5rem;
        display: flex;
        align-items: flex-start;
        text-transform: uppercase;
        @media (max-width: $breakpoint-l) {
            display: none;
        }
    }

    &__landingspec {
        background-color: $dark-bg;
        padding: 0 1rem;
        color: #fff;
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 4px;
        > .marketplace-header__filter {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: flex-end;
            appearance: none;
            background-color: transparent;
            border: none;
            cursor: pointer;
            padding: 0.5rem;
            color: $primary;
            width: auto;
            svg {
                margin-left: 0.25rem;
            }
        }
        > .ais-Stats > .hits-action__stats {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.5;
        }
        @include medium-up {
            display: none;
        }
    }

    &__logo {
        width: 30px;
        height: auto;
        margin-right: 1rem;
        @media (max-width: $breakpoint-l) {
            width: 16px;
            height: auto;
            margin-bottom: 0.5rem;
        }
    }

    &__search {
        display: flex;
        align-items: stretch;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        width: 100%;
        border-radius: 4px;

        @media (max-width: $breakpoint-l) {
            border: 2px solid $grey-card;
        }
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 40px;
        @media (max-width: $breakpoint-l) {
            overflow-x: auto;
            min-height: auto;
        }
    }

    &__filter {
        display: block;
        appearance: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-left: 2px solid $grey-card;
        padding: 0.5rem;

        @include medium-up {
            display: none;
        }
    }

    &__close {
        display: flex;
        justify-content: flex-end;
        height: 100%;

        &-button {
            appearance: none;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }
}

.landing-filters {
    display: flex;
}
.ais-CurrentRefinements--noRefinement {
    .current-refinements.facet {
        border: none;
    }
}

.current-refinements {
    margin: 0 0 0 0;
    padding: 0;
    list-style: none;
    overflow-x: auto;
    > div {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.5rem;
        column-gap: 0.75rem;
    }

    &.facet {
        margin-bottom: 1rem;
        width: 100%;
        padding-right: 1rem;
        opacity: 0.6;
        border-bottom: 1px solid #d9dcdc;
        > div {
            width: 100%;
            display: block;
        }
    }

    @media (max-width: $breakpoint-l) {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        color: #fff;
        text-decoration: none;
        border-radius: 28px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        padding: 0.25rem 0.75rem;
        transition:
            color $transition-time,
            border-color $transition-time;

        &:hover,
        &:focus-within {
            color: $primary;
            border-color: $primary;
        }

        &.landing {
            &:hover,
            &:focus-within {
                color: #fff;
                border-color: rgba(255, 255, 255, 0.5);
            }
        }

        &__close {
            font-size: 10px;
            margin-left: 0.25rem;
        }

        @media (max-width: 50rem) {
            color: $dark-font;
            border-color: $dark-font;

            &:hover,
            &:focus-within {
                color: $dark-font;
                border-color: $dark-font;
            }
        }
    }
}

.ais-CurrentRefinements {
    display: flex;
    align-items: center;
}

.ais-SearchBox-input {
    flex-grow: 1;
    padding: 1rem 1rem 1rem calc(2rem + 24px);
    font-size: 1rem;
    line-height: 1;
    border: 1px solid #f2f5f6;
    border-radius: 4px;
    background: #fff url("../../../images/icons/search-green.svg") no-repeat
        left 1rem center;
    background-size: 24px;

    @include large-up {
        border: 1px solid #f2f5f6;
    }
}

.ais-CurrentRefinements-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.ais-CurrentRefinements-label {
    display: none;
}
.ais-CurrentRefinements-category {
    color: #fff;
    border-radius: 28px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    transition:
        color $transition-time,
        border-color $transition-time;

    &:has(.ais-CurrentRefinements-delete:hover),
    &:focus-within {
        color: $primary;
        border-color: $primary;
    }
}

.ais-CurrentRefinements-delete {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color $transition-time;

    &:hover {
        color: $primary;
    }
}

.ais-ClearRefinements-button {
    &--disabled {
        display: none;
    }
}

.ais-SearchBox {
    flex-grow: 1;
    border-radius: 4px;
    @include large-up {
        border: none;
        min-width: 50px;
        border-radius: 0;
    }
}
.ais-SearchBox-form {
    display: flex;
}
.ais-SearchBox-reset,
.ais-SearchBox-submit {
    @include hidden;
}

@media (max-width: calc(50rem - 1px)) {
    .marketplace-header__title {
        @include hidden;
    }
}

@media (min-width: 50rem) {
    .marketplace-header {
        height: 360px;
        box-sizing: border-box;
        background:
            linear-gradient(
                47deg,
                rgba(0, 0, 0, 0.5) 41.97%,
                rgba(0, 0, 0, 0) 100%
            ),
            lightgray url("../../../images/joinsteer-marketplace-header.webp")
                no-repeat center / cover;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .ais-SearchBox-input {
        border: 0;
    }
}

@include large-up {
    .marketplace-header__search-box {
        width: 50rem;
    }
}
</style>
