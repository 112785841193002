<script setup>
import { computed } from "vue";

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const filteredLinks = computed(() => {
    const links = props.data.links.filter(
        (link) =>
            !link.label.includes("Previous") && !link.label.includes("Next"),
    );

    // Trouver l'index de la page courante
    const currentIndex = links.findIndex((link) => link.active);

    // Garder seulement 2 pages avant et après la page courante
    return links.filter((link, index) => {
        const distance = Math.abs(index - currentIndex);
        return distance <= 1 || index === 0 || index === links.length - 1;
    });
});
import { Link } from "@inertiajs/vue3";
</script>

<template>
    <ul class="ais-Pagination-list" v-if="data.last_page !== 1">
        <li>
            <Link
                preserve-state
                class="ais-Pagination-link"
                :href="data.prev_page_url || '#'"
            >
                <span
                    class="ais-Pagination-item previousPage"
                    :class="{ disabled: !data.prev_page_url }"
                    >‹</span
                >
            </Link>
        </li>

        <li v-for="(link, index) in filteredLinks" :key="index">
            <Link
                :href="link.url"
                preserve-state
                class="ais-Pagination-link"
                :class="{ disabled: link.label === '...' }"
            >
                <span
                    class="ais-Pagination-item"
                    :class="{ selected: link.active }"
                    >{{ link.label }}</span
                >
            </Link>
        </li>

        <li>
            <Link
                preserve-state
                class="ais-Pagination-link"
                :href="data.next_page_url || '#'"
            >
                <span
                    class="ais-Pagination-item nextPage"
                    :class="{ disabled: !data.next_page_url }"
                    >›</span
                >
            </Link>
        </li>
    </ul>
</template>

<style lang="scss">
.ais-Pagination-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
}

.ais-Pagination-item {
    padding: 0.5rem 0.75rem;
}

@media (max-width: 640px) {
    .ais-Pagination-list {
        gap: 0.15rem;
    }

    .ais-Pagination-item {
        padding: 0.2rem 0.02rem;
        font-size: 0.875rem;
    }
}
</style>
