<script lang="ts" setup>
import { SavedSearch } from "../../types";
import Checkbox from "@/Components/Forms/Input/Checkbox.vue";
import { computed, ref, watch } from "vue";
import { isClientSide } from "@/helpers/client";
import { useI18n } from "vue-i18n";
import { useForm } from "@inertiajs/vue3";
import { useToast } from "vue-toastification";
import Button from "@/Components/Buttons/Button.vue";
import Dots from "@/Components/Icons/Dots.vue";
import Modal from "@/Modal/Modal.vue";
import TextInput from "@/Components/Forms/Input/TextInput.vue";

const toast = useToast();
const { t } = useI18n();

const props = defineProps<{ savedSearch: SavedSearch }>();

const openingAction = ref(false);
const confirmingDeleteSearch = ref(false);
const confirmingEditSearch = ref(false);

const form = useForm({
    notify_user: props.savedSearch.notify_user,
    title: props.savedSearch.title,
});

const update = () => {
    confirmingEditSearch.value = false;

    form.put(
        route("savedSearchs.update", {
            savedSearch: props.savedSearch,
        }),
        {
            onSuccess: () => toast.success(t("toast.success")),
            preserveScroll: true,
        },
    );
};

const handleClickOutsideAction = () => {
    openingAction.value = false;
};

watch(
    () => openingAction.value,
    (value) => {
        if (!isClientSide()) return;

        if (value) {
            window.addEventListener("click", handleClickOutsideAction);
        } else {
            window.removeEventListener("click", handleClickOutsideAction);
        }
    },
);

const filteredDetails = computed(() => {
    const filtered = {};
    Object.keys(props.savedSearch.details_search).forEach((key) => {
        const value = props.savedSearch.details_search[key];
        if (value !== null) {
            if (Array.isArray(value) && value.length > 0) {
                filtered[key] = value.join(", ");
            } else if (!Array.isArray(value)) {
                filtered[key] = value;
            }
        }
    });

    return filtered;
});

const deleteSavedSearch = () => {
    confirmingDeleteSearch.value = false;

    form.delete(
        route("savedSearchs.destroy", {
            savedSearch: props.savedSearch,
        }),
        {
            preserveScroll: true,
            onSuccess: () => {
                toast.success(t("toast.deleteSavedSearch"));
            },
        },
    );
};

const openConfirmingDeleteSearchModal = () => {
    confirmingDeleteSearch.value = true;
};
const openConfirmingEditSearchModal = () => {
    confirmingEditSearch.value = true;
};
</script>
<template>
    <div class="saved-search__card">
        <div class="saved-search__card__top">
            <Button
                type="button"
                @click="openConfirmingEditSearchModal"
                :loading="form.processing"
                class="delete-saved-search__button"
            >
                <img
                    src="../../../images/icons/edit.svg"
                    class=""
                    width="24"
                    height="24"
                    alt="edit saved search"
                />
            </Button>
            <Button
                type="button"
                @click="openConfirmingDeleteSearchModal"
                :loading="form.processing"
                class="delete-saved-search__button"
            >
                <img
                    src="../../../images/icons/trash.svg"
                    class=""
                    width="24"
                    height="24"
                    alt="delete saved search"
                />
            </Button>
        </div>

        <div class="saved-search__card__header">
            <a
                :href="savedSearch.search_url"
                class="saved-search__card__title"
                >{{ savedSearch.title }}</a
            >
            <Button
                @click.stop="openingAction = !openingAction"
                class="delete-saved-search__dots"
            >
                <Dots />
            </Button>

            <div class="saved-search__card__action" v-if="openingAction">
                <Button
                    type="button"
                    @click="openConfirmingEditSearchModal"
                    :loading="form.processing"
                >
                    {{ t("actions.rename") }}
                </Button>
                <Button
                    type="button"
                    @click="openConfirmingDeleteSearchModal"
                    :loading="form.processing"
                >
                    {{ t("deleteSavedSearch") }}
                </Button>
            </div>
        </div>
        <Modal
            :show="confirmingDeleteSearch"
            @close="confirmingDeleteSearch = false"
        >
            <template #title
                >{{
                    t("savedSearch.confirmDelete", { title: savedSearch.title })
                }}
            </template>

            <template #content>
                <p class="saved-search__card__subtitle warning">
                    {{ t("savedSearch.deleteAction") }}
                </p>
                <div class="delete-saved-search__action">
                    <Button @click="deleteSavedSearch" variante="danger">{{
                        t("deleteSavedSearch")
                    }}</Button>
                    <Button
                        @click="confirmingDeleteSearch = false"
                        variante="secondary"
                        >{{ t("actions.cancel") }}</Button
                    >
                </div>
            </template>
        </Modal>
        <Modal
            :show="confirmingEditSearch"
            @close="confirmingEditSearch = false"
        >
            <template #title
                >{{ t("savedSearch.renameSavedSearch") }}
            </template>

            <template #content>
                <div class="delete-saved-search__action">
                    <TextInput
                        id="savedsearch_title"
                        v-model="form.title"
                        type="text"
                        :label="t('savedSearch.modifySearch')"
                        required
                        :placeholder="t('titleSearch')"
                    />
                    <Button @click="update" variante="primary">{{
                        t("actions.validate")
                    }}</Button>
                </div>
            </template>
        </Modal>
        <p class="saved-search__card__subtitle">
            {{
                t("savedSearch.datesSearch", {
                    created_at: $filters.date(
                        savedSearch.created_at,
                        "DD MMM YYYY",
                    ),
                    updated_at: $filters.date(
                        savedSearch.updated_at,
                        "DD MMM YYYY",
                    ),
                })
            }}
        </p>
        <div class="saved-search__card__content">
            <ul class="saved-search__card__list">
                <li v-for="(item, index) in filteredDetails" :key="index">
                    {{ t(`${index}`) }} : {{ item }},
                </li>
            </ul>
        </div>

        <div class="saved-search__card__notif">
            <Checkbox
                v-model:checked="form.notify_user"
                @change="update"
                type="checkbox"
                variante="switch"
                :value="true"
            />
            <p class="saved-search__card__text">
                {{ t("savedSearch.activateNotification") }}
            </p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.saved-search__card {
    position: relative;
    border-radius: 4px;
    border: 1px solid $grey-input;
    margin-bottom: 1.5rem;
    padding: 0;
    @include medium-up {
        padding: 2.5rem;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 62px;
        height: 62px;
        background-size: 62px;
        background-image: url("../../../images/icons/yellow-corner.svg");
        @include medium-up {
            background-size: 40px;
            width: 40px;
            height: 40px;
        }
    }
    &__title {
        color: $dark-font;
        font-size: 1.5rem;
        font-weight: 600;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    &__subtitle {
        color: $dark-font;
        font-size: 0.875rem;
        opacity: 0.6;
        line-height: 1.5;
        padding: 0 2.5rem;
        &.warning {
            padding: 0;
        }
        @include medium-up {
            padding: 0;
            line-height: 1;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 1rem 2.5rem 0 2.5rem;
        @include medium-up {
            padding: 0;
        }
        button {
            appearance: none;
            border: none;
            background-color: transparent;
            padding: 0;
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }
    &__action {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 50;
        top: 2.5rem;
        right: 0;
        border-radius: 4px;
        border: 1px solid $grey-input;
        background: #fff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 1rem 1rem 0 1rem;
        button {
            color: #000;
            font-size: 0.875rem;
            font-style: normal;
            padding-bottom: 1rem;
            font-weight: 400;
        }
    }
    &__content {
        margin-top: 1.5rem;
        display: block;
        padding: 0 2.5rem;
        @include medium-up {
            padding: 0;
        }
    }

    &__list {
        list-style-type: none;
        padding: 1.5rem 0 0 0;
        border-top: 1px solid $light-bg;
        @include medium-up {
            padding: 0;
            border-top: none;
        }
        li {
            display: block;
            margin-top: 0;
            color: $dark-font;
            font-size: 1rem;
            line-height: 1.5;
            @include medium-up {
                padding-top: 0;
                display: inline;
            }
        }
    }
    &__notif {
        display: flex;
        justify-content: center;
        margin-top: 1.75rem;
        .saved-search__card__text {
            margin-left: 0.75rem;
        }
        p {
            margin: 0;
        }
        background-color: $grey-card;
        padding: 1rem 0;
        @include medium-up {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: transparent;
            padding: 0;
        }
    }
    &__text {
        color: $dark-font;
        font-size: 1rem;
    }
    &__top {
        display: flex;
        justify-content: flex-end;
        button {
            width: fit-content;
            padding: 1.5rem 0.5rem;
        }
    }
}

.delete-saved-search {
    &__action {
        margin-top: 0;
        @include medium-up {
            margin-top: 2.5rem;
        }
        > .input__field {
            margin: 1.5rem 0 2rem 0;
            @include medium-up {
                margin-top: 0;
            }
        }
        button {
            width: 100%;
            margin-bottom: 0.5rem;
        }
    }
    &__button {
        display: flex;
        background-color: transparent;
        appearance: none;
        border: none;
        justify-content: flex-end;
        width: 100%;
        &:hover,
        &:focus {
            background-color: transparent;
        }
        @include medium-up {
            display: none;
        }
    }
    &__dots {
        display: none;
        @include medium-up {
            display: block;
        }
    }
}
</style>
