<script setup lang="ts">
import { PropType, ref } from "vue";
import { Address } from "@/types";
import Label from "@/Components/Forms/Label.vue";
import Error from "@/Components/Forms/Error.vue";

const props = defineProps({
    modelValue: {
        type: Object as PropType<Address | null>,
        default: null,
        required: false,
    },
    label: {
        type: String,
        default: "",
    },
    message: {
        type: String,
        default: "",
    },
});

const getFormattedAddress = (address: Address | null) => {
    if (!address) return props.label;
    const addressLine = address.line_2
        ? `${address.line_1} ${address.line_2}`
        : address.line_1;
    return `${addressLine}, ${address.postcode} ${address.city}, ${address.country}`;
};

const search = ref("");
const placeholder = getFormattedAddress(props.modelValue);

const emit = defineEmits<{
    (e: "update:modelValue", value: Address): void;
}>();

const updateModelValue = (place) => {
    const address: Address = {
        line_1: "",
        line_2: "",
        postcode: "",
        city: "",
        country: "",
    };
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.line_1 = `${component.long_name} `;
                break;
            }

            case "route": {
                address.line_1 += component.short_name;
                break;
            }

            case "postal_code": {
                address.postcode = `${component.long_name}`;
                break;
            }

            case "locality":
                address.city = component.long_name;
                break;

            case "country":
                address.country = component.short_name;
                break;
        }
    }

    emit("update:modelValue", address);
};
</script>

<template>
    <div class="input__field input__field--classique">
        <Label :label="label" :required="true" />
        <GMapAutocomplete
            class="input input--address"
            v-model="search"
            @place_changed="updateModelValue"
            :placeholder="placeholder"
        />
        <Error :error="message" />
    </div>
</template>

<style scoped lang="scss"></style>
