<script setup lang="ts">
import Close from "@/Components/Icons/Close.vue";
import { LandingData } from "@/types";

withDefaults(
    defineProps<{
        landingData?: LandingData;
        isIntoFacet?: boolean;
        includedAttributes?: string;
    }>(),
    {
        isIntoFacet: false,
        includedAttributes: undefined,
        landingData: undefined,
    },
);

const getUnitLabel = (label) => {
    let unit = "";

    switch (label) {
        case "mileage":
            unit = "km";
            break;
        case "monthly_price":
            unit = "€";
            break;
        default:
            break;
    }

    return unit;
};

const getOperatorLabel = (refinement) => {
    let operator = "";
    switch (refinement.operator) {
        case "<=":
            operator = "≤";
            break;
        case ">=":
            operator = "≥";
            break;
        default:
            break;
    }

    return operator;
};
</script>

<template>
    <ais-current-refinements :included-attributes="[includedAttributes]">
        <template #default="{ items, createURL }">
            <div
                class="current-refinements"
                :class="{
                    facet: isIntoFacet,
                }"
            >
                <div v-if="landingData" class="landing-filters">
                    <div
                        v-for="item in Object.values(landingData)"
                        :key="item.id"
                    >
                        <span class="current-refinements__item landing">
                            <span>
                                {{ item.designation }}
                            </span>
                        </span>
                    </div>
                </div>

                <div v-if="!isIntoFacet">
                    <template v-for="item in items" :key="item.attribute">
                        <template
                            v-for="refinement in item.refinements"
                            :key="[refinement.attribute, refinement.type, refinement.value, refinement.operator].join(':')"
                        >
                            <a
                                class="current-refinements__item"
                                :class="{
                                    facet: isIntoFacet,
                                }"
                                :href="createURL(refinement)"
                                @click.prevent="item.refine(refinement)"
                            >
                                <span>
                                    {{ getOperatorLabel(refinement) }}
                                    {{ $filters.number(refinement.value) }}
                                    {{ getUnitLabel(item.label) }}
                                </span>

                                <Close
                                    class="current-refinements__item__close"
                                />
                            </a>
                        </template>
                    </template>
                </div>

                <div v-else>
                    <template v-for="item in items">
                        <label
                            class="RefinementList"
                            v-for="refinement in item.refinements"
                            :class="{
                                'RefinementList--selected': item.isRefined,
                            }"
                            :key="
                                [
                                    refinement.attribute,
                                    refinement.type,
                                    refinement.value,
                                    refinement.operator,
                                ].join(':')
                            "
                        >
                            <span>
                                <span class="RefinementList__labelText">{{
                                    refinement.label
                                }}</span>
                            </span>
                            <input
                                class="RefinementList__clear"
                                type="checkbox"
                                :value="refinement.value"
                                :checked="true"
                                @input.prevent="item.refine(refinement)"
                            />
                        </label>
                    </template>
                </div>
            </div>
        </template>
    </ais-current-refinements>
</template>

<style lang="scss">
.RefinementList {
    // Cas pour avoir une croix dans les filtres actifs
    &__clear {
        margin-left: auto;
        background: transparent;
        appearance: none;
        width: 1.5rem;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 1.5rem;
        &:checked {
            background: url("../../../images/icons/close_modal.svg") no-repeat
                center center / 1rem;
            border: none;
        }
    }
}
</style>
