<script lang="ts" setup>
import SavedSearchCard from "@/Components/Cards/SavedSearchCard.vue";
import { useI18n } from "vue-i18n";
import Pagination from "@/Components/Pagination.vue";
import { PaginatedResponse, SavedSearch } from "@/types";

const { t } = useI18n();

defineProps<{
    savedSearches: PaginatedResponse<SavedSearch>;
    totalSavedSearches: number;
}>();
</script>
<template>
    <div class="container saved-searches">
        <h1 class="saved-searches__title">
            {{ t("savedSearch.mySavedSearches") }} ({{ totalSavedSearches }})
        </h1>
        <div class="saved-searches__wrapper">
            <SavedSearchCard
                :saved-search="savedSearch"
                v-for="savedSearch in savedSearches.data"
                :key="`saved-search-card-${savedSearch.id}`"
            />
            <Pagination :data="savedSearches" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.saved-searches {
    margin-top: 2.5rem;
    @include medium-up {
        margin-top: 7.5rem;
    }
    &__title {
        color: $dark-font;
        font-family: $bebas;
        font-size: 2rem;
        margin: 0;
        padding: 0;
        display: flex;
        margin-bottom: 2rem;
        @include medium-up {
            font-size: 2.5rem;
        }
    }
    &__wrapper {
        margin-bottom: 7.5rem;
    }
}
</style>
