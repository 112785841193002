<script setup lang="ts">
import { Vehicle, VehiclePreview } from "@/types";
import { router } from "@inertiajs/vue3";
import { ref } from "vue";
import Modal from "@/Modal/Modal.vue";
import Button from "@/Components/Buttons/Button.vue";
import { useI18n } from "vue-i18n";
import Sidebar from "@/Components/Simulator/Sidebar.vue";
import Messages from "@/Components/Simulator/Messages.vue";
import Header from "@/Components/Simulator/Header.vue";

const props = withDefaults(
    defineProps<{
        vehicle: Vehicle;
        showMessage: boolean;
        vehiclePreview: VehiclePreview;
        goToScreen?: (screenNumber: number) => void;
        currentIntermediateStep?: number | null;
        currentTunnelStep?: number;
        dark?: boolean;
    }>(),
    {
        currentIntermediateStep: 1,
        currentTunnelStep: 1,
        goToScreen: () => {},
        dark: false,
    },
);

const { t } = useI18n();

const confirmingLeave = ref(false);
const loadingMessage = ref(true);
const sidebar = ref();

setTimeout(
    () => {
        loadingMessage.value = false;
    },
    props.showMessage ? 2000 : 0,
);

const openConfirmingLeaveModal = () => {
    confirmingLeave.value = true;
};

const leave = () => {
    confirmingLeave.value = false;
    router.visit(
        route("vehicles.show", {
            slug: props.vehicle.slug,
            vehicle: props.vehicle,
        }),
    );
};

const forceOpenSidebar = (open) => {
    sidebar.value.forceOpen(open);
};

defineExpose({
    forceOpenSidebar,
});
</script>

<template>
    <div
        class="simulator -expanded"
        :class="{
            '-dark': dark,
            '-no-sidebar': !$slots.sidebarContent && !$slots.sidebarFooter,
        }"
    >
        <Header
            :step="currentTunnelStep"
            :intermediate-step="currentIntermediateStep"
            @leave="openConfirmingLeaveModal"
            @previous="
                () => {
                    if (currentIntermediateStep) {
                        goToScreen(currentIntermediateStep - 1);
                    }
                }
            "
        />
        <div class="simulator__content">
            <Transition mode="out-in">
                <div
                    class="simulator__content__form"
                    :key="`step-${currentIntermediateStep}`"
                >
                    <Messages
                        :is-loading="loadingMessage"
                        v-if="showMessage && $slots.message"
                    >
                        <slot name="message" />
                    </Messages>

                    <Transition enter-active-class="v-enter-active-delayed">
                        <div v-if="!loadingMessage">
                            <slot :key="`step-${currentIntermediateStep}`" />
                        </div>
                    </Transition>
                </div>
            </Transition>
        </div>
        <Sidebar
            ref="sidebar"
            :vehicle-preview="vehiclePreview"
            v-if="$slots.sidebarContent || $slots.sidebarFooter"
        >
            <slot name="sidebarContent" />
            <template v-if="$slots.sidebarFooter" #footer>
                <slot name="sidebarFooter" />
            </template>
            <template v-if="$slots.sidebarCta" #cta>
                <slot name="sidebarCta" />
            </template>
        </Sidebar>
        <Modal :show="confirmingLeave" @close="confirmingLeave = false">
            <template #title>
                {{ t("tunnel.wantToLeave") }}
            </template>

            <template #content>
                <p class="leave-paragraph">
                    {{ t("tunnel.noProblem") }}
                    <br />
                    <br />
                    {{ t("tunnel.canRestartProcess") }}
                </p>

                <div class="leave-actions">
                    <Button
                        variante="primary"
                        type="button"
                        @click="confirmingLeave = false"
                    >
                        {{ t("tunnel.continueProcess") }}
                    </Button>

                    <Button variante="secondary" type="button" @click="leave">
                        {{ t("actions.leave") }}
                    </Button>
                </div>
            </template>
        </Modal>
    </div>
</template>

<style lang="scss">
.leave-actions {
    display: grid;
    gap: 0.5rem;
}

.leave-paragraph {
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
}

.vehicle-preview {
    padding: 2.5rem 2.5rem 1.5rem 2.5rem;
    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.25);
    margin-bottom: 1rem;

    @include medium-down {
        border-bottom: none;
        padding-bottom: 0;
    }

    &__title {
        display: block;
        font-family: $bebas;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.96px;
        font-size: 1.5rem;
        margin: 0 0 1rem 0;
        padding: 0;
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__image {
        display: block;
        height: 170px;
        margin-right: 1rem;
        width: 50%;
        min-width: 50%;

        @include medium-down {
            display: none;
        }

        & > img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__make {
        display: block;
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.5rem;

        @include medium-down {
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
    }

    &__description {
        display: block;
        line-height: 1.25rem;
        opacity: 0.5;

        @include medium-down {
            font-size: 0.875rem;
        }
    }
}

.simulator {
    background: $grey-card;
    height: 100dvh;
    position: relative;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 80px 1fr 165px;
    @include medium-up {
        grid-template-rows: 80px 1fr;
        grid-template-columns: 1fr clamp(360px, 50%, 450px);
        align-items: stretch;
        > * {
            box-sizing: border-box;
        }
    }

    &.-expanded {
        grid-template-rows: 80px 1fr 2rem;

        @include medium-down {
            grid-template-rows: 80px 1fr 6rem;
        }
    }

    &.-no-sidebar {
        grid-template-columns: 1fr;
        grid-template-rows: 80px 1fr;

        @include medium-down {
            grid-template-rows: 50px 1fr;
        }
    }

    &.-dark {
        background: $dark-bg;

        @include medium-down {
            .simulator__header__logo {
                display: none !important;
            }

            .simulator__header__leave-button {
                color: white;
                background: transparent;
            }
        }
    }

    &__content {
        padding: 2.5rem 1rem 1.5rem 1rem;
        position: relative;
        box-sizing: border-box;
        overflow-y: auto;

        @include medium-down {
            padding: 1rem;
        }

        &__form {
            max-width: 45rem;
            margin: 0 auto;
        }
    }
}

hr {
    border-top: 1px solid $light-bg;
}

.accept {
    display: flex;
    align-items: center;
    margin: 1rem 0 1.5rem 0;

    &--label {
        margin-left: 0.5rem;

        @include medium-down {
            font-size: 14px;
        }
    }
}

.form-button {
    width: 100%;
}

.mobile-only {
    display: none;
    @include medium-down {
        display: block;
    }
}

.mobile-hidden {
    @include medium-down {
        display: none;

        &.visible {
            display: block;
        }
    }
}

.radio-group {
    display: flex;
    align-items: center;

    & > * {
        margin-right: 1.25rem;
    }

    &__label {
        display: block;
        padding-bottom: 1rem;
    }
}

.radio-label {
    display: flex;
    align-items: center;

    &__text {
        font-family: $bebas;
        opacity: 0.5;
        text-transform: uppercase;
        letter-spacing: 1.28px;
    }
}

.radio-label.active {
    .radio-label__text {
        opacity: 1;
    }
}
</style>
