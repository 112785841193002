<script lang="ts" setup>
import { Device } from "@/Composables/utils/useClient";
import { inject } from "vue";
import { useI18n } from "vue-i18n";
import { Link as InertiaLink } from "@inertiajs/vue3";
import Logo from "@/Components/Icons/Logo.vue";
import TitleTabulatedMultiLine from "@/Components/Typography/TitleTabulatedMultiLine.vue";
import Link from "@/Components/Home/Link/Link.vue";

const { t } = useI18n();
const device = inject<Device>("device");
</script>

<template>
    <section class="section">
        <div class="section__container container">
            <div
                class="section__img -main"
                :data-scroll="device === Device.desktop || null"
                data-scroll-speed="-0.08"
                data-scroll-offset="-200, 200"
            >
                <img
                    srcset="
                        ../../../../../resources/images/home/joinsteer-exit.webp,
                        ../../../../../resources/images/home/joinsteer-exit@2x.webp 2x
                    "
                    src="../../../../../resources/images/home/joinsteer-exit.webp"
                    alt=""
                    loading="lazy"
                />
            </div>

            <div class="section__desc">
                <p>
                    <b>{{ t("home.technology.text[0]") }}</b>
                </p>
                <p>{{ t("home.technology.text[1]") }}</p>
            </div>
            <div
                class="section__img -sub"
                :data-scroll="device === Device.desktop || null"
                data-scroll-offset="-200, 200"
                data-scroll-speed="-0.04"
            >
                <img
                    srcset="
                        ../../../../../resources/images/home/joinsteer-entry.webp,
                        ../../../../../resources/images/home/joinsteer-entry@2x.webp 2x
                    "
                    src="../../../../../resources/images/home/joinsteer-entry.webp"
                    alt=""
                    loading="lazy"
                />

                <Logo
                    only-stroke
                    data-scroll
                    data-scroll-offset="200, 200"
                    class="section__logo"
                />
            </div>

            <div class="section__title">
                <TitleTabulatedMultiLine
                    data-scroll
                    data-scroll-offset="200, 200"
                    :labels="[
                        {
                            isInterpolated: false,
                            content: t('home.technology.title[0]'),
                            gap: '0',
                        },
                        {
                            isInterpolated: false,
                            content: t('home.technology.title[1]'),
                            gap: '2.5ch',
                        },
                        {
                            isInterpolated: false,
                            content: t('home.technology.title[2]'),
                            gap: '1ch',
                        },
                    ]"
                    :level="2"
                />
                <Link
                    data-scroll
                    data-scroll-offset="200, 200"
                    :as="InertiaLink"
                    :href="route('vehicles.index')"
                    class="section__link"
                    >{{ t("home.technology.cta") }}</Link
                >
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
html.lenis {
    .section {
        &__logo {
            opacity: 0;
            transform: translateX(-40%);
            &.is-inview {
                opacity: 1;
                transform: translateX(0%);
                transition: all 0.5s ease-in-out;
            }
        }
    }
}
.section {
    padding: var(--hp-y-space) 0;
    background: $dark-black-bg;
    color: white;

    &__container {
        display: grid;
        grid-template-columns: var(--grid-columns);
        row-gap: 2rem;
    }

    &__link {
        color: $primary;
        margin-left: 1rem;
    }

    &__img {
        img {
            width: 100%;
        }

        &.-sub {
            position: relative;
            @include medium-down {
                padding: 0 var(--hp-x-space);
            }
            @include large-up {
                margin-top: -4%;
            }
        }
        svg {
            position: absolute;
            width: 100px;
            height: 100px;
            left: 10%;
            bottom: -40px;
        }
    }

    &__title {
        margin: 2rem 0;
    }

    &__desc {
        line-height: var(--description-line-height);

        color: rgba(255, 255, 255, 0.87);

        b {
            color: white;
        }
    }

    @include medium-up {
        &__img {
            &.-main {
                grid-column: 3;
                grid-row: 1;
            }

            &.-sub {
                grid-column: 1;
                grid-row: 2;
            }

            svg {
                left: inherit;
                right: -10px;
                bottom: 50%;
            }
        }

        &__title {
            grid-column: 3;
            grid-row: 2;
        }

        &__desc {
            grid-column: 1;
            grid-row: 1;
        }
    }

    @include medium-down {
        &__title {
            text-align: center;
        }

        &__desc {
            b {
                display: block;
                margin-bottom: 1rem;
            }
        }
    }
}
</style>
