<script setup lang="ts">
import { Head } from "@inertiajs/vue3";
import PageLoader from "@/Components/Home/PageLoader.vue";
import { defineAsyncComponent, onBeforeUnmount, onMounted, provide } from "vue";
import { useClient } from "@/Composables/utils/useClient";
import { useGlobalEmitter } from "@/Composables/utils/useGlobalEmitter";
import { useTimeout } from "@/Composables/utils/useTimeout";
import { Article, Review } from "@/types";

const HomeAnimation = defineAsyncComponent(
    () => import("@/Components/Home/Animation/Animation.vue"),
);

defineProps<{
    articles?: Array<Article>;
    customerReview?: Review;
    showVideo: boolean;
    reviewSchema: JSON;
}>();

const { device } = useClient();
provide("device", device.value);

const { emitter } = useGlobalEmitter();
const { launchTimeout, cancelTimeout } = useTimeout();

const isServer = typeof window === "undefined";

onMounted(() => {
    document.documentElement.classList.add("homepage");
    emitter.emit("loading:progress", "start");

    if (!isServer) {
        /*
            Start timeout of 5s to stop loading animation.
            It will be cancel when animation is ready:
            - If it's canceled before the timeout, it would do nothing
            - Else it will disable animation
            */
        launchTimeout(5000, () => {
            window.location.href = "/about-us";
        });
    }
});

onBeforeUnmount(() => {
    document.documentElement.classList.remove("homepage");
});
</script>

<template>
    <Head>
        <component :is="'script'" type="application/ld+json">
            {{ reviewSchema }}
        </component>
    </Head>

    <div class="home">
        <PageLoader v-if="showVideo" />
        <HomeAnimation @animation:ready="cancelTimeout" />
    </div>
</template>
<style>
html.lenis {
    height: auto;
}
.lenis.lenis-smooth {
    scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}
.lenis.lenis-stopped {
    overflow: hidden;
}
.lenis.lenis-scrolling iframe {
    pointer-events: none;
}
img {
    max-width: 100%;
    vertical-align: middle;
}
</style>
<style lang="scss" scoped>
.home {
    --description-line-height: 1.5;
    --grid-columns: 1;
    --hp-y-space: 2rem;

    @include medium-up {
        --grid-columns: 1fr #{clampGenerator(3, 10)} 1fr;
        --hp-y-space: #{clampGenerator(2.5, 5, 40, 120)};
    }

    &__img {
        * {
            width: 100%;
            display: block;
        }
    }
    &__button {
        a {
            display: inline-block;
        }
    }
}

@include medium-up {
    .mobile-only {
        display: none;
    }
}
</style>
