<script setup lang="ts">
import { Order } from "@/types";
import Button from "@/Components/Buttons/Button.vue";
import BigDate from "@/Components/BigDate.vue";
import Logo from "@/Components/Icons/Logo.vue";
import { onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import { useForm } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";
const toast = useToast();
const { t } = useI18n();

const props = defineProps<{ data: Order }>();

const steps = ref();
const currentStep = ref();
const callbackRequestReceived = ref(false);

const form = useForm({});

const askForCallback = () => {
    form.post(
        route("simulations.callback", { simulation: props.data.simulation }),
        {
            onSuccess: () => {
                callbackRequestReceived.value = true;
                toast.success(t("tunnel.callbackRequestReceived"));
            },
        },
    );
};

onMounted(() => {
    steps.value = [
        {
            label: "Signature du bon de commande",
            delay: "2 à 3 jours",
            date: props.data.purchase_order_sign_at,
        },
        {
            label: "Signature du contrat de financement",
            delay: "3 à 5 jours",
            date: props.data.financing_contract_sign_at,
        },
        {
            label: "Versement du premier loyer",
            delay: "2 à 3 jours",
            date: props.data.first_rent_payment_at,
        },
        {
            label: "Envoie du certificat d’immatriculation",
            delay: "2 à 3 jours",
            date: props.data.registration_certificate_send_at,
        },
        {
            label: "Mise en transport du véhicule",
            delay: "3 à 5 jours",
            date: props.data.transporting_vehicle_at,
        },
        {
            label: "Paiement des frais d’immatriculation",
            delay: "2 à 3 jours",
            date: props.data.registration_fees_payment_at,
        },
        {
            label: "Livraison estimée",
            delay: "",
            date:
                props.data.estimated_delivery ??
                props.data.estimated_delivery_date,
        },
    ];

    const firstNullDateStep = steps.value.find((step) => step.date === null);

    if (firstNullDateStep) {
        currentStep.value = firstNullDateStep;
    } else {
        currentStep.value = steps.value[0];
    }
});
</script>
<template>
    <div class="item">
        <header class="item__header">
            <Logo />
            <h3 class="item__status">
                {{ t("order.title[0]") }}
                <a :href="data.simulation.vehicle.url">{{
                    t("order.title[1]")
                }}</a>
            </h3>
        </header>
        <div class="item__section">
            <div class="item__content left">
                <div
                    class="order-step"
                    v-for="(step, index) in steps"
                    :key="step.label"
                    :class="{
                        current:
                            currentStep && step.label === currentStep.label,
                    }"
                >
                    <span v-if="!step.date" class="order-step__icon">{{
                        index + 1
                    }}</span>
                    <span v-else class="order-step__icon checked"></span>
                    <div class="order-step__content">
                        <p
                            class="order-step__title"
                            :class="{
                                current:
                                    currentStep &&
                                    step.label === currentStep.label,
                            }"
                        >
                            {{ step.label }}
                        </p>
                        <span v-if="step.label === 'Livraison estimée'">
                            {{
                                t("order.estimatedDelivery", {
                                    date: $filters.date(step.date, "DD/MM/YY"),
                                })
                            }}
                        </span>
                        <span v-else-if="step.date">{{
                            $filters.date(step.date, "DD/MM/YY")
                        }}</span>
                        <span v-else>{{ step.delay }}</span>
                        <p
                            class="order-step__details"
                            v-if="
                                currentStep && step.label === currentStep.label
                            "
                        >
                            {{ data.action }}
                        </p>
                    </div>
                </div>

                <div class="order-step">
                    <div class="order-step__content"></div>
                </div>
            </div>
            <div class="item__content right" v-if="data.action">
                <h3 class="item__contact--title">
                    {{ t("order.commercial") }}
                </h3>
                <div class="item__contact">
                    <div class="item__contact--identity">
                        <img
                            v-if="data.sale.avatar_url"
                            :src="data.sale.avatar_url"
                            class="item__contact--photo"
                            width="24"
                            height="24"
                            :alt="data.sale.fullname"
                        />
                        <img
                            v-else
                            class="item__contact--photo"
                            width="24"
                            height="24"
                            :alt="data.sale.fullname"
                            src="../../../images/icons/user_grey.svg"
                        />
                        <div>
                            <p class="item__contact--name">
                                {{ data.sale.fullname }}
                            </p>
                            <p class="item__contact--function">
                                {{ t("order.role") }}
                            </p>
                        </div>
                    </div>
                    <a :href="`tel:${data.sale.phone}`">
                        <img
                            class="item__contact--phone"
                            src="../../../images/icons/phone.svg"
                        />
                    </a>
                </div>
                <div class="item__waitingaction">
                    <a :href="data.simulation.vehicle.url">
                        <img
                            class="item__waitingaction--img"
                            :src="
                                data.simulation.vehicle.previewData?.image_url
                            "
                            :alt="`Image : ${data.simulation.vehicle.display_name2}`"
                            v-if="
                                data.simulation.vehicle.previewData?.image_url
                            "
                        />
                        <img
                            class="item__waitingaction--img"
                            src="../../../images/joinsteer-logo.svg"
                            :alt="`Image : ${data.simulation.vehicle.display_name2}`"
                            v-else
                        />
                    </a>
                    <div class="item__waitingaction--bottom">
                        <p class="item__waitingaction--title">
                            {{ t("order.waitingAction") }}
                        </p>
                        <p class="item__waitingaction--content">
                            {{ data.action }}
                        </p>
                        <Button
                            variante="primary"
                            type="button"
                            class="item__waitingaction--action"
                            @click="askForCallback"
                            :disabled="callbackRequestReceived"
                        >
                            {{
                                t(
                                    callbackRequestReceived
                                        ? "tunnel.callbackRequestReceived"
                                        : "order.callbackRequest",
                                )
                            }}
                        </Button>
                    </div>
                </div>
            </div>
            <div class="item__content right" v-else>
                <a
                    class="item__content--link"
                    :href="data.simulation.vehicle.url"
                >
                    <img
                        :src="data.simulation.vehicle.previewData?.image_url"
                        :alt="`Image : ${data.simulation.vehicle.display_name2}`"
                        v-if="data.simulation.vehicle.previewData?.image_url"
                        class="item__waitingaction--img"
                    />
                    <img
                        src="../../../images/joinsteer-logo.svg"
                        :alt="`Image : ${data.simulation.vehicle.display_name2}`"
                        v-else
                        class="item__waitingaction--img"
                    />
                </a>
                <div class="item__delivery">
                    <div class="item__delivery--date">
                        <BigDate
                            :date="
                                $filters.date(
                                    data.estimated_delivery ??
                                        data.estimated_delivery_date,
                                    'DD/MM/YY',
                                )
                            "
                        />
                    </div>
                    <p class="item__delivery--content">
                        {{ t("order.estimatedDeliveryOf") }}
                    </p>
                    <p class="item__delivery--content">
                        <strong>{{
                            data.simulation.vehicle.display_name2
                        }}</strong>
                    </p>
                </div>
                <Button
                    variante="secondary"
                    as="a"
                    :href="data.simulation.vehicle.url"
                    class="item__delivery--action"
                >
                    {{ t("order.detailsVehicle") }}
                </Button>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.item {
    margin-bottom: 2rem;
    $this: &;
    &__status {
        font-size: $font-size-m;
        font-weight: normal;
        font-family: $bebas;
        text-align: center;
        text-transform: uppercase;
        a {
            color: $primary;
            text-decoration: none;
        }
        @include medium-up {
            text-align: left;
        }
    }
    &__content {
        margin: 0;
        padding: 2.5rem;
        > *:first-child {
            margin-top: 0;
        }

        @include medium-up {
            margin: 2.5rem;
            padding: 0;
        }
        &.left {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                left: 2.25rem;
                top: 2.5rem;
                bottom: 90px;
                width: 2rem;
                background-color: $grey-card;
                border-radius: 70px;
                @include medium-up {
                    left: -0.25rem;
                    top: 0;
                    bottom: 50px;
                }
            }
        }
        &.right {
            margin-left: 0;
            padding: 0;
        }
        &--link {
            background-color: $grey-card;
        }
    }

    &__delivery {
        background-color: $grey-card;
        margin: 0;
        padding: 0.5rem 0.5rem 2rem 0.5rem;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        &--date {
            margin: 2rem 0 2rem 0;
        }
        &--content {
            text-align: center;
            margin: 0;
        }
        @include medium-up {
            padding: 1rem 1rem 2.5rem 1rem;
        }
        &--action {
            display: none;
            @include medium-up {
                display: flex;
                margin-top: 0.5rem;
            }
        }
    }

    &__contact {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        border-radius: 2px;
        border: 1px solid rgba(22, 59, 69, 0.2);
        background: white;
        padding: 0.75rem 1rem;
        margin-bottom: 1.5rem;
        p {
            margin: 0;
        }
        &--identity {
            display: flex;
            align-content: center;
            align-items: center;
            img {
                width: 3rem;
                height: 3rem;
                border-radius: 3rem;
                margin-right: 1rem;
            }
        }
        &--phone {
            width: 1.25rem;
            height: 1.25rem;
            background-color: $dark-font;
            padding: 0.5rem;
            border-radius: 0.25rem;
        }
        &--name {
            font-weight: 500;
            line-height: 1.5rem;
        }
        &--function {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1rem;
            opacity: 0.6;
        }
        &--title {
            font-family: $bebas;
            font-size: 1.125rem;
            line-height: 1.25rem;
            text-transform: uppercase;
        }
    }

    &__waitingaction {
        background-color: $grey-card;
        border-radius: 0.25rem;
        &--bottom {
            padding: 1rem;
        }
        &--title {
            padding: 0;
            margin: 0;
            font-weight: 700;
        }
        &--content {
            font-size: 0.875rem;
            line-height: 1.5rem;
            opacity: 0.6;
        }
        &--action {
            width: 100%;
        }
        &--img {
            max-width: 100%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            @include medium-up {
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
            }
        }
    }

    &__header {
        background: $dark-bg;
        color: white;
        border-radius: 0.25rem 0.25rem 0 0;
        padding: 1rem 1rem 1rem 40px;
        position: relative;
        overflow: hidden;
        @include medium-up {
            padding: 1.25rem 1.25rem 1.25rem 78px;
        }
        svg {
            position: absolute;
            top: 50%;
            left: -1rem;
            width: 78px;
            height: 78px;
            opacity: 0.04;
            transform: translateY(-50%);
            @include medium-up {
                left: 0.5rem;
            }
        }
        > * {
            margin: 0;
        }
    }
    &__section {
        border: 1px solid $grey-card;
        border-top: none;
        border-radius: 0 0 0.25rem 0.25rem;
        @include medium-up {
            display: grid;
            grid-template-columns: 60% 40%;
            justify-content: space-between;
        }
    }
}

.order-step {
    display: flex;
    position: relative;
    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        color: $primary;
        text-align: center;
        font-family: $bebas;
        font-size: 0.875rem;
        line-height: 2rem;
        background-color: $dark-font;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        margin-bottom: 2.5rem;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-top: 0.25rem;
        &.checked {
            background: $dark-font url("../../../images/icons/check-yellow.svg")
                no-repeat center center / 1rem;
        }
    }
    &__content {
        margin-left: 2.5rem;
        margin-bottom: 1rem;
        color: #668a90;
        line-height: 1.5rem;
    }
    &__title {
        font-weight: 700;
        margin: 0;
        &.current {
            color: $dark-font;
        }
    }
    &__details {
        margin-top: 1rem;
        line-height: 2rem;
    }
    &.current {
        position: relative;
        height: auto;
        &::before {
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;
            top: 0.5rem;
            left: 11px;
            background-repeat: no-repeat;
            background-image: url("../../../images/icons/current_bar.svg");
            background-size: auto 100%;
            @include medium-up {
                top: 1rem;
            }
        }
    }
}
</style>
