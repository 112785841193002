<script setup lang="ts">
import Modal from "@/Modal/Modal.vue";
import TextInput from "@/Components/Forms/Input/TextInput.vue";
import { router, useForm, usePage } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import Bell from "./Icons/Bell.vue";
import Button from "@/Components/Buttons/Button.vue";
import LoginCard from "@/Components/Cards/LoginCard.vue";
const page = usePage();

defineProps<{
    reverseColor: boolean;
}>();

const { t } = useI18n();

const isSearchSaved = ref(false);
const noCurrentSearch = ref(false);

const form = useForm({
    search_url: "",
    title: "",
});

const confirmingSaveSearch = ref(false);
const loginBeforeSaveSearch = ref(false);

const openConfirmingSaveSearchModal = () => {
    if (page.props.auth.user) {
        // On recupère la recherche pour mettre un titre par défaut
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.size > 0) {
            const make = urlParams.get("menu[make]");
            const model = urlParams.getAll("refinementList[model][0]")[0];

            if (make) {
                form.title = make;
                if (model) {
                    form.title += " " + model;
                }
            }
        } else {
            noCurrentSearch.value = true;
        }
        confirmingSaveSearch.value = true;
    } else {
        loginBeforeSaveSearch.value = true;
    }
};

const submit = () => {
    form.search_url = decodeURIComponent(window.location.href) ?? "";
    form.post(route("savedSearchs.store"), {
        onSuccess: () => {
            isSearchSaved.value = true;
        },
    });
};

const reset = () => {
    form.clearErrors();
    form.title = "";
    confirmingSaveSearch.value = false;
    isSearchSaved.value = false;
    loginBeforeSaveSearch.value = false;
    noCurrentSearch.value = false;
};

const userIsLogged = () => {
    loginBeforeSaveSearch.value = false;
    confirmingSaveSearch.value = true;
    router.reload();
};
</script>
<template>
    <button
        @click.prevent="openConfirmingSaveSearchModal"
        class="hits-action__save-search-button"
        :class="{ 'reverse-color': reverseColor }"
    >
        <Bell width="14px" />
        <span class="hits-action__save-search-button--text">{{
            t("saveSearch")
        }}</span>
    </button>
    <Modal :show="confirmingSaveSearch" @close="reset()">
        <template v-if="!isSearchSaved" #title>{{ t("saveSearch") }}</template>
        <template v-else #title>
            <div class="savedsearch-confirm__title">
                <img
                    src="../../images/icons/check-green.svg"
                    class="savedsearch-confirm__title__icon"
                    width="40"
                    height="40"
                    alt="check saved search"
                />
                {{ t("savedSearchConfirm") }}
            </div>
        </template>

        <template v-if="noCurrentSearch" #content>
            <p class="savedsearch-confirm__content">
                {{ t("noCurrentSearch") }}
            </p>
            <Button @click="reset()" class="savedsearch-confirm__action">{{
                t("performSavedSearch")
            }}</Button>
        </template>

        <template v-else-if="!isSearchSaved" #content>
            <form @submit.prevent="submit" class="savedSearch-form">
                <TextInput
                    id="savedsearch_title"
                    v-model="form.title"
                    type="text"
                    :label="t('personalizeSearch')"
                    required
                    :placeholder="t('titleSearch')"
                    :message="form.errors.title || form.errors.search_url"
                />

                <Button variante="primary" type="submit">{{
                    t("save")
                }}</Button>
            </form>
        </template>
        <template v-else #content>
            <div class="savedsearch-confirm__title responsive">
                <img
                    src="../../images/icons/check-green.svg"
                    class="savedsearch-confirm__title__icon"
                    width="40"
                    height="40"
                    alt="check saved search"
                />
                {{ t("savedSearchConfirm") }}
            </div>
            <p class="savedsearch-confirm__content">
                {{ t("savedSearchConfirmText") }}
            </p>
            <Button as="a" :href="route('savedSearchs.index')">{{
                t("manageSavedSearches")
            }}</Button>
        </template>
    </Modal>
    <Modal :show="loginBeforeSaveSearch" @close="reset()">
        <template #content>
            <LoginCard
                :header-text="t('savedSearch.headerModal')"
                @logged="userIsLogged()"
            />
        </template>
    </Modal>
</template>

<style lang="scss">
.hits-action__save-search-button {
    color: $primary;
    border: none;
    background: transparent;
    padding: 0 0.25rem 0 0;
    cursor: pointer;
    line-height: 1.5;
    border-radius: 4px;
    transition: background-color $transition-time;

    &:hover {
        color: $primary;
    }
    svg {
        margin-right: 2px;
        color: $primary;
    }
    img,
    span,
    svg {
        vertical-align: middle;
    }

    span {
        margin-left: 0.25rem;
    }

    &--text {
        display: inline-block;
    }

    @media (max-width: 960px) {
        background: $dark-bg;
        color: white;
        width: 100%;
        padding: 0.5rem 0;
        line-height: 2;
        &.reverse-color {
            background: $primary;
            color: $dark-bg;
            svg {
                color: $dark-bg;
            }
        }
        &--text {
            display: none;
        }
    }
}
.savedSearch-form {
    margin-top: 1.5rem;
    button {
        width: 100%;
        margin-top: 1.5rem;
    }
}

.savedsearch-confirm {
    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-bottom: 1rem;
        &__icon {
            margin-bottom: 1rem;
        }

        @media (max-width: 960px) {
            display: none;
        }
        &.responsive {
            display: none;
            @media (max-width: 960px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                align-items: center;
                color: $dark-font;
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 2;
                margin-top: 2rem;
            }
        }
    }
    &__content {
        color: $dark-font;
        opacity: 0.6;
        text-align: center;
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 2rem;
        white-space: pre-line;
    }
    &__action {
        margin-top: 1.5rem;
        width: 100%;
    }
}
</style>
