<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Link as InertiaLink } from "@inertiajs/vue3";
import Cross from "@/Components/Icons/Cross.vue";
import Subtitle from "@/Components/Typography/SubTitle.vue";
import TitleTabulatedMultiLine from "@/Components/Typography/TitleTabulatedMultiLine.vue";
import Link from "@/Components/Home/Link/Link.vue";
import GarageCar1x from "../../../../../resources/images/home/joinsteer-garage-car.webp";
import GarageCar2x from "../../../../../resources/images/home/joinsteer-garage-car@2x.webp";
import House1x from "../../../../../resources/images/home/joinsteer-house.webp";
import House2x from "../../../../../resources/images/home/joinsteer-house@2x.webp";

import { reactive } from "vue";
const { t } = useI18n();

type Story = {
    title: string;
    content: string;
    link: string;
    image?: {
        src: {
            "1x": string;
            "2x": string;
        };
        alt: string;
    };
};

const stories = reactive<Story[]>([
    {
        title: t("home.storyTelling.stories[0].title"),
        content: t("home.storyTelling.stories[0].content"),
        link: t("home.storyTelling.stories[0].link"),
        image: {
            src: {
                "1x": House1x,
                "2x": House2x,
            },
            alt: "Maison Joinsteer",
        },
    },
    {
        title: t("home.storyTelling.stories[1].title"),
        content: t("home.storyTelling.stories[1].content"),
        link: t("home.storyTelling.stories[1].link"),
        image: {
            src: {
                "1x": GarageCar1x,
                "2x": GarageCar2x,
            },
            alt: "Garage Joinsteer",
        },
    },
    {
        title: t("home.storyTelling.stories[2].title"),
        content: t("home.storyTelling.stories[2].content"),
        link: t("home.storyTelling.stories[2].link"),
    },
]);
</script>
<template>
    <section class="section">
        <header class="section__title">
            <TitleTabulatedMultiLine
                is-italic
                :level="2"
                :labels="[
                    {
                        isInterpolated: false,
                        content: t('home.storyTelling.title[0]'),
                        gap: '0',
                    },
                    {
                        isInterpolated: false,
                        content: t('home.storyTelling.title[1]', {
                            space: '&nbsp;',
                        }),
                        gap: '2ch',
                    },
                ]"
            />
        </header>
        <div class="list container">
            <div
                class="section__story"
                v-for="story in stories"
                :key="story.title"
            >
                <Cross />

                <div>
                    <header>
                        <Subtitle>{{ story.title }}</Subtitle>
                        <img
                            v-if="story.image"
                            :srcset="`${story.image.src['1x']}, ${story.image.src['2x']} 2x`"
                            :src="story.image.src['1x']"
                            :alt="story.image.alt"
                            loading="lazy"
                        />
                    </header>
                    <p>
                        {{ story.content }}
                    </p>

                    <Link
                        :as="InertiaLink"
                        :href="route('vehicles.index')"
                        class="section__link"
                        >{{ story.link }}</Link
                    >
                </div>
            </div>
        </div>
    </section>
</template>
<style lang="scss" scoped>
.list {
    display: grid;
    grid-template-columns: var(--grid-columns);
}

.section {
    padding-block: var(--hp-y-space);

    &__title {
        text-align: center;
    }

    &__story {
        position: relative;
        display: flex;

        svg {
            color: $primary;
            height: clampGenerator(2, 3.75, 40, 120);
            width: clampGenerator(2, 3.75, 40, 120);
            flex-shrink: 0;
        }

        h3 {
            margin-bottom: 1rem;
            padding-top: 0.25rem;
        }
        p {
            line-height: var(--description-line-height);
            color: black;
        }
        img {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    @include medium-up {
        &__story {
            align-self: center;

            &:nth-of-type(2) {
                grid-column: 3/4;
                grid-row: 2;
                margin-top: -12rem;
            }

            &:nth-of-type(3) {
                grid-row: 3;
                margin-top: -10rem;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    @include medium-down {
        &__story {
            margin-bottom: 80px;
        }
    }
}
</style>
