<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { AccordionContent, VehicleMake } from "@/types";
import { getAccordionContent } from "@/helpers/landing";
import AccordionList from "@/Components/Accordions/AccordionList.vue";
import Cross from "@/Components/Icons/Cross.vue";
import AudiSmall from "../../../images/vehicleMake/exterior/mobile/audi.webp";
import Audi from "../../../images/vehicleMake/exterior/desktop/audi.webp";
import AstonMartin from "../../../images/vehicleMake/exterior/desktop/astonmartin.webp";
import AstonMartinSmall from "../../../images/vehicleMake/exterior/mobile/astonmartin.webp";
import Porsche from "../../../images/vehicleMake/exterior/desktop/porsche.webp";
import PorscheSmall from "../../../images/vehicleMake/exterior/mobile/porsche.webp";
import HomeSmall from "../../../images/home-mobile.webp";
import Home from "../../../images/home.webp";
import Garage from "../../../images/garage.webp";
import GarageSmall from "../../../images/garage-mobile.webp";

const { t } = useI18n();

const props = defineProps<{
    vehicleMake: VehicleMake;
    itemSeo: string;
}>();

const accordionKeys: string[] = [
    "ambition",
    "customers",
    "personalisedService",
];

const accordionItems: AccordionContent[] = getAccordionContent(
    "concept",
    accordionKeys,
    props.itemSeo,
    t,
);

const matchingImages: Record<string, { mobile: string; desktop: string }> = {
    audi: {
        mobile: AudiSmall,
        desktop: Audi,
    },
    "aston-martin": {
        mobile: AstonMartinSmall,
        desktop: AstonMartin,
    },
    bmw: {
        mobile: GarageSmall,
        desktop: Garage,
    },
    ferrari: {
        mobile: HomeSmall,
        desktop: Home,
    },
    lamborghini: {
        mobile: GarageSmall,
        desktop: Garage,
    },
    "land-rover": {
        mobile: GarageSmall,
        desktop: Garage,
    },
    mclaren: {
        mobile: GarageSmall,
        desktop: Garage,
    },
    mercedes: {
        mobile: GarageSmall,
        desktop: Garage,
    },
    mini: {
        mobile: GarageSmall,
        desktop: Garage,
    },
    porsche: {
        mobile: PorscheSmall,
        desktop: Porsche,
    },
    volvo: {
        mobile: GarageSmall,
        desktop: Garage,
    },
};
</script>

<template>
    <section class="concept">
        <div class="concept__wrapper container">
            <div class="concept__image">
                <img
                    :srcset="`${matchingImages[vehicleMake.ref].mobile}  600w,
                ${matchingImages[vehicleMake.ref].desktop}  1280w`"
                    :src="matchingImages[vehicleMake.ref].desktop"
                    sizes="(max-width: 600px) 600px,
                         1280px"
                    alt="Le concept joinsteer"
                />
            </div>

            <div class="concept__content">
                <div class="concept__content__title">
                    <div class="concept__content__title__wrapper">
                        <h2>{{ t("sections.concept.title") }}</h2>
                        <Cross color="white" />
                    </div>
                </div>

                <div class="concept__content__accordions">
                    <AccordionList
                        :items="accordionItems"
                        accordion-border-color="white"
                        text-color="#333333"
                        :text-opacity="1"
                        border-color="white"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
.concept {
    position: relative;
    padding-bottom: clampGenerator(2, 7);

    &:after {
        content: "";
        position: absolute;
        top: clampGenerator(2, 3.5);
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $light-bg-blue;
    }

    &__wrapper {
        position: relative;
        z-index: 2;
    }

    &__image {
        img {
            display: block;
            width: 100%;
        }
    }

    &__content {
        &__title {
            display: flex;
            justify-content: center;
            flex-grow: 1;

            &__wrapper {
                position: relative;

                h2 {
                    margin: 0;
                    padding-top: clampGenerator(1, 2);

                    font-size: clampGenerator(3, 5);
                    font-family: $bebas;
                    font-style: italic;
                    font-weight: 500;
                    line-height: clampGenerator(2.5, 6);
                    text-transform: uppercase;
                    white-space: pre-line;
                }

                svg {
                    position: absolute;
                    width: 9vw;
                    left: -9vw;
                    top: -4.5vw;
                }
            }
        }

        &__accordions {
            margin-top: clampGenerator(2, 3);
        }
    }

    @media (min-width: $breakpoint-l) {
        &__content {
            display: flex;
            justify-content: space-between;

            &__title {
                margin-left: 6rem;
            }

            &__accordions {
                width: 35rem;
            }
        }
    }
}
</style>
