<script setup lang="ts">
import FinancingItem from "@/Components/FinancingItem/FinancingItem.vue";
import Pagination from "@/Components/Pagination.vue";
import Default from "@/Layouts/Default.vue";
import Account from "@/Layouts/Account.vue";
import { Simulation, PaginatedResponse } from "@/types";
import { useI18n } from "vue-i18n";

defineOptions({ layout: [Default, Account] });

defineProps<{
    simulations: PaginatedResponse<Simulation>;
}>();
const { t } = useI18n();
</script>

<template>
    <div class="financing">
        <div
            class="financing__list"
            v-if="simulations && simulations.data.length > 0"
        >
            <FinancingItem
                v-for="simulation in simulations.data"
                :key="simulation.id"
                :data="simulation"
            />
            <Pagination :data="simulations" />
        </div>

        <h2 v-else>{{ t("simulation.notFound") }}</h2>
    </div>
</template>

<style scoped lang="scss">
.financing {
    &__list > * + * {
        margin-top: $space-l;
    }
}
</style>
