export const isClientSide = () => typeof window !== "undefined";

export const isMobile = () => window.matchMedia("(max-width: 768px)").matches;

export const ssrIsMobile = () => {
    if (!isClientSide()) {
        return true;
    }

    return isMobile();
};
