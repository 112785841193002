<script lang="ts" setup>
import Button from "@/Components/Buttons/Button.vue";
import Subtitle from "@/Components/Typography/SubTitle.vue";
import { Link } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
    <section class="section container">
        <p class="section__desc">
            <b>{{ t("home.dream.text[0]") }}</b>
            {{ t("home.dream.text[1]") }}
        </p>
        <Subtitle class="section__main">
            {{ t("home.dream.subtitle[0]") }}
        </Subtitle>
        <div class="section__button">
            <Button
                :as="Link"
                :href="route('vehicles.index')"
                variante="arrow"
                >{{ t("home.dream.cta") }}</Button
            >
        </div>
    </section>
</template>

<style lang="scss" scoped>
.section {
    padding-block: var(--hp-y-space);
    display: grid;
    grid-template-columns: var(--grid-columns);
    color: $grey;

    &__desc {
        line-height: var(--description-line-height);
    }

    &__main {
        margin-top: 60px;
    }

    @include medium-up {
        &__desc {
            grid-column: 3;
        }

        &__main {
            grid-row: 2;
            grid-column: 1/4;
        }

        &__button {
            grid-column: 1/4;
            text-align: center;
        }
    }

    @include medium-down {
        &__desc {
            b {
                display: block;
                margin-bottom: 1rem;
            }
        }

        &__main,
        &__button {
            padding-left: 4rem;
        }
    }
}
@include medium-down {
    .mobile-hidden {
        display: none;
    }
}
</style>
