<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11 11H3V13H11H13H21V11H13H11Z" fill="currentColor" />
    </svg>
</template>
