<script setup lang="ts">
import { ref, watch } from "vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { VehicleAlgoliaRecord } from "@/types";
import { vehicleDisplayName } from "@/helpers/vehicle";


const props = defineProps<{
    isMouseover: boolean;
    vehicle: VehicleAlgoliaRecord;
    url: string;
}>();

const isLoading = ref(true);

const handleImageLoad = (event) => {
    event.target.classList.add("loaded");
    isLoading.value = false;
};

const vehicleCarousel = ref(null);

watch(
    () => props.vehicle.photos,
    async () => {
        isLoading.value = true;
    },
);
</script>

<template>
    <div class="vehicle-carousel">
        <Carousel
            :class="isMouseover ? 'is-hover' : ''"
            :wrap-around="true"
            ref="vehicleCarousel"
        >
            <Slide
                v-for="(photo, index) in vehicle.photos"
                :key="`${vehicle.id}-${index}`"
            >
                <div class="vehicle-carousel__item">
                    <a :href="url">
                        <img
                            loading="lazy"
                            :key="`img-${vehicle.id}-${index}`"
                            :src="photo"
                            @load="handleImageLoad"
                            class="fade-in"
                            :alt="`Joinsteer Leasing - ${vehicleDisplayName(vehicle)}`"
                        />
                        <div v-if="isLoading" class="loader"></div>
                    </a>
                </div>
            </Slide>

            <template #addons v-if="Object.keys(vehicle.photos).length !== 1">
                <div class="vehicle-carousel__navigation">
                    <Navigation />
                </div>

                <Pagination />
            </template>
        </Carousel>
    </div>
</template>

<style lang="scss" scoped>
.carousel {
    $self: &;

    --vc-pgn-width: 6px;
    --vc-pgn-height: 6px;
    --vc-pgn-border-radius: 6px;
    --vc-pgn-background-color: #{$light-bg};
    --vc-pgn-active-color: #{$dark-font};
    --vc-nav-background: #{$primary};
    --vc-nav-color-hover: #{$light-blue};
    --vc-pgn-margin: 0 3px;

    &__pagination {
        margin: 1rem 0 0 0;
        padding: 0;
    }

    &__slide {
        align-items: flex-end;
    }
}

.vehicle-carousel {
    &__item {
        position: relative;
        width: 100%;
        padding-bottom: 65%;

        & > a {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            & > img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 0.125rem;
                overflow: hidden;
            }

            & > .loader {
                width: 100%;
                height: 100%;
                border-radius: 0.125rem;
                background: linear-gradient(
                    90deg,
                    rgb(242, 245, 246) 25%,
                    rgb(232, 235, 236) 50%,
                    rgb(242, 245, 246) 75%
                );
                background-size: 200% 100%;
                animation: loading 1.5s infinite;
            }
        }
    }

    &__navigation {
        position: absolute;
        top: 0;
        width: 100%;
        padding-bottom: 65%;
        pointer-events: none;
    }
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.fade-in {
    opacity: 0;
    transition: opacity 0.2s ease-in;
}

.fade-in.loaded {
    opacity: 1;
}
</style>

<style lang="scss">
.vehicle-card {
    .carousel__prev,
    .carousel__next {
        display: none;
        bottom: 0.5rem;
        top: auto;
        margin: 0;
        transform: translateY(0);

        border-radius: 0.125rem;
    }

    .carousel__next {
        left: auto;
        right: 0.5rem;
    }

    .carousel__prev {
        right: auto;
        left: 0.5rem;
    }

    @media (hover: hover) {
        &:hover {
            .carousel {
                --vc-pgn-active-color: #{$primary};
            }

            .carousel__prev,
            .carousel__next {
                display: flex;
                pointer-events: auto;
            }
        }
    }
}
</style>
