import { DynamicContent } from "@/types";
import { useI18n } from "vue-i18n";

export const getValueOf = (
    dynamicContent: DynamicContent[],
    key: string,
    emptyMessage?: boolean,
) => {
    const { t } = useI18n();

    if (key) {
        return (
            dynamicContent?.find((item) => item.key === key)?.value ||
            (emptyMessage ? t("dynamicContent.emptyMessage") : null)
        );
    }
};
