<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useGlobalEmitter } from "@/Composables/utils/useGlobalEmitter";
import { random } from "lodash-es";
import Logo from "@/Components/Icons/Logo.vue";
const { emitter } = useGlobalEmitter();
const progress = ref<number>(0);
const show = ref<boolean>(true);

let timeout;
const loop = () => {
    // fake progress, we can't really know the real progress value. we just know when it's finished
    if (progress.value < 75) {
        if (timeout) clearTimeout(timeout);
        progress.value += 10;
        timeout = setTimeout(loop, random(400, 750));
    }
};

onMounted(() => {
    emitter.on("loading:progress", (value: "start" | "end") => {
        if (value === "end") {
            progress.value = 100;
            setTimeout(() => {
                show.value = false;
            }, 200);
        } else {
            progress.value = 10;
            loop();
        }
    });
});
</script>
<template>
    <Transition name="fade" mode="in-out">
        <div class="loader" v-if="show">
            <Logo />
        </div>
    </Transition>
</template>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 700ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9999;

    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        content: "";
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        background-image: url("../../../images/loader-video.webp");
        background-size: cover;
        background-position: center center;
        filter: blur(4px);

        @media (max-width: 600px) {
            background-image: url("../../../images/loader-video-mobile.webp");
        }
    }

    svg {
        position: relative;
        z-index: 2;
        width: 3.7rem;
        height: 3.7rem;
        animation: pulse 500ms infinite;
        color: white;
    }
}
</style>
