<script setup lang="ts">
import algoliasearch from "algoliasearch/lite";
import { history } from "instantsearch.js/es/lib/routers";
import { singleIndex } from "instantsearch.js/es/lib/stateMappings";
import { usePage } from "@inertiajs/vue3";
const page = usePage();
import VehicleCard from "../Cards/VehicleCard.vue";
import Button from "../Buttons/Button.vue";
import { Vehicle, VehicleMake, VehicleModel } from "../../types";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { isClientSide } from "@/helpers/client";
import {
    AisInfiniteHits,
    AisConfigure,
    AisStats,
    AisInstantSearch,
} from "vue-instantsearch/vue3/es";

const props = defineProps<{
    vehicle?: Vehicle;
    vehicleMake: VehicleMake;
    title?: string;
    itemSeo?: string;
}>();
const maxHitsPerPage = props.vehicle ? 4 : 8;
const hitsPerPage = ref(maxHitsPerPage);

const searchClient = algoliasearch(
    page.props.algolia.appId,
    page.props.algolia.searchKey,
);

const isClient = ref(isClientSide());

const routing = {
    router: history(),
    stateMapping: singleIndex(page.props.algolia.indexName),
};

const updateHitsPerPage = () => {
    hitsPerPage.value = window.innerWidth <= 655 ? 3 : maxHitsPerPage;
};

const filters = () => {
    const facetFilters: string[][] = [];
    if (props.vehicle !== undefined) {
        facetFilters.push([`model:${props.vehicle.vehicle_model.designation}`]);
        const algoliaVehicleId = `App\\Models\\Vehicle::${props.vehicle.id}`;
        facetFilters.push([`objectID:-${algoliaVehicleId}`]);
    } else {
        if (page.component === "Vehicle/LandingModel") {
            facetFilters.push([`make:${props.vehicleMake.designation}`]);
            facetFilters.push([
                `model:${
                    (page.props.vehicleModel as VehicleModel).designation
                }`,
            ]);
        } else {
            facetFilters.push([`make:${props.vehicleMake.designation}`]);
        }
    }

    return facetFilters;
};

onMounted(() => {
    updateHitsPerPage();
    window.addEventListener("resize", updateHitsPerPage);
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", updateHitsPerPage);
});
</script>
<template>
    <section class="landing-offer container">
        <ais-instant-search
            :search-client="searchClient"
            :index-name="$page.props.algolia.indexName"
            :routing="routing"
        >
            <ais-configure
                :facet-filters.camel="filters()"
                :hits-per-page.camel="hitsPerPage"
            />
            <ais-stats>
                <template #default="{ nbHits }">
                    <h2 class="landing-make__title" v-if="vehicle">
                        ces véhicules sont faits pour vous
                    </h2>
                    <h2 class="landing-make__title" v-else>
                        Joinsteer vous propose <br />
                        {{ $filters.number(nbHits) }} véhicules
                        {{ itemSeo }} exclusifs
                    </h2>
                </template>
            </ais-stats>

            <ais-infinite-hits>
                <template #default="{ items }">
                    <ul class="landing-make__wrapper">
                        <li v-for="item in items" :key="item.objectID">
                            <VehicleCard :item="item" />
                        </li>
                    </ul>
                    <div class="landing-make__action" v-if="!vehicle">
                        <Button
                            as="a"
                            :href="
                                route('home') +
                                '/marketplace?menu[make]=' +
                                vehicleMake.designation
                            "
                        >
                            Voir tous les véhicules
                            {{ vehicleMake.designation }}
                        </Button>
                    </div>
                </template>
            </ais-infinite-hits>
        </ais-instant-search>
    </section>
</template>
