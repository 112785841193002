import { ref, watch } from "vue";
import { isClientSide } from "@/helpers/client";

type ToggleOptions = {
    defaultValue: boolean;
};

export const useToggle = ({ defaultValue = false }: ToggleOptions) => {
    const show = ref<boolean>(defaultValue);

    const toggle = () => {
        show.value = !show.value;
    };

    const handleClickOutsideShowSortOptions = () => {
        show.value = false;
    };

    watch(
        () => show.value,
        (value) => {
            if (!isClientSide()) return;

            if (value) {
                window.addEventListener(
                    "click",
                    handleClickOutsideShowSortOptions,
                );
            } else {
                window.removeEventListener(
                    "click",
                    handleClickOutsideShowSortOptions,
                );
            }
        },
    );

    return { show, toggle };
};
