<script setup lang="ts">
import TextInput from "@/Components/Forms/Input/TextInput.vue";
import PhoneInput from "@/Components/Forms/Input/PhoneInput.vue";
import Checkbox from "@/Components/Forms/Input/Checkbox.vue";
import Button from "@/Components/Buttons/Button.vue";
import Counter from "@/Components/Icons/Counter.vue";
import { router, useForm, usePage } from "@inertiajs/vue3";
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { User, Vehicle, VehiclePreview } from "@/types";
import Modal from "@/Modal/Modal.vue";
import LoginCard from "@/Components/Cards/LoginCard.vue";
import Simulator from "@/Layouts/Simulator.vue";
import { watch } from "vue";
import Calculator from "@/Components/Calculator.vue";
import PriceInput from "@/Components/Forms/Input/PriceInput.vue";
import Label from "@/Components/Forms/Label.vue";
import Error from "@/Components/Forms/Error.vue";
import { ssrIsMobile } from "@/helpers/client";

const page = usePage();
const props = defineProps<{
    vehicle: Vehicle;
    vehiclePreview: VehiclePreview;
    initialData: {
        firstname: string;
        lastname: string;
        email: string;
        phone: string;
        job: string;
        fiscal_reference_income: number;
        has_leasing: boolean;
        accept: boolean;
        offer: string;
        duration: number;
        first_rent: number;
        mileage: number;
        gclid: string | null;
        fbclid: string | null;
    };
    minFirstRent: number | null;
    estimatedPrice: number | null
}>();

const currentUserIsMobile = ref(ssrIsMobile());

console.log("currentUserIsMobile", currentUserIsMobile.value);
console.log("auth user", page.props.auth?.user?.email);

const { t } = useI18n();

const currentIntermediateScreen = ref(1);

const openingLoginModal = ref(false);
const openLoginModal = (val: boolean) => {
    openingLoginModal.value = val;
};

const fillFormFromAuth = () => {
    openLoginModal(false);
    router.visit(route('simulations.start', { vehicle: props.vehicle }), {
        preserveScroll: true,
    });
};

const form = useForm({
    ...props.initialData,
    gclid: localStorage.getItem("gclid"),
    fbclid: localStorage.getItem("fbclid"),
});

const submit = () => {
    if (hasFirstRentError.value != true) {
        form.transform((data) => ({
            ...data,
            vehicle_id: props.vehicle.id,
        })).post(route("simulations.init"), {
            onError: () => {
                if (currentIntermediateScreen.value > 1) {
                    let intermediateScreenError = 5;
                    if (
                        form.errors.email ||
                        form.errors.phone ||
                        form.errors.firstname ||
                        form.errors.lastname
                    ) {
                        intermediateScreenError = 2;
                    } else if (
                        form.errors.job ||
                        form.errors.fiscal_reference_income
                    ) {
                        intermediateScreenError = 3;
                    }

                    currentIntermediateScreen.value = intermediateScreenError;
                }
            },
        });
    }
};

const goToScreen = (screenNumber: number) => {
    if (screenNumber <= 0) return;
    currentIntermediateScreen.value = screenNumber;
};

const isStep2Filled = computed(() => {
    return form.email && form.phone && form.firstname && form.lastname;
});

const isStep3Filled = computed(() => {
    return (
        form.job &&
        form.fiscal_reference_income &&
        form.accept &&
        form.has_leasing != null
    );
});

const currentYear = computed(() => {
    return new Date().getFullYear();
});

watch(
    () => props.initialData,
    () => {
        if (page.props.auth.user) {
            form.firstname = props.initialData.firstname;
            form.lastname = props.initialData.lastname;
            form.phone = props.initialData.phone;
            form.email = props.initialData.email;
            form.job = props.initialData.job;
            form.fiscal_reference_income =
                props.initialData.fiscal_reference_income;
        }
    },
);

const hasFirstRentError = computed(() => {
    return form.first_rent && form.first_rent > props.vehicle.max_first_rent;
});


const simulationForm = useForm({
    offer: props.initialData.offer,
    duration: props.initialData.duration,
    first_rent: props.initialData.first_rent,
    mileage: props.initialData.mileage,
});

const monthlyPrice = ref(props.estimatedPrice ?? 0);


console.log("monthlyPrice", monthlyPrice.value);

const simulator = ref();
const showMonthyPrice = ref(false);
const isLoading = ref(false);

const handleEndLoader = () => {
    showMonthyPrice.value = true;
};

const computeNewRevision = () => {
    isLoading.value = true;
    simulationForm.post(
        route("simulations.compute", {
            vehicle: props.vehicle.id,
        }),
        {
            preserveScroll: true,
            onSuccess: () => {
                if (page.props.flash.data && page.props.flash.data.price) {
                    monthlyPrice.value = page.props.flash.data.price;
                }

                simulationForm.get(
                    route("simulations.start",
                    { vehicle: props.vehicle, }),
                    {
                        preserveState: true,
                    }
                );

                isLoading.value = false;
            }
        },
    );
    simulationForm.defaults();
};

</script>

<template>
    <Simulator
        :show-message="currentIntermediateScreen == 1"
        :vehicle-preview="vehiclePreview"
        :vehicle="vehicle"
        :go-to-screen="goToScreen"
        :current-tunnel-step="1"
        :current-intermediate-step="currentIntermediateScreen"
        ref="simulator"
    >
        <template #message>
            {{ t("tunnel.hello") }} <br />
            {{ t("tunnel.needSomeInformations") }}
        </template>

        <Modal :show="openingLoginModal" @close="openLoginModal(false)">
            <template #content>
                <LoginCard @logged="fillFormFromAuth" />
            </template>
        </Modal>

        <form @submit.prevent="submit">
            <div
                class="mobile-hidden"
                :class="{ visible: currentIntermediateScreen == 1 }"
            >
                <template class="mobile-only">
                    <Button
                        type="button"
                        @click="goToScreen(2)"
                        class="form-button"
                    >
                        {{ t("actions.continue") }}
                    </Button>
                </template>
            </div>

            <div
                class="mobile-hidden row-margin"
                :class="{ visible: currentIntermediateScreen == 2 }"
            >
                <div class="simulation-grid">
                    <PhoneInput
                        id="phone"
                        v-model="form.phone"
                        type="text"
                        :label="
                            t('forms.whatsYour', {
                                attr: t('attributes.phone'),
                            })
                        "
                        required
                        placeholder="06 00 00 00 00"
                        :message="form.errors.phone"
                    />

                    <TextInput
                        id="email"
                        v-model="form.email"
                        type="email"
                        :label="
                            t('forms.whatsYourE', {
                                attr: t('attributes.email'),
                            })
                        "
                        :message="form.errors.email"
                        required
                    />

                    <TextInput
                        id="firstname"
                        v-model="form.firstname"
                        type="text"
                        :label="
                            t('forms.whatsYour', {
                                attr: t('attributes.firstname'),
                            })
                        "
                        :message="form.errors.firstname"
                        required
                    />

                    <TextInput
                        id="lastname"
                        v-model="form.lastname"
                        type="text"
                        :label="
                            t('forms.whatsYour', {
                                attr: t('attributes.lastname'),
                            })
                        "
                        :message="form.errors.lastname"
                        required
                    />
                </div>

                <template class="mobile-only">
                    <Button
                        type="button"
                        @click="goToScreen(3)"
                        :disabled="!isStep2Filled"
                        class="form-button"
                    >
                        {{ t("actions.continue") }}
                    </Button>
                </template>
            </div>

            <div
                class="mobile-hidden row-margin"
                :class="{ visible: currentIntermediateScreen == 3 }"
            >
                <div class="simulation-grid">
                    <TextInput
                        id="job"
                        v-model="form.job"
                        type="text"
                        :label="
                            t('forms.whatsYourE', {
                                attr: t('attributes.job'),
                            })
                        "
                        :message="form.errors.job"
                        required
                    />

                    <div class="row-margin">
                        <Label
                            required
                            :label="
                                t('forms.whatsYour', {
                                    attr:
                                        t(
                                            'attributes.fiscal_reference_income',
                                        ) +
                                        ' ' +
                                        currentYear,
                                })
                            "
                        />
                        <PriceInput
                            :min-first-rent="minFirstRent"
                            v-model="form.fiscal_reference_income"
                            :step="500"
                            :show-buttons="false"
                            :classic="true"
                        />
                        <Error :error="form.errors.fiscal_reference_income" />
                    </div>

                    <div>
                        <span class="radio-group__label">{{
                            t("tunnel.haveLeasing")
                        }}</span>
                        <div class="radio-group">
                            <label
                                for="leasing_false"
                                class="radio-label"
                                :class="{ active: !form.has_leasing }"
                            >
                                <Checkbox
                                    id="leasing_false"
                                    v-model:checked="form.has_leasing"
                                    :value="false"
                                    type="radio"
                                />

                                <span class="radio-label__text">
                                    {{ t("actions.no") }}
                                </span>
                            </label>

                            <label
                                for="leasing_true"
                                class="radio-label"
                                :class="{ active: form.has_leasing }"
                            >
                                <Checkbox
                                    id="leasing_true"
                                    v-model:checked="form.has_leasing"
                                    :value="true"
                                    type="radio"
                                />

                                <span class="radio-label__text">
                                    {{ t("actions.yes") }}
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="desktop-no-grid">
                        <hr />
                        <label class="accept">
                            <Checkbox
                                v-model:checked="form.accept"
                                :value="true"
                            />
                            <span class="accept--label">
                                {{ t("iAcceptThe") }}
                                <a :href="route('privacy')" target="_blank">
                                    {{ t("cgu") }}
                                </a>
                            </span>
                        </label>
                    </div>
                </div>

                <Button
                    type="submit"
                    :disabled="
                        !isStep2Filled ||
                        !isStep3Filled ||
                        hasFirstRentError != false
                    "
                    class="form-button"
                >
                    {{ t("actions.validateInfos") }}
                </Button>
            </div>
        </form>

        <div
            v-if="!$page.props.auth.user && currentIntermediateScreen == 1"
            class="has-account"
        >
            <p class="has-account__message">
                {{ t("tunnel.accountAlreadyExist") }}
            </p>
            <button class="has-account__button" @click="openLoginModal(true)">
                {{ t("actions.signInV2") }}
            </button>
        </div>

        <template #sidebarContent>
            <div class="simulator__sidebar__content__calculator">
                <Calculator
                    :min-first-rent="minFirstRent"
                    :max-first-rent="vehicle.max_first_rent"
                    :has-first-rent-error="hasFirstRentError == true"
                    :light-mode="false"
                    v-model:offer="simulationForm.offer"
                    v-model:duration="simulationForm.duration"
                    v-model:first-rent="simulationForm.first_rent"
                    v-model:mileage="simulationForm.mileage"
                />
            </div>
        </template>

        <template #sidebarFooter>
            <template v-if="showMonthyPrice">
                <div
                    class="simulator__sidebar__footer__content"
                    :class="{
                        '--reduced-padding': simulationForm.isDirty,
                    }"
                >
                    <div class="simulator__sidebar__footer__content__result">
                        <span class="simulator__sidebar__footer__title">
                            {{ t("tunnel.yourMonthlyPrice") }}
                        </span>
                        <span class="simulator__sidebar__footer__price">
                            <span
                                class="simulator__sidebar__footer__price__value"
                                :class="{
                                    blur:
                                        (!page.props.auth.user &&
                                            currentUserIsMobile) ||
                                        hasFirstRentError == true ||
                                        simulationForm.isDirty ||
                                        monthlyPrice == 0 ||
                                        isLoading,
                                }"
                                v-if="showMonthyPrice"
                            >
                                {{
                                    hasFirstRentError
                                        ? "0000"
                                        : $filters.currency(monthlyPrice, false)
                                }}
                            </span>
                            {{ t("vehicle.perMonth") }}
                        </span>
                    </div>

                    <Button
                        type="button"
                        class="compute-button"
                        @click="computeNewRevision"
                        v-if="simulationForm.isDirty"
                        :disabled="hasFirstRentError == true"
                    >
                        {{ t("tunnel.computeYoutMonthlyPrice") }}
                    </Button>
                </div>
            </template>
            <template v-else>
                <Counter @animation-ended="handleEndLoader" />
                <span class="compute-progress">
                    {{ t("tunnel.monthlyPriceComputing") }}
                </span>
            </template>
        </template>
    </Simulator>
</template>

<style lang="scss" scoped>
.desktop-no-grid {
    @include medium-up {
        grid-column: 1/3;
    }
}
.input__label {
    padding-bottom: 0.5rem;
    display: block;
}
.row-margin {
    margin-bottom: 1rem;
}

.has-account {
    margin-top: 1.5rem;
    text-align: center;

    &__message {
        padding: 0;
        margin: 0 0 0.25rem 0;
    }

    &__button {
        background: none;
        border: none;
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }
}


.cta {
    margin: 1.5rem 0;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    @include medium-down {
        grid-template-columns: repeat(1, 1fr);

        & > *:first-child {
            order: 2;
        }
    }

    & > * {
        width: 100%;
    }
}

.compute-button {
    width: 100%;
    margin-top: 1rem;
}


.compute-progress {
    font-family: $bebas;
    color: $primary;
    font-size: 1.75rem;
    text-transform: uppercase;
    padding: 2rem 0;
    text-align: center;
    display: block;
    width: 100%;

    @include medium-down {
        font-size: 1.25rem;
    }
}
</style>
