<script setup lang="ts">
import Simulator from "@/Layouts/Simulator.vue";
import Pagination from "@/Components/Pagination.vue";
import Calculator from "@/Components/Calculator.vue";
import Checkbox from "@/Components/Forms/Input/Checkbox.vue";
import {
    Simulation,
    Revision,
    PaginatedResponse,
    Vehicle,
    VehiclePreview,
} from "@/types";
import Button from "@/Components/Buttons/Button.vue";
import Counter from "@/Components/Icons/Counter.vue";
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { router, useForm, usePage } from "@inertiajs/vue3";
import VehicleId from "@/Components/VehicleId.vue";

const { t } = useI18n();
const page = usePage();

const props = defineProps<{
    vehicle: Vehicle;
    simulation: Simulation;
    currentRevision: Revision;
    revisions: PaginatedResponse<Revision>;
    vehiclePreview: VehiclePreview;
    modelLabel: string;
    modelUrl: string;
    minFirstRent: number | null;
}>();

const form = useForm<{
    revision: number | undefined;
}>({
    revision: props.currentRevision.is_selected
        ? props.currentRevision.id
        : props.revisions.data[props.revisions.data.length - 1]?.id,
});

const simulationForm = useForm({
    offer: props.currentRevision.is_selected
        ? props.currentRevision.offer
        : props.revisions.data[props.revisions.data.length - 1]?.offer ?? "",
    duration: props.currentRevision.is_selected
        ? props.currentRevision.duration
        : props.revisions.data[props.revisions.data.length - 1]?.duration ?? 0,
    first_rent: props.currentRevision.is_selected
        ? props.currentRevision.first_rent
        : props.revisions.data[props.revisions.data.length - 1]?.first_rent ?? 0,
    mileage: props.currentRevision.is_selected
        ? props.currentRevision.mileage
        : props.revisions.data[props.revisions.data.length - 1]?.mileage ?? 0,
});

const currentIntermediateScreen = ref(1);

const monthlyPrice = ref(
    props.currentRevision.is_selected
        ? props.currentRevision.price
        : props.revisions.data[props.revisions.data.length - 1]?.price ?? 0,
);
const showMonthyPrice = ref(false);
const simulator = ref();

const handleEndLoader = () => {
    showMonthyPrice.value = true;
};

const submit = () => {
    if (form.revision) {
        form.post(
            route("simulations.submit", {
                simulation: props.simulation,
            }),
        );
    }
};

const hasFirstRentError = computed(() => {
    return (
        simulationForm.first_rent &&
        simulationForm.first_rent > props.vehicle.max_first_rent
    );
});

const hasFormChanges = computed(() => {
    const currentRevision = props.revisions.data.find(
        (rev) => rev.id === form.revision,
    );
    if (!currentRevision) return false;

    return (
        simulationForm.offer !== currentRevision.offer ||
        simulationForm.duration !== currentRevision.duration ||
        simulationForm.first_rent !== currentRevision.first_rent ||
        simulationForm.mileage !== currentRevision.mileage
    );
});

const isLoading = ref(false);

const computeNewRevision = () => {
    isLoading.value = true;
    simulationForm.post(
        route("simulations.revision", { simulation: props.simulation }),
        {
            preserveScroll: true,
            onSuccess: (response) => {
                isLoading.value = false;
                simulator.value.forceOpenSidebar(false);

                // Utiliser Inertia pour faire un rechargement partiel qui préserve l'état
                // et passe à la page 1 sans réouvrir la sidebar
                router.reload({
                    only: ["revisions"],
                    data: { page: 1 },
                });
            },
        },
    );
};

const selectRevision = (revision) => {
    form.revision = revision.id;
    monthlyPrice.value = revision.price;

    simulationForm.defaults({
        offer: revision.offer,
        duration: revision.duration,
        first_rent: revision.first_rent,
        mileage: revision.mileage,
    });
    simulationForm.reset();
};

// Watcher pour sélectionner automatiquement la dernière révision
watch(
    () => props.revisions.data,
    (newRevisions) => {
        if (newRevisions.length > 0) {
            const lastRevision = newRevisions[newRevisions.length - 1];
            form.revision = lastRevision.id;
            monthlyPrice.value = lastRevision.price;
            simulationForm.offer = lastRevision.offer;
            simulationForm.duration = lastRevision.duration;
            simulationForm.first_rent = lastRevision.first_rent;
            simulationForm.mileage = lastRevision.mileage;
        }
    },
    { deep: true }
);
</script>

<template>
    <Simulator
        :show-message="currentIntermediateScreen == 1"
        :vehicle-preview="vehiclePreview"
        :vehicle="vehicle"
        :current-tunnel-step="3"
        :current-intermediate-step="currentIntermediateScreen"
        ref="simulator"
    >
        <template #message>
            {{ t("tunnel.hereTheResult", revisions.total) }}
            <a :href="modelUrl" target="_blank">
                {{ modelLabel }}
            </a>
            <VehicleId :id="vehicle.id" />
        </template>

        <p>{{ t("tunnel.chooseRevision") }}</p>

        <div
            class="resume"
            v-for="revision in revisions.data"
            :key="revision.id"
            @click="selectRevision(revision)"
        >
            <div class="resume__header">
                <Checkbox
                    v-model:checked="form.revision"
                    type="radio"
                    :value="revision.id"
                />
                <h2 class="resume__title">
                    {{
                        t("tunnel.yourSimulation", {
                            offer: revision.offer.toUpperCase(),
                            duration: revision.duration,
                        })
                    }}
                </h2>
            </div>

            <div class="resume__separator"></div>

            <table class="resume__table">
                <tbody>
                    <tr v-if="revision.offer == 'loa'">
                        <td>{{ t("calculator.vehiclePrice") }}</td>
                        <td>
                            {{
                                $filters.currency(revision.vehicle_price, false)
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ t("calculator.firstRent") }}</td>
                        <td>
                            {{ $filters.currency(revision.first_rent, false) }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ t("vehicle.monthlyPrice") }}</td>
                        <td>
                            {{ $filters.currency(revision.price, false) }}
                        </td>
                    </tr>
                    <tr v-if="revision.offer == 'loa'">
                        <td>{{ t("calculator.loaAmount") }}</td>
                        <td>
                            {{ $filters.currency(revision.loa_amount, false) }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ t("calculator.annualMileage") }}</td>
                        <td>{{ $filters.number(revision.mileage) }} km</td>
                    </tr>
                </tbody>
            </table>

            <p class="insurance">
                Hors perte financière assurance facultative de
                <span class="no-break"
                    >{{ $filters.currency(revision.insurance_amount, false) }} /
                    mois</span
                >
            </p>
        </div>


        <div class="cta">
            <Button
                @click="submit"
                variante="quinary"
                as="a"
                :href="route('user.financingRequests')"
            >
                {{ t("tunnel.goToSimulations") }}
            </Button>
            <Button type="button" @click="submit" :disabled="!form.revision">
                {{ t("tunnel.priorityCallbackRequest") }}
            </Button>
        </div>

        <Pagination :data="revisions" />


        <div class="end-message">
            <span class="bold">{{ t("tunnel.changeParametersLater") }}</span>
        </div>

        <div class="mentions">
            <p>
                Un crédit vous engage et doit être remboursé. Il est essentiel
                de vérifier vos capacités de remboursement avant de vous engager
                dans un tel accord. Les conséquences d'un non-remboursement
                peuvent être significatives et affecter votre crédibilité
                financière.
            </p>

            <p>
                Les simulations de financement fournies sur notre site web sont
                à titre indicatif et ne constituent en aucun cas une offre ferme
                ou un engagement contractuel. Ces simulations sont basées sur
                des paramètres généraux et sont destinées à vous donner une idée
                approximative des termes et conditions qui pourraient être
                applicables. Ainsi, les conditions spécifiques peuvent différer
                de celles affichées dans nos simulations.
            </p>

            <p>
                Pour une analyse complète et personnalisée de votre situation
                financière et pour obtenir des conseils adaptés à vos besoins
                spécifiques, il est recommandé de consulter un conseiller
                financier professionnel.
            </p>

            <p>
                En utilisant les outils de simulation de financement de notre
                site, vous reconnaissez avoir pris connaissance de ces
                avertissements et acceptez les termes et conditions
                d'utilisation de notre site.
            </p>
        </div>

        <template #sidebarContent>
            <div class="simulator__sidebar__content__calculator">
                <Calculator
                    :min-first-rent="minFirstRent"
                    :max-first-rent="vehicle.max_first_rent"
                    :has-first-rent-error="hasFirstRentError == true"
                    :light-mode="false"
                    v-model:offer="simulationForm.offer"
                    v-model:duration="simulationForm.duration"
                    v-model:first-rent="simulationForm.first_rent"
                    v-model:mileage="simulationForm.mileage"
                />
            </div>
        </template>

        <template #sidebarFooter>
            <template v-if="showMonthyPrice">
                <div
                    class="simulator__sidebar__footer__content"
                    :class="{
                        '--reduced-padding': hasFormChanges,
                    }"
                >
                    <div class="simulator__sidebar__footer__content__result">
                        <span class="simulator__sidebar__footer__title">
                            {{ t("tunnel.yourMonthlyPrice") }}
                        </span>
                        <span class="simulator__sidebar__footer__price">
                            <span
                                class="simulator__sidebar__footer__price__value"
                                :class="{
                                    blur:
                                        hasFirstRentError == true ||
                                        hasFormChanges ||
                                        isLoading,
                                }"
                                v-if="showMonthyPrice"
                            >
                                {{
                                    hasFirstRentError
                                        ? "0000"
                                        : $filters.currency(monthlyPrice, false)
                                }}
                            </span>
                            {{ t("vehicle.perMonth") }}
                        </span>
                    </div>

                    <Button
                        type="button"
                        class="compute-button"
                        @click="computeNewRevision"
                        v-if="hasFormChanges"
                        :disabled="hasFirstRentError == true"
                    >
                        {{ t("tunnel.computeYoutMonthlyPrice") }}
                    </Button>
                </div>
            </template>
            <template v-else>
                <Counter @animation-ended="handleEndLoader" />
                <span class="compute-progress">
                    {{ t("tunnel.monthlyPriceComputing") }}
                </span>
            </template>
        </template>
    </Simulator>
</template>

<style lang="scss" scoped>
.mentions {
    p {
        font-size: 0.688rem;
        text-align: center;
        opacity: 0.5;
    }
}

.insurance {
    font-size: 0.875rem;
    padding: 0;
    margin: 1rem 0 0 0;

    @include medium-down {
        margin: 0.5rem 0 0 0;
        line-height: 1.25rem;
        font-size: 0.7rem;
    }
}
.resume {
    padding: 1rem 1.5rem;
    background: white;
    border: 1px solid $grey-input;
    margin-bottom: 0.5rem;

    &:hover {
        cursor: pointer;
        border-color: $dark-bg;
    }

    &__separator {
        display: block;
        width: 100%;
        height: 1px;
        background: $light-bg;
        margin: 0.75rem 0;
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__title {
        font-size: 1.125rem;
        padding: 0;
        margin: 0 0 0 0.5rem;
        font-weight: 700;

        @include medium-down {
            font-size: 1rem;
        }
    }

    &__text {
        line-height: 1.5rem;
    }

    &__list {
        line-height: 1.5rem;
        padding: 0 0 0 1rem;
    }

    &__table {
        width: 100%;
        tr {
            td {
                padding: 0.25rem 0;
                @include medium-down {
                    font-size: 0.875rem;
                }

                &:last-child {
                    text-align: right;
                    font-weight: 700;
                }
            }
        }
    }
}

.cta {
    margin: 1.5rem 0;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    @include medium-down {
        grid-template-columns: repeat(1, 1fr);

        & > *:first-child {
            order: 2;
        }
    }

    & > * {
        width: 100%;
    }
}

.compute-button {
    width: 100%;
    margin-top: 1rem;
}

.end-message {
    opacity: 0.6;

    span {
        display: block;
        text-align: center;
        line-height: 1.5rem;
        font-size: 0.875rem;

        &.bold {
            font-weight: 700;
        }
    }
}

.compute-progress {
    font-family: $bebas;
    color: $primary;
    font-size: 1.75rem;
    text-transform: uppercase;
    padding: 2rem 0;
    text-align: center;
    display: block;
    width: 100%;

    @include medium-down {
        font-size: 1.25rem;
    }
}
</style>
