<script lang="ts" setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
    title: {
        type: String,
        default: "Filter",
    },
    open: {
        type: Boolean,
        default: true,
    },
    forceOpen: {
        type: Boolean,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const isOpen = ref(props.forceOpen ?? (props.open && !props.disabled));

const setOpen = (open: boolean) => {
    isOpen.value = open;
};

watch(
    () => props.forceOpen,
    (value) => {
        isOpen.value = value;
    },
);

defineExpose({ setOpen });
</script>
<template>
    <ais-panel>
        <template #header>
            <button
                class="widget-header"
                :class="{ 'widget-header--disabled': disabled }"
                :disabled="disabled"
                @click="setOpen((isOpen = !isOpen))"
                :title="disabled ? t('messages.noMakeSelected') : ''"
            >
                <span class="widget-title" :class="isOpen ? 'isOpen' : ''">
                    {{ title }}
                </span>
                <img
                    src="../../../images/icons/minus-algolia.svg"
                    class=""
                    width="24"
                    height="24"
                    alt="minus"
                    v-if="isOpen"
                />
                <img
                    src="../../../images/icons/plus-algolia.svg"
                    class=""
                    width="24"
                    height="24"
                    alt="plus"
                    v-if="!isOpen"
                />
            </button>
        </template>

        <div :hidden="!isOpen">
            <slot />
        </div>
    </ais-panel>
</template>

<style scoped lang="scss">
.widget-header {
    display: flex;
    justify-content: space-between;
    appearance: none;
    border: none;
    background: none;
    width: 100%;
    cursor: pointer;
    padding: 0;

    &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
.widget-title {
    color: #000;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    margin: 0;
    &.isOpen {
        margin: 0 0 1.5rem;
    }
}
</style>
