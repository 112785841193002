<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import Spot from "./Spot.vue";
import { throttle } from "lodash-es";
import { onMounted, onBeforeUnmount, ref } from "vue";

const { t } = useI18n();

const count = ref(0);

const handleProgress = throttle((e) => {
    const { progress } = e.detail;
    const percentProgress = Math.round(progress * 100);
    const currentCount = Math.min(4, Math.floor(percentProgress / 18));
    if (currentCount !== count.value) {
        count.value = currentCount;
    }
}, 50);

onMounted(() => {
    window.addEventListener("progressEvent", handleProgress);
});
onBeforeUnmount(() => {
    window.removeEventListener("progressEvent", handleProgress);
});
</script>
<template>
    <section
        class="carVisualization"
        data-scroll
        data-scroll-event-progress="progressEvent"
        data-scroll-offset="300, 600"
    >
        <img
            src="../../../../../resources/images/home/car-background-desktop.webp"
            alt=""
            loading="lazy"
        />
        <Spot
            class="carVisualization__spot -interior"
            :class="{ '-active': count === 0 }"
            :label="t('home.car.interior')"
        />
        <Spot
            class="carVisualization__spot -body"
            :class="{ '-active': count === 1 }"
            :label="t('home.car.body')"
        />
        <Spot
            class="carVisualization__spot -mecanical"
            :class="{ '-active': count === 2 }"
            :label="t('home.car.mecanical')"
        />
        <Spot
            class="carVisualization__spot -trace"
            :class="{ '-active': count === 3 }"
            :label="t('home.car.trace')"
        />
        <Spot
            class="carVisualization__spot -tyre"
            :class="{ '-active': count === 4 }"
            :label="t('home.car.tyre')"
        />
    </section>
</template>
<style lang="scss" scoped>
$spots: (
    trace: (
        top: 57%,
        left: 34%,
    ),
    mecanical: (
        top: 45%,
        left: 49%,
    ),
    body: (
        top: 42%,
        left: 38%,
    ),
    tyre: (
        top: 59%,
        left: 51.5%,
    ),
    interior: (
        top: 32%,
        left: 52%,
    ),
);
.carVisualization {
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.39;
    }
    img {
        width: 100%;
    }
    &__spot {
        position: absolute;
        @each $class, $props in $spots {
            &.-#{$class} {
                top: calc(
                    map-get($props, "top") - clampGenerator(1.5, 3, 40, 120)
                );
                left: calc(
                    map-get($props, "left") - clampGenerator(1.5, 3, 40, 120)
                );
            }
        }
    }
}
</style>
