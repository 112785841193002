<script setup lang="ts">
import { Link, usePage } from "@inertiajs/vue3";
import Rating from "@/Components/Rating.vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const page = usePage();
const { t } = useI18n();

defineProps<{
    make: string;
    minPrice?: number;
}>();

const socialLogoUrl = {};
page.props.socialLinks.forEach((link) => {
    socialLogoUrl[link.icon] = new URL(
        `../../images/icons/${link.icon}.svg`,
        import.meta.url,
    );
});

const currentYear = computed(() => {
    return new Date().getFullYear();
});
</script>

<template>
    <footer class="footer" :data-page="page.component">
        <div class="container" :data-page="page.component">
            <div class="footer__top">
                <div class="footer__top__rating">
                    <img
                        src="../../images/joinsteer-logo.svg"
                        class="footer__logo-img"
                        width="78"
                        height="39"
                        alt="Joinsteer Logo"
                    />

                    <Rating />
                </div>

                <div class="footer__top__links-container">
                    <div class="footer__top__seo-links">
                        <h2 class="footer__title">Location longue durée</h2>
                        <div class="footer__top__seo-links__list">
                            <Link
                                v-for="vehicleMake in $page.props.footerLink"
                                :href="
                                    route('vehicleMake.show', { vehicleMake })
                                "
                                class="seo-link"
                                :key="`footer-link--make-${vehicleMake.id}`"
                            >
                                LLD {{ vehicleMake.designation }}
                            </Link>
                        </div>
                    </div>

                    <div class="footer__top__seo-links">
                        <h2 class="footer__title">Nos Offres</h2>
                        <div class="footer__top__seo-links__list offers-list">
                            <a
                                href="https://www.joinsteer.com/marketplace"
                                class="seo-link"
                            >
                                Leasing
                            </a>
                            <a
                                href="https://car.joinsteer.com/location-voiture-par-abonnement"
                                class="seo-link"
                            >
                                Location par Abonnement
                            </a>
                            <a
                                href="https://car.joinsteer.com/depot-vente"
                                class="seo-link"
                            >
                                Vendez votre voiture avec Joinsteer
                            </a>
                        </div>
                    </div>
                </div>

                <div class="footer__top__seo-text">
                    <h2 class="footer__title">Joinsteer</h2>

                    <!-- Vehicle/LandingMake OU Vehicle/LandingModel -->
                    <div
                        v-if="
                            page.component === 'Vehicle/LandingMake' ||
                            page.component === 'Vehicle/LandingModel'
                        "
                        class="footer__top__seo-text__content"
                    >
                        <p>
                            {{
                                t("landingPage.footer.first_part", {
                                    make: make,
                                })
                            }}
                        </p>
                        <p v-if="minPrice">
                            {{
                                t("landingPage.footer.second_part", {
                                    make: make,
                                    minPrice: $filters.number(minPrice),
                                })
                            }}
                        </p>
                        <p>
                            {{
                                t("landingPage.footer.third_part", {
                                    make: make,
                                })
                            }}
                        </p>
                        <p>
                            {{
                                t("landingPage.footer.fourth_part", {
                                    make: make,
                                })
                            }}
                        </p>
                    </div>

                    <!-- Vehicle/LandingModelSpecification OU Vehicle/Show -->
                    <div
                        v-else-if="
                            page.component ===
                                'Vehicle/LandingModelSpecification' ||
                            page.component === 'Vehicle/Show'
                        "
                        class="footer__top__seo-text__content"
                    >
                        <p>
                            {{
                                t("landingPage.footer.fifth_part", {
                                    make: make,
                                })
                            }}
                        </p>
                        <p v-if="minPrice">
                            {{
                                t("landingPage.footer.second_part", {
                                    make: make,
                                    minPrice: $filters.number(minPrice),
                                })
                            }}
                        </p>
                        <p>
                            {{
                                t("landingPage.footer.third_part", {
                                    make: make,
                                })
                            }}
                        </p>
                        <p>
                            {{
                                t("landingPage.footer.fourth_part", {
                                    make: make,
                                })
                            }}
                        </p>
                    </div>

                    <div v-else class="footer__top__seo-text__content">
                        <p>{{ t("home.footer.first_part") }}</p>
                        <p>
                            {{ t("home.footer.second_part") }}
                        </p>
                        <p>
                            {{ t("home.footer.third_part") }}
                        </p>
                        <p>
                            {{ t("home.footer.fourth_part") }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="footer__bottom">
                <div class="footer__bottom-social-link">
                    <a
                        href="https://blog.joinsteer.com"
                        target="_blank"
                        rel="noreferrer noopener"
                        class="social-link blog-link"
                    >
                        <img
                            src="../../images/icons/le_blog_marketplace_icon.svg"
                            width="84"
                            height="20"
                            alt="Le Blog Joinsteer"
                            class="social-icon blog-icon"
                        />
                    </a>
                    <a
                        v-for="link in $page.props.socialLinks"
                        :key="`footer-link-${link.icon}`"
                        :href="link.link"
                        target="_blank"
                        rel="noreferrer noopener"
                        class="social-link"
                    >
                        <img
                            :src="socialLogoUrl[link.icon]"
                            width="24"
                            height="24"
                            :alt="link.alt"
                            class="social-icon"
                        />
                    </a>
                    <a
                        href="https://x.com/joinsteer"
                        target="_blank"
                        rel="noreferrer noopener"
                        class="social-link"
                    >
                        <img
                            src="../../images/icons/x_icon.svg"
                            width="20"
                            height="20"
                            alt="Joinsteer sur X"
                            class="social-icon"
                        />
                    </a>
                    <a
                        href="https://www.threads.net/@joinsteer"
                        target="_blank"
                        rel="noreferrer noopener"
                        class="social-link"
                    >
                        <img
                            src="../../images/icons/threads_icon.svg"
                            width="20"
                            height="20"
                            alt="Joinsteer sur Threads"
                            class="social-icon"
                        />
                    </a>
                </div>

                <div class="footer__bottom-links">
                    <Link
                        :href="route('legalInformation')"
                        class="footer__bottom-link"
                        >Mentions légales</Link
                    >
                    <Link :href="route('privacy')" class="footer__bottom-link"
                        >Confidentialité & CGU</Link
                    >
                </div>

                <p>{{ currentYear }} Joinsteer. All right reserved.</p>
            </div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
.footer {
    --mb-title: 1rem;

    padding: 2.5rem 0;
    background: $dark-bg;
    color: #fff;

    &__title {
        margin-top: 0;
        margin-bottom: var(--mb-title);
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
    }

    &__top {
        margin-bottom: 2.5rem;

        &__rating {
            img {
                display: block;
                margin: 0 auto var(--mb-title) auto;
                filter: invert(1);

                @media (min-width: 52rem) {
                    margin-left: 0;
                }
            }

            .rating {
                margin-top: 1.7rem;
            }
        }

        &__links-container {
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }

        &__seo-links {
            margin-bottom: 0;

            &__list {
                column-count: 2;

                &.offers-list {
                    column-count: 1;
                }
            }

            @media (min-width: 69rem) {
                &:first-child {
                    .footer__top__seo-links__list {
                        column-count: 3;
                    }
                }

                &:last-child {
                    .footer__top__seo-links__list:not(.offers-list) {
                        column-count: 2;
                    }

                    .footer__top__seo-links__list.offers-list {
                        column-count: 1;
                    }
                }
            }

            .seo-link {
                display: block;
                text-decoration: none;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                break-inside: avoid-column;
                padding: 0.5rem 0;
            }
        }

        &__seo-text {
            p {
                opacity: 0.8;
                font-size: 1rem;

                &:first-child {
                    padding-top: 0.5rem;
                }
            }
        }
    }

    &__bottom {
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        padding-top: 2rem;
        text-align: center;
        font-size: 0.875rem;
        line-height: 1.5;

        &-social-link {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.75rem;
            opacity: 0.6;

            .social-link {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;

                &.blog-link {
                    width: 84px;
                }
            }

            .social-icon {
                display: block;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }

            .blog-icon {
                width: 84px;
            }
        }

        &-links {
            margin: 1rem 0;
        }

        &-link {
            display: inline-block;
            padding: 0 0.5rem;
        }
    }

    @media (min-width: 52rem) {
        &__title {
            height: 39px;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            gap: 0;

            &__rating {
                flex: 0 0 18%;
                max-width: 18%;
                padding-right: 1%;
            }

            &__links-container {
                flex: 0 0 38%;
                max-width: 38%;
                padding-right: 1%;
                padding-left: 1%;
            }

            &__seo-text {
                flex: 0 0 38%;
                max-width: 38%;
                padding-left: 1%;
                overflow-wrap: break-word;
                word-wrap: break-word;
                hyphens: auto;
            }
        }

        &__bottom {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            gap: 2%;
        }

        &__bottom-social-link {
            flex-grow: 1;
            justify-content: flex-end;
        }
    }

    @media (min-width: 69rem) {
        &__top {
            &__links-container {
                flex: 0 0 38%;
                max-width: 38%;
            }

            &__seo-text {
                flex: 0 0 38%;
                max-width: 38%;
                p {
                    max-width: 100%;
                }
            }

            &__seo-links {
                &:first-child {
                    .footer__top__seo-links__list {
                        column-count: 3;
                    }
                }

                &:last-child {
                    .footer__top__seo-links__list:not(.offers-list) {
                        column-count: 2;
                    }
                }
            }
        }
    }

    @include medium-down {
        &[data-page="Vehicle/Show"] {
            padding-bottom: 12rem;
        }
    }
}
</style>
