<script setup lang="ts">
import PriceInput from "@/Components/Forms/Input/PriceInput.vue";
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";
import { reactive } from "vue";
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import Error from "@/Components/Forms/Error.vue";

const emit = defineEmits<{
    (e: "update:offer", value: string): void;
    (e: "update:duration", value: number): void;
    (e: "update:first-rent", value: number): void;
    (e: "update:mileage", value: number): void;
}>();

const props = withDefaults(
    defineProps<{
        offer: string;
        duration: number;
        firstRent?: number;
        mileage: number;
        lightMode: boolean;
        hasFirstRentError?: boolean;
        maxFirstRent: number;
        minFirstRent: number | null;
    }>(),
    {
        firstRent: undefined,
        lightMode: true,
        hasFirstRentError: false,
    },
);

const { t } = useI18n();

const changeOffer = (offer) => {
    form.offer = offer;
};

const form = reactive({
    offer: props.offer,
    duration: props.duration,
    firstRent: props.firstRent,
    mileage: props.mileage,
});

const formatSliderTooltip = (value, suffix) => {
    return `${Math.round(value)} ${suffix}`;
};

const formatDurationTooltip = (value) => {
    return formatSliderTooltip(value, " mois");
};

const formatMileageTooltip = (value) => {
    return formatSliderTooltip(value, " km");
};

watch(
    () => props.offer,
    (value) => {
        form.offer = value;
    },
);

watch(
    () => props.duration,
    (value) => {
        form.duration = value;
    },
);

watch(
    () => props.firstRent,
    (value) => {
        form.firstRent = value;
    },
);

watch(
    () => props.mileage,
    (value) => {
        form.mileage = value;
    },
);

watch(form, () => {
    emit("update:first-rent", form.firstRent || 0);
    emit("update:duration", form.duration || 12);
    emit("update:offer", form.offer);
    emit("update:mileage", form.mileage || 0);
});
</script>

<template>
    <div>
        <div class="form-group">
            <span class="label">
                {{ t("calculator.typeOfFinancing") }}
            </span>
            <div class="offers-buttons" :class="{ light: lightMode }">
                <button
                    type="button"
                    @click="changeOffer('lld')"
                    class="offers-buttons__btn"
                    :class="{ active: offer == 'lld' }"
                >
                    {{ t("calculator.lld") }}
                </button>
                <button
                    type="button"
                    @click="changeOffer('loa')"
                    class="offers-buttons__btn"
                    :class="{ active: offer == 'loa' }"
                >
                    {{ t("calculator.loa") }}
                </button>
            </div>
        </div>
        <div class="form-group increased">
            <span class="label">
                {{ t("calculator.duration") }}
            </span>
            <div class="fix-padding">
                <Slider
                    :class="{
                        'slider-simulator-dark': !lightMode,
                        'slider-simulator-light': lightMode,
                        'slider-overrides': true,
                    }"
                    :min="24"
                    :max="60"
                    :step="6"
                    v-model="form.duration"
                    :format="formatDurationTooltip"
                    show-tooltip="always"
                    tooltip-position="bottom"

                />
            </div>
        </div>
        <div class="form-group">
            <span class="label">
                {{ t("calculator.firstRent") }}
            </span>
            <PriceInput
                v-model="form.firstRent"
                :step="500"
                :min-first-rent="minFirstRent"
                :show-buttons="true"
                :classic="false"
            />
            <Error
                :error="
                    t('calculator.errors.excessiveFirstRent', {
                        amount: $filters.number(maxFirstRent),
                    })
                "
                v-if="hasFirstRentError"
            />
        </div>
        <div class="form-group increased padding">
            <span class="label">
                {{ t("calculator.annualMileage") }}
            </span>
            <div class="fix-padding">
                <Slider
                    :class="{
                        'slider-simulator-dark': !lightMode,
                        'slider-simulator-light': lightMode,
                        'slider-overrides': true,
                    }"
                    :min="3000"
                    :max="25000"
                    :step="1000"
                    v-model="form.mileage"
                    :format="formatMileageTooltip"
                    show-tooltip="always"
                    tooltip-position="bottom"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.slider-touch-area {
    position: relative;
    &::after {
        content: " ";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $dark-font;
        margin: auto;
        border-radius: 50%;
    }
}

.form-group {
    margin-bottom: 1.5rem;

    @include medium-down {
        margin-bottom: 1rem;
    }

    &.increased {
        margin-bottom: 4rem;

        @include medium-down {
            margin-bottom: 3rem;
        }
    }
}
.label {
    display: block;
    font-family: $bebas;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.825rem;
    letter-spacing: 0.96px;

    @include medium-down {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
}

.offers-buttons {
    background: rgba($color: #ffffff, $alpha: 0.1);
    border-radius: 4px;
    padding: 4px;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);

    &.light {
        background: $grey-card;
    }

    &__btn {
        background: transparent;
        border: none;
        outline: none;
        padding: 12px 8px;
        border-radius: 4px;
        font-weight: 600;

        @include medium-down {
            padding: 6px 4px;
        }

        &:hover {
            background: rgba($color: #000000, $alpha: 0.1);
            cursor: pointer;
        }

        &.active {
            background: $primary;
            color: $dark-font;
        }
    }
}

.fix-padding {
    padding: 0 1rem;
}

.slider-overrides {
    margin-top: 1.3rem !important;
}

.offers-buttons.light .offers-buttons__btn.active {
    background: $dark-bg;
    color: white;
}
</style>
