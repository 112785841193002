<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import Button from "@/Components/Buttons/Button.vue";
import SubTitle from "@/Components/Typography/SubTitle.vue";
import { Link } from "@inertiajs/vue3";

const { t } = useI18n();
</script>

<template>
    <section class="section container">
        <div class="section__main">
            <SubTitle class="section__subtitle">
                {{ t("home.requirement.subtitle[0]")
                }}<br class="mobile-hidden" />
                {{ t("home.requirement.subtitle[1]") }}
            </SubTitle>
            <p>
                <b>{{ t("home.requirement.mainText[0]") }}</b
                >{{ t("home.requirement.mainText[1]") }}
            </p>
        </div>

        <div class="section__content">
            <p>
                <b>{{ t("home.requirement.text[0]") }}</b>
                {{ t("home.requirement.text[1]") }}
            </p>
            <Button
                :as="Link"
                :href="route('vehicles.index')"
                variante="arrow"
                >{{ t("home.requirement.cta") }}</Button
            >
        </div>
    </section>
</template>

<style lang="scss" scoped>
.section {
    padding: var(--hp-y-space) 0;
    @include medium-up {
        display: grid;
        grid-template-columns: var(--grid-columns);
        grid-column: 1/2;
    }
    &__subtitle {
        @include medium-down {
            max-width: 220px;
            margin-inline: auto;
        }
    }
    &__main {
        p {
            line-height: var(--description-line-height);
        }
    }
    &__content {
        grid-column: 3/4;
        grid-row: 2;
        text-align: center;
        @include medium-up {
            text-align: left;
        }
        p {
            text-align: left;
            line-height: var(--description-line-height);
        }
        > * + * {
            margin-top: 1rem;
        }
    }
}
@include medium-down {
    .mobile-hidden {
        display: none;
    }
}
</style>
