<script setup lang="ts">
import { throttle } from "lodash-es";
import { useI18n } from "vue-i18n";
import { onMounted, onBeforeUnmount, ref } from "vue";
import TitleTabulatedMultiLine from "@/Components/Typography/TitleTabulatedMultiLine.vue";

const { t } = useI18n();
let reverseHeader = true;

const handleProgress = throttle((e) => {
    const { progress } = e.detail;
    if (progress < 0.9 && reverseHeader) {
        const header = document.getElementById("app-header");
        if (header) {
            header.classList.add("header--white");
            reverseHeader = false;
        }
    } else if (progress > 0.9 && !reverseHeader) {
        const header = document.getElementById("app-header");
        if (header) {
            reverseHeader = true;
            header.classList.remove("header--white");
        }
    }
});

const isInview = ref<boolean>(false);

onMounted(() => {
    window.addEventListener("progressHeroEvent", handleProgress);
});

onBeforeUnmount(() => {
    window.removeEventListener("progressHeroEvent", handleProgress);
});
const handleHeroLoad = () => {
    setTimeout(() => {
        isInview.value = true;
    }, 1000);
};

const scrollToNextSection = () => {
    const nextSection = document.getElementById("content-home");

    if (nextSection) {
        window.scrollTo({
            top: nextSection.offsetTop,
            behavior: "smooth",
        });
    }
};
</script>
<template>
    <section
        class="hero"
        data-scroll
        data-scroll-event-progress="progressHeroEvent"
    >
        <div class="hero__background">
            <picture>
                <source
                    media="(max-width: 640px)"
                    srcset="
                        ../../../../../resources/images/home/hero-mobile.webp
                    "
                />
                <img
                    src="../../../../../resources/images/home/hero-desktop.webp"
                    alt="Porsche 992"
                    fetchpriority="high"
                    @load="handleHeroLoad"
                />
            </picture>
        </div>
        <div class="hero__content">
            <div class="hero__content__title">
                <TitleTabulatedMultiLine
                    :is-animated="true"
                    :is-inview="isInview"
                    :level="1"
                    :labels="[
                        {
                            isInterpolated: false,
                            content: t('home.hero.title[0]'),
                            gap: '0',
                        },
                        {
                            isInterpolated: false,
                            content: t('home.hero.title[1]'),
                            gap: '0',
                        },
                        {
                            isInterpolated: false,
                            content: t('home.hero.title[2]'),
                            gap: '0',
                        },
                    ]"
                    align-text="center"
                />
            </div>

            <img
                src="../../../../images/icons/scroll.svg"
                alt="scroll"
                class="hero__content__scroll"
                @click="scrollToNextSection"
            />
        </div>
    </section>
</template>
<style lang="scss" scoped>
.hero {
    color: $primary;
    margin-top: -74px;
    position: relative;
    width: 100%;
    height: 100dvh;

    &__background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media (max-width: 640px) {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(black, 0.2);
            }
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        height: calc(100% - 2rem);
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @include medium-down {
            margin-bottom: 70px;
        }

        > * {
            margin: 0;
        }

        &__title {
            flex-grow: 1;
            display: flex;
            align-items: center;
            margin-top: 5%;
        }

        &__scroll {
            height: 1.5rem;
        }
    }
}
</style>
