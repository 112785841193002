<script setup lang="ts"></script>

<template>
    <div class="container page-content">
        <h1 class="page-content__title">
            CONDITIONS GÉNÉRALES “VÉHICULES NEUF ET D’OCCASION”
        </h1>
        <h2 class="page-content__subtitle">0. Champ d’application</h2>
        <p>
            Les présentes conditions générales de vente sont applicables à la
            vente de nouveaux véhicules et de véhicules d’occasion par le
            vendeur à l’acheteur aux conditions particulières se référer au bon
            de commande.
        </p>
        <p>0.1 Généralités</p>
        <p>
            soit un contrat de vente, si l’Acheteur déclare qu’il se porte
            acquéreur du véhicule,
        </p>
        <p>
            soit un contrat de mandat de financement dans ce cas l’acheteur
            mandate BACT pour lui trouver un financement conforme à la
            description des conditions particulières - 1er loyer - mensualité -
            assurance facultative sous forme de loa ou lld tous frais
            d'intermédiation inclus,
        </p>
        <p>
            soit un contrat de mandat qui autorise le Vendeur à effectuer les
            démarches préalables à la livraison du véhicule demandé, si
            l’Acheteur choisit la location avec option d’achat ou le
            crédit-bail.
        </p>
        <h2 class="page-content__subtitle">1. Date de livraison</h2>
        <p>
            1.1. La livraison a lieu dans les locaux de l’établissement vendeur.
            Le vendeur s’engage à livrer le véhicule et l’acheteur à prendre
            livraison de celui-ci à la date convenue et au plus tard dans les
            trois jours qui suivent cette date. Le délai convenu sera en cas
            d’événement constituant un cas de force majeure, prolongé, au
            bénéfice du client comme du vendeur, d’une période égale à cet
            événement sans qu’aucune compensation ne puisse être exigée par
            l’acheteur.
        </p>
        <p>
            Si la commande le prévoit, et en accord avec le client, la livraison
            du véhicule peut intervenir sur un autre site défini en entre les
            deux parties.
        </p>
        <p>
            Si l’acheteur ayant commandé son véhicule à crédit ou en L.O.A. dans
            les conditions prévues par les dispositions légales et
            réglementaires souhaite être livré immédiatement, le délai de
            rétractation passera de quatorze à trois jours.
        </p>
        <p>
            Dans le cas d’une commande avec&nbsp; livraison immédiate, le délai
            de livraison effectif accordé ne pourra excéder soixante jours à
            compter de la date de signature du bon de commande.
        </p>
        <p>
            1.2. Lorsque l’acheteur ne prend pas possession véhicule à la date
            de livraison convenue, le vendeur a le droit, après un délai de 10
            jours calendriers à dater de l’envoi d’un courrier recommandé de
            mise en demeure, sauf si l’acheteur prouve que le défaut de
            réception du véhicule est consécutif à un cas de force majeure :
            <br />
            - de réclamer des frais de garage s’élevant à 25,00 euros/jour ou
            <br />
            - de résilier la vente ainsi que d’exiger une indemnité
            correspondant au préjudice subi, les parties convenant que ce
            préjudice est égal à 15% du prix de vente intégral du véhicule, sans
            préjudice du droit pour le vendeur de prouver que le préjudice
            réellement subi est plus important.
        </p>
        <h2 class="page-content__subtitle">2. Prix</h2>
        <p>
            2.1. Le prix stipulé dans le contrat de vente, toutes taxes et tous
            services à payer obligatoirement par le client compris, n’est pas
            révisable, sans préjudice de l'application de l'article 18 des
            présentes conditions générales de vente.
        </p>
        <p>
            2.2. Le prix des accessoires légalement obligatoires fixés de façon
            permanente&nbsp; est réputé inclus dans le prix annoncé.
        </p>
        <h2 class="page-content__subtitle">3. Livraison</h2>
        <p>
            3.1. La livraison d’un véhicule se fait à l’endroit repris sur le
            contrat de vente et, en principe, au siège du vendeur.
        </p>
        <p>
            3.2. L’acheteur assume tous les risques relatifs au véhicule dès que
            ce dernier ou la personne qu'il a désignée et qui n'est pas le
            transporteur, prend physiquement possession du véhicule. Lorsque le
            contrat de vente prévoit l'expédition du véhicule, le risque de
            perte ou d'endommagement est transféré à l’acheteur au moment de la
            livraison au transporteur qui a été chargé par l’acheteur du
            transport et pour autant que le choix de ce transporteur n'ait pas
            été offert par le vendeur.
        </p>
        <p>
            3.3. Si la production du véhicule neuf commandé vient à cesser, la
            vente sera résiliée de plein droit.
        </p>
        <h2 class="page-content__subtitle">4. Paiement</h2>
        <p>
            4.1. Sauf accord de l’acheteur et sans préjudice de l’application
            des règles relatives au crédit à la consommation telles qu’établies
            dans le code du consommation, le vendeur ne peut exiger le paiement
            d’un acompte supérieur à 35% du prix intégral de vente du véhicule.
            Le cas échéant il est prévu dans le contrat de vente que celle-ci
            est conclue sous la condition suspensive du paiement de l’acompte
            convenu.-En cas de financement du véhicule et de non réception ou
            non signature du contrat de financement, l’acheteur se verra
            réclamer la somme égale à 20 fois le montant du loyer mensuel
            stipulé dans le bon de commande dans la limite de trente cinq milles
            euros.
        </p>
        <p>
            4.2. Le paiement complet ou celui du solde en cas de paiement d’un
            acompte, se fait au comptant au moment de la livraison, sauf
            convention expresse et écrite contraire. A défaut, des intérêts au
            taux légal sont dus de plein droit et sans mise en demeure sur le
            solde restant dû. Sans préjudice de l’application de l’article 3.2.,
            le véhicule reste la propriété du vendeur jusqu’au paiement intégral
            du prix. <br />
            En outre, si le paiement n’a pas été effectué dans les 10 jours
            calendriers suivant l’envoi à l’acheteur d’une mise en demeure, par
            recommandé, de payer intégralement les sommes dues, le vendeur peut
            résilier la vente par courrier recommandé adressé à l’acheteur. Dans
            ce cas, et sans préjudice des intérêts mentionnés ci-dessus,
            l’acheteur sera redevable envers le vendeur d’une indemnité
            correspondant au préjudice subi, les parties convenant que ce
            préjudice est égal à 15% du prix intégral de vente du véhicule, sans
            préjudice du droit pour le vendeur de prouver que le préjudice
            réellement subi est plus important.
        </p>

        <p>
            4.3. La remise d’un chèque ne vaut pas paiement, celui-ci n’étant
            accepté que sous réserve d’encaissement.
        </p>
        <h2 class="page-content__subtitle">5. Processus de production</h2>
        <p>
            L’acheteur reconnaît être informé et accepter que la production, les
            techniques, la technologie et le design sont sujets à évolutions
            dans le secteur automobile, impliquant que le véhicule livré peut
            légèrement différer par certains détails au modèle commandé. Ces
            modifications ne peuvent cependant pas affecter les caractéristiques
            spécifiques et/ou l’usage particulier recherché par l’acheteur tel
            que mentionné dans le contrat de vente.
        </p>
        <h2 class="page-content__subtitle">6. Garantie</h2>
        <p>
            6.1 Garantie légale : Conformément aux articles s du Code civil, le
            vendeur répond vis-à-vis de l’acheteur de tout défaut de conformité
            qui existe lors de la livraison et qui apparaît dans un délai de
            deux ans (pour véhicules neufs) ou d’un an (pour véhicules
            d’occasion) à compter de cette livraison. <br />
            Après l’échéance de la garantie telle qu’elle est mentionnée à
            l’alinéa précédent, l’acheteur bénéficie de la garantie légale
            contre les vices cachés telle que reprise aux articles du Code
            civil, si le vice caché existait au moment de la livraison et pour
            autant que le vice caché rende le véhicule impropre à l’usage auquel
            il est destiné ou en diminue sensiblement l’usage.
        </p>
        <p>
            Tout défaut de conformité ou tout vice caché doit être notifié au
            vendeur par courrier recommandé dans un délai de 2 mois à partir du
            moment où l’acheteur l’a constaté ou aurait normalement dû le
            constater. <br />
            Le client conserve le bénéfice de la garantie légale s’il fait
            entretenir ou réparer le véhicule automoteur hors du réseau de
            réparateurs agréés par le constructeur automobile, selon les
            instructions du constructeur automobile.
        </p>
        <h2 class="page-content__subtitle">
            7. Financement et faculté de renoncer au contrat
        </h2>
        <p>
            7.1. Le cas échéant, le financement s’effectue conformément aux
            règles relatives au crédit à la consommation.
        </p>
        <p>
            En cas de financement par le vendeur ou par l’intermédiaire du
            vendeur, mention en sera faite dans le contrat de vente. S’il est
            prévu dans le contrat de vente que la vente a été conclue sous
            condition suspensive de l’octroi d’un financement, sans intervention
            du vendeur dans la conclusion de ce contrat et si ce financement est
            refusé par l’établissement de crédit, l’acheteur devra en aviser le
            vendeur sans délai. De plus, la preuve écrite de ce refus de
            financement devra être rapportée par lettre adressée au vendeur dans
            le mois de la signature du bon de commande. L’acompte éventuellement
            versé sera, dans ce cas, remboursé sans retard excessif à
            l’acheteur.
        </p>
        <p>
            Si l’acheteur ne respecte pas cette disposition, il sera redevable,
            à l’égard du vendeur, d’une indemnité qui correspond au préjudice
            subi, les parties convenant que ce préjudice est égal à 15% du prix
            intégral de vente du véhicule, sans préjudice du droit pour le
            vendeur de prouver que le préjudice réellement subi est plus
            important.
        </p>
        <p>
            Frais d'intermédiation sont inclus au prix de vente stipulé dans le
            contrat de financement il peuvent s’élever à vingt pour cent du prix
            de vente de la voiture.&nbsp;
        </p>
        <p>
            En cas de non signature du contrat de financement suite à la
            signature du bon de commande sous condition suspensive, l’acheteur
            se verra réclamer la somme égale à 20 fois le montant du loyer
            mensuel stipulé dans le bon de commande dans la limite de trente
            cinq&nbsp; milles euros.
        </p>
        <h2 class="page-content__subtitle">
            8. Reprise d’un véhicule d’occasion
        </h2>
        <p>
            Lorsque le bon de commande stipule la reprise d’un véhicule, cette
            reprise est subordonnée à la livraison et au paiement par l’acheteur
            au vendeur d’un véhicule (neuf ou d’occasion) et à la preuve (i) que
            l’acheteur est propriétaire du véhicule repris et (ii) que toutes
            les obligations afférentes au financement éventuel du véhicule
            repris ont été exécutées.
        </p>
        <p>
            Le prix de reprise du véhicule à reprendre, convenu dans le contrat
            de vente du véhicule à acheter, est définitif à moins que l’état du
            véhicule repris, au moment de sa livraison par l’acheteur, ne
            corresponde plus aux exigences spécifiques qui ont été convenues
            dans le contrat de vente. La diminution de la valeur du véhicule
            repris, suite à un retard de livraison du véhicule vendu à
            l’acheteur, est à charge du vendeur.
        </p>
        <h2 class="page-content__subtitle">9. Documents du constructeur</h2>
        <p>
            Tout document émanant du constructeur qui mentionne les
            caractéristiques techniques du véhicule commandé, portant le cachet
            ou la signature du vendeur et joint au contrat de vente, est réputé
            faire partie du contrat de vente auquel il est joint.
        </p>
        <h2 class="page-content__subtitle">10. Force majeure</h2>
        <p>
            La partie qui invoque un cas de force majeure en informe l’autre par
            courrier recommandé dans les 8 jours calendriers qui suivent le jour
            où elle a eu connaissance de l’événement.
        </p>
        <h2 class="page-content__subtitle">11. Preuve</h2>
        <p>
            Dans les dispositions susmentionnées, la forme recommandée de
            l’écrit n’est prévue qu’à titre probatoire.
        </p>
        <h2 class="page-content__subtitle">
            12. Droit applicable et tribunal compétent
        </h2>
        <p>
            La relation contractuelle entre l’acheteur et le vendeur est soumise
            au droit français. En cas de litige, la juridiction compétente est
            celle du siège ou d’un des établissements&nbsp; du vendeur.
        </p>
        <h2 class="page-content__subtitle">13. Données personnelles</h2>
        <p>
            Pour plus d’informations, nous vous invitons à consulter notre
            politique de confidentialité sur notre site internet.
        </p>
        <h2 class="page-content__subtitle">
            14. Qualité et engagement de l’acheteur
        </h2>
        <p>
            14.1. Les présentes conditions générales sont applicables dans leur
            intégralité pour autant que l’acheteur soit un consommateur au sens
            du Code de droit économique c’est-à-dire pour autant que l’acheteur,
            personne physique, agisse à des fins qui n’entrent pas dans le cadre
            de son activité professionnelle ou commerciale.
        </p>
        <p>
            14.2. Dans les cas où l’acheteur n’est pas un consommateur au sens
            de l’article 15.1. ci-dessus, les présentes conditions générales
            sont également d’application à l’exception des articles suivants:
            1.1, 2.1, 2.2, 4.1, 6.1, 7.1, 9, 12 et 22.
        </p>
        <p>
            Dans ce cas : <br />
            - la date ou le délai de livraison est toujours donné à titre
            purement indicatif et sans engagement ferme de la part du vendeur ;
            <br />
            - les prix indiqués sont susceptibles de majoration si les prix
            catalogues conseillés par l’importateur ou le constructeur
            augmentent; l’article 6.1 est supprimé et remplacé par la
            disposition suivante : <br />
            “6.1. Garantie légale” <br />

            L’acheteur bénéficie de la garantie légale contre les vices cachés
            telle qu’elle est précisée aux articles du Code civil pour autant
            que le vice caché ait existé au moment de la livraison et rende le
            véhicule impropre à l’usage auquel il est destiné ou en diminue
            sensiblement l’usage. Tout vice caché doit être notifié au vendeur
            par courrier recommandé dans un délai de 2 mois à partir du moment
            où l’acheteur l’a constaté ou aurait normalement dû le constater »
            <br />

            Un article 6.3 est ajouté et est libellé comme suit : « Les défauts
            apparents à la peinture, à la carrosserie et aux garnitures
            intérieures doivent être signalés sans délai par courrier recommandé
            au vendeur. Les autres vices apparents doivent être notifiés par
            courrier recommandé au vendeur au plus tard dans les 10 jours
            calendriers à partir de la livraison »
            <br />

            - seuls les tribunaux du siège social du vendeur sont compétents ;
            <br />
            - le vendeur conserve néanmoins le droit de porter tout litige
            devant l’une des juridictions de son choix telles que reprises à
            l’article 12.
        </p>
        <p>
            14.3. L’acheteur s’engage expressément à acquérir le véhicule
            commandé pour son usage personnel ou à des fins de mise en location
            ou de leasing et non pour le revendre à l’état neuf à des fins
            commerciales (avec un but de lucre, et ceci particulièrement dans le
            cas où des conditions préférentielles lui auraient été accordées).
        </p>
        <p>
            Si l’acheteur ne respecte pas le présent engagement, le vendeur se
            réserve le droit :<br />
            -soit de considérer la vente comme annulée sans que le vendeur ne
            soit tenu au paiement d’une quelconque indemnité ;<br />
            -soit de réclamer à l’acheteur une indemnité égale à 10% du prix de
            vente du véhicule; <br />

            -soit de suspendre l’exécution de toute autre commande en cours tant
            que l’acheteur n’a pas confirmé son engagement de respecter
            l’obligation susvisée pour ce qui concerne les autres véhicules
            commandés.
        </p>
        <h2 class="page-content__subtitle">15. Cession&nbsp;</h2>
        <p>
            La commande qui figure sur le contrat de vente ne pourra être cédée
            ni à titre onéreux ni à titre gratuit par l’acheteur sans l’accord
            écrit du vendeur.
        </p>
        <h2 class="page-content__subtitle">16. Commandes</h2>
        <p>
            La commande est définitive dès signature du contrat de vente, sous
            réserve des dispositions qui précèdent. Chaque acompte versé est
            exempt d’intérêts.
        </p>
        <p>
            La vente est réputée conclue à l’endroit repris sur le contrat de
            vente, à savoir en principe au siège social du vendeur, où les
            paiements doivent être effectués.
        </p>
        <h2 class="page-content__subtitle">17. Commande complémentaire</h2>
        <p>
            Sous réserve d’un accord écrit conclu entre l’acheteur et le vendeur
            et à condition que le processus de production le permette encore,
            l’acheteur peut encore commander des options complémentaires à
            intégrer au véhicule commandé après conclusion du contrat de vente.
        </p>
        <h2 class="page-content__subtitle">18. Certificat d’immatriculation</h2>
        <p>
            Le transfert de propriété n’interviendra au solde complet du
            véhicule selon les conditions particulières du contrat - Offre de
            LOA ou&nbsp; Offre d’achat -
        </p>
        <h2 class="page-content__subtitle">
            19. Cas particulier à la location avec option d’achat
        </h2>
        <p>
            19.1 Définitions <br />
            - "Locataire": désigne indifféremment l'entreprise ou la personne
            physique bénéficiaire du présent Contrat. Lorsqu'il s'agira d'une
            entreprise, le Locataire désigne le Conducteur habilité du Véhicule
            <br />

            - "Véhicule": désigne une voiture particulière haut de gamme telle
            que désignée en Annexe 1 et louée pour la durée du Contrat. <br />

            - «Conducteur » désigne le Locataire personne physique ou morale.<br />
            - "Territoire": désigne la zone géographique<br />
        </p>
        <p>19.2 Responsabilite - Assurance</p>
        <p>
            19.2.1 Le Locataire s'engage à faire preuve de la plus grande
            diligence et du plus grand soin dans l'utilisation du Véhicule, dans
            sa garde et son entretien.
        </p>
        <p>
            19.2.2 Le Locataire est exclusivement et personnellement responsable
            de toutes les conséquences résultant des infractions au Code de la
            Route. A ce titre, il lui appartient d'assumer toutes les amendes et
            autres frais et accessoires qui seraient réclamés au Loueur, du fait
            du Véhicule, et, le cas échéant, à lui rembourser toutes sommes qui
            auraient été avancées par les soins de ce dernier pour le compte du
            Locataire. <br />
            Chaque amende engendrant un traitement administratif le Locataire se
            verra facturé des frais par amende constatée (cf article 19.3)
        </p>
        <p>
            19.2.3 Le Locataire devra informer le Loueur sans délai et par écrit
            de toute mesure dont il ferait l'objet et tendant au retrait ou à la
            suspension de son permis de conduire. Ainsi, il devra aviser le
            Loueur, sans délai, de toute perte de points.
        </p>
        <p>
            19.2.4 Le Locataire souscrit sa propre assurance <br />
            Aucun véhicule ne pourra être livré sans qu’une copie de la police
            d’assurance ne soit transmise au Loueur. <br />
            Le Locataire s’engage à souscrire ou faire souscrire, pour toute la
            durée de la location, auprès d’une compagnie notoirement solvable,
            une police d’assurance pour le véhicule loué, couvrant les risques
            suivants : <br />
            -Responsabilité civile (en et hors circulation) pour les dommages
            causés au tiers, la famille du Locataire et du conducteur
            conformément à l’article L211-1 du Code des assurances. <br />
            -Dommages au véhicule loué, à la suite de tout accident, incendie,
            vol, bris de glace, à l’occurrence de la valeur de remplacement
            <br />
            -Défense et recours, insolvabilité d’un tiers <br />
            -Perte financière, avec clause expresse de délégation des indemnités
            au profit du Loueur
        </p>
        <p>
            Le Locataire devra s’assurer que sont notifiés à la compagnie
            d’assurance, les droits du Loueur et le fondement de la propriété
            juridique de celui-ci sur le véhicule.
        </p>
        <p>
            Au moment du transfert au Locataire de la garde du véhicule, le
            Locataire remettra au Loueur une attestation délivrée par son
            assureur et dès la réception de la police définitive, il en fera
            parvenir un exemplaire au loueur.
        </p>
        <p>
            La compagnie d’assurance devra s’engager à ne pas suspendre ni
            résilier la garantie du véhicule loué sans en avertir préalablement
            le Loueur par lettre recommandée avec avis de réception.
        </p>
        <p>
            Le Locataire est responsable de son assurance civile professionnelle
            qu’il est libre de souscrire auprès de l’assureur de son choix. Le
            Locataire doit pouvoir en justifier lors de tout contrôle.
        </p>
        <p>19.2.5 Le Locataire mandate Bact pour l’assurer</p>
        <p>
            Le Locataire donne par les présentes mandat au Loueur, ce que ce
            dernier accepte, conformément aux dispositions des articles 1984 et
            suivants du code civil, pour faire souscrire, en son nom et pour son
            compte, pour toute la durée de la location, auprès d’une compagnie
            notoirement solvable, une police d’assurance pour le véhicule loué,
            couvrant les risques suivants :
        </p>
        <ul class="page-content__list">
            <li>
                Responsabilité civile (en et hors circulation) pour les dommages
                causés au tiers, la famille du Locataire et du conducteur
                conformément à l’article L211-1 du Code des assurances.
            </li>
            <li>
                Dommages au véhicule loué, à la suite de tout accident,
                incendie, vol, bris de glace, à l’occurrence de la valeur de
                remplacement
            </li>
            <li>Défense et recours, insolvabilité d’un tiers</li>

            <li>
                Perte financière, avec clause expresse de délégation des
                indemnités au profit du Loueur
            </li>
        </ul>
        <p>
            Dans le cadre de ce mandat, les frais d’assurance seront refacturés
            par le Loueur au locataire à l’euro l’euro.<br />
            Seront notifiés à la compagnie d’assurance dans le cadre de ladite
            police les droits du Loueur et le fondement de la propriété
            juridique de celui-ci sur le véhicule. La compagnie d’assurance
            devra s’engager à ne pas suspendre ni résilier la garantie du
            véhicule loué sans en avertir préalablement le Loueur par lettre
            recommandée avec avis de réception.<br />
            Le Loueur remettra au Locataire la documentation justifiant de la
            souscription de ladite police d’assurance, le Locataire devant
            pouvoir en justifier lors de tout contrôle.
        </p>
        <p>19.3. Frais additionnels&nbsp;</p>
        <p>
            Frais de traitement d’une facture impayée : 48€ HT - Frais de
            blocage du véhicule : 160€ HT- Frais de retard de présentation à une
            échéance fixée par les conditions générales ou particulières : 100€
            HT par jour de retard -Frais de gestion de contravention : 34€ HT
            par contravention- Frais de gestion de sinistre responsable : 200€
            HT -Frais de gestion de sinistre non responsable : 200€ HT - Frais
            de Clef perdu :&nbsp; frais de concessionnaire + 150€ HT de frais de
            traitement - Coût du kilomètre supplémentaire au delà du forfait
            autorisé dans le bon de commande : 0,69€ HT/km.
        </p>
        <p>19.4 Cessation - Resiliation - Suspension</p>
        <p>
            19.4.1 La durée du Contrat, telle que défini dans l’offre de LOA
            correspondant aux conditions particulières est ferme et chaque
            Partie pourra y mettre un terme à son échéance.
        </p>
        <p>
            19.4.2 Le Loueur pourra, de plein droit, mettre fin de manière
            anticipée au Contrat, sans préavis ni indemnité au profit du
            Locataire, en cas de manquement grave au Contrat et notamment :
        </p>

        <ul>
            <li>abandon du Véhicule,</li>
            <li>non-respect du Territoire,</li>
            <li>
                violation caractérisée et/ ou répétée de l'obligation de
                conduire en "bon père de famille",
            </li>
            <li>
                violation persistante de l'obligation d'entretien du Véhicule, à
                l'issue d'un délai de 7 jours suivant une lettre recommandée
                avec accusé de réception de mise en demeure de se conformer à
                cette obligation par le Loueur,
            </li>
            <li>
                responsabilité du Locataire, engagée dans deux accidents
                impliquant le Véhicule,
            </li>
            <li>
                retrait du permis de conduire et/ou suspension pour une durée
                supérieure à deux (2) mois,
            </li>
            <li>
                - non règlement d'un Loyer ou d'une somme quelconque due par le
                Locataire dans le cadre de l'exécution du présent Contrat, à
                l'issue d'une période de 7 jours suivant la réception d'une
                lettre de mise en demeure de payer adressée par le Loueur.
            </li>
        </ul>
        <p>
            19.4.3 En cas de résiliation, pour quelque motif que ce soit, le
            Locataire devra restituer le Véhicule, dans le délai de 24 heures
            suivant la prise d'effet de la résiliation. A défaut, des pénalités
            de paiement seront appliquées à hauteur de cent (100) euros par jour
            jusqu'à la date de parfaite restitution.
        </p>
        <p>
            19.4.4 En cas de résiliation du contrat pour quelque motif que ce
            soit, le Locataire sera redevable de l’ensemble des mensualités
            restantes du au contrat.
        </p>
        <p>
            19.5 Étant donné qu'il est difficile d'anticiper ses envies, BACT
            s'engage à racheter votre véhicule auprès du bailleur en fin de
            contrat au montant de l'Option d'Achat/Engagement de Reprise (*).
            <br />
            Pour cela, il suffit de nous faire part de votre décision 3 mois
            avant le terme de contrat par voie postale à l’adresse du siège
            social par LAR. <br />
            Vous devrez alors restituer votre véhicule au siège de la société
            BACT SAS.<br />
            Au-delà de cette échéance, l'engagement de reprise/rachat de BACT
            deviendra caduc. Le prix de rachat indiqué s'entend: -hors éventuel
            frais de remise en état (un état des lieux du véhicule sera effectué
            le jour de la livraison et consigné sur un procès verbal réalisé via
            la solution "Weproov") -hors éventuelle facturation du kilométrage
            supplémentaire-
        </p>
        <p>
            Montant maximum d'engagement de reprise sous conditions de bonne
            exécution du contrat de Location avec Option d'Achat en date de
            reprise vis-à-vis des obligations envers le bailleur.<br />
            En cas d’exercice de l’option d’achat du véhicule par le locataire,
            la totalité des avoirs perçus par le locataire sera due à la société
            BACT. <br />
            BACT se réserve le droit de ne pas procéder au rachat si les
            conditions de bonne exécution ne sont pas réunies.
        </p>
        <p>
            Condition d'exécution d’engagement de reprise. Le montant maximum
            d'engagement de reprise sous conditions de bonne exécution du
            contrat de Location avec Option d'Achat en date de reprise vis-à-vis
            des obligations envers le bailleur. BACT se réserve le droit de ne
            pas procéder au rachat si les conditions de bonne exécution ne sont
            pas réunies.&nbsp;
        </p>
        <p>
            Les frais de remise en état&nbsp; sont à la charge du locataire;
            estimés sur devis au moment (max 14 jours) de la restitution du
            véhicule en cas de remise en état du véhicule. Le solde du contrat
            n'interviendra qu’une fois les travaux réalisés. Pendant la période
            des travaux, le locataire se verra rembourser sa mensualité.<br />
            19.5.1 Non exécution de l'engagement de reprise, le vendeur se
            réserve le droit de ne pas exercer l’engagement de reprise dans les
            cas suivants. Défaut de paiement sur l’une des échéances du contrat
            d’entretien; Frais de remise en état supérieur à 10 000€;
            dépassement du forfait kilométrique total de plus de 25%.
        </p>
        <h1 class="page-content__title">
            CONDITIONS GÉNÉRALES DE “PRESTATION RÉVISIONS ET PIÈCES D’USURE”
        </h1>
        <h2 class="page-content__subtitle">1. Définitions</h2>
        <p>
            Bénéficiaire : signataire dont le nom est porté sur le bulletin
            d'adhésion au contrat de prestation de services "Révisions et Pièces
            d'usure ".
        </p>
        <h2 class="page-content__subtitle">
            2. Objet De La Prestation D'entretien
        </h2>
        <p>
            Le contrat de prestation de services "Révisions et pièces d'usure"
            prend en charge :
        </p>
        <ul class="page-content__list">
            <li>
                Les opérations d'entretien périodiques du Véhicule, aux
                kilométrages préconisés par le constructeur dans le cadre d'un
                usage normal, telles que définies ou mentionnées dans la notice
                technique remise lors de l'achat de ce Véhicule. Les opérations
                d'entretien sont prévues par avance suivant les règles fixées
                par les constructeurs et basées sur les prix des forfaits
                d'entretien périodique de chaque constructeur. Une tolérance de
                3000 km ou 3 mois pour les entretiens périodiques par rapport
                aux préconisations du constructeur est permise pendant la
                validité du présent contrat. Au-delà de cette tolérance aucune
                prise en charge d'entretien ne sera acceptée.
            </li>
            <li>
                Le remplacement des pièces d'usure sous la condition qu'elles ne
                puissent plus être en mesure de remplir la fonction pour
                laquelle elles ont été conçues selon le plan de recommandations
                du constructeur.
            </li>
            <li>
                Toute usure prématurée des dites pièces d’usure exclura
                automatiquement&nbsp; la prise en charge du changement de ces
                éléments. <br />
                Sont considérées comme pièces d'usure : plaquettes de frein,
                disques de frein, balais d'essuie-glace avant et arrière,
                amortisseurs avant et arrière, lampes ampoules (à l’exclusion
                des ampoules de type LED et Xénon), bougies, recharge de
                climatisation, kit distribution et batterie à l'exclusion des
                batteries des véhicules électriques ou hybrides. Cette liste est
                limitative : aucune autre pièce ne pourra être prise en charge
                dans le cadre du présent contrat.
            </li>
            <li>
                Les contrôles techniques qui sont fixés par la législation en
                vigueur et devront être effectués dans les 30 jours précédant la
                date limite de l'obligation légale mentionnée sur le certificat
                d'immatriculation du véhicule (carte grise).
            </li>
        </ul>

        <h2 class="page-content__subtitle">
            3. Véhicules Pouvant Bénéficier De La Prestation Entretien
        </h2>
        <p>Le véhicule concerné doit être un véhicule terrestre à moteur:</p>
        <ul class="page-content__list">
            <li>à 4 roues et de moins de 3.5 tonnes de PTAC,</li>
            <li>immatriculé en France métropolitaine,</li>
            <li>
                son kilométrage sera inférieur à 90 000 km à la date de
                souscription au contrat,
            </li>
            <li>
                avec une date de première mise en circulation à la date de
                souscription du contrat antérieure de 5 ans au plus,
            </li>
            <li>son prix d'achat sera inférieur à 200 000 euros T.T.C.,</li>
            <li>
                les véhicules à carburation GPL montée de série sont exclus,
            </li>
            <li>
                les véhicules « dérivés VP» c’est-à-dire les véhicules
                particuliers (VP) transformés en véhicules utilitaires avec
                modification du type de véhicule sur la carte grise (VUL), sont
                éligibles.
            </li>
        </ul>
        <h2 class="page-content__subtitle">
            4. Véhicules Ne Pouvant Pas Bénéficier De La Prestation Entretien
        </h2>
        <p>
            Sont exclus les véhicules ne respectant pas les conditions ci-dessus
            ainsi que les véhicules immatriculés à moins de 100 exemplaires par
            an ou les véhicules des marques suivantes : Aston Martin, Cadillac,
            Corvette, Chevrolet (modèles importés des USA), Ferrari, Hummer,
            Lada, Morgan, RoIls Royce. Sont exclus également les véhicules
            électriques, sans permis et ceux modifiés ou utilisés à des fins
            sportives et de compétition, les véhicules ayant subi une quelconque
            modification par rapport aux normes et spécifications standard du
            constructeur postérieurement à sa première mise en circulation.
        </p>
        <p>
            Il est fait exclusion des véhicules affectés à la location courte
            durée, taxis, ambulances, des véhicules sanitaires légers (VSL),
            auto-écoles, des véhicules affectés à des transports rémunérés de
            marchandises ou de personnes.
        </p>
        <h2 class="page-content__subtitle">
            5. Etendue Territoriale De La Prestation D'entretien
        </h2>
        <p>Le contrat s'applique en France métropolitaine uniquement.</p>
        <h2 class="page-content__subtitle">6. Obligations Du Bénéficiaire</h2>
        <p>
            Le bénéficiaire s'engage à utiliser le véhicule raisonnablement et à
            faire assurer par le conducteur les opérations d'entretien dans le
            réseau établi par BACT SAS selon les indications du « manuel
            d'utilisation » édité par le constructeur, notamment de faire
            conduire à ses frais les véhicules aux lieux de réparation ou
            d'entretien.
        </p>
        <h2 class="page-content__subtitle">7. Cotisations</h2>
        <p>
            Les cotisations sont prélevées hors des échéances du financement et
            directement par la société BACT SAS. Pour le montant des cotisations
            se référer au bon de commande signé par le bénéficiaire.
        </p>
        <p>
            Elles sont fixes pour toute la durée du contrat du financement.
            Elles sont toutefois susceptibles d'être modifiées en cas de
            variation du taux de taxe applicable au contrat de prestation de
            services, par l'Administration fiscale et/ou les pouvoirs publics.
        </p>
        <h2 class="page-content__subtitle">
            8. Prise D'effet De La Prestation D'entretien
        </h2>
        <p>
            Le contrat de prestation de services "Révisions et Pièces d'usure"
            prend effet, à compter de la date de mise à disposition du véhicule
            au titulaire du contrat de financement, à l'issue d'une période de
            carence de 6 mois calendaires ou dès lors que le véhicule aura
            parcouru 10 000 Kilomètres (au premier des deux termes atteints) à
            condition que le bulletin d’adhésion au présent contrat et le procès
            verbal de livraison aient été également signés par le titulaire du
            contrat de financement. Le contrat de prestation de services
            “Révisions et Pièces d'usure” reste acquis jusqu'au terme du contrat
            de financement du véhicule.
        </p>
        <p>
            Le contrat de prestation de services "Révisions et Pièces d'usure"
            prend fin au terme du contrat de financement qui ne pourra excéder
            84 mois.
        </p>
        <p>Le présent contrat n'est pas cessible.</p>
        <h2 class="page-content__subtitle">9. Demande De Prise En Charge</h2>
        <p>
            Aucune intervention effectuée sans l'accord exprès préalable de BACT
            SAS ne sera prise en charge.
        </p>
        <h2 class="page-content__subtitle">10. Exclusions</h2>
        <p>Le présent contrat ne prend pas en charge :</p>
        <ul class="page-content__list">
            <li>
                les opérations de Maintenance non effectuées par un réparateur
                agréé par BACT SAS pour le véhicule objet du contrat de
                financement situé en France Métropolitaine.
            </li>
            <li>
                les carburants, lavages, nettoyages, crevaisons, parking et
                additifs.
            </li>
            <li>le filtre à particules.</li>
            <li>le système d’échappement.</li>
            <li>l’embrayage.</li>
            <li>
                Plaquettes et disques de freins céramique et céramique carbone.
            </li>
            <li>le liquide de régénération du filtre à particules.</li>
            <li>
                le nettoyage par régénération au-delà d’une prise en charge
                accordée pendant la vie du présent contrat.
            </li>
            <li>
                les réparations, rééquilibrage ou fourniture de pneumatiques.
            </li>
            <li>
                les opérations d’entretien non préconisées par le constructeur.
            </li>
            <li>
                les contre-visites éventuelles suite au contrôle technique ne
                seront pas prises en charge.
            </li>
            <li>
                les conséquences de l'excès de froid ou de chaleur, l'immersion
                ou l'immobilisation prolongée du véhicule.
            </li>
            <li>
                les conséquences d’une cause externe, d’un événement climatique
                naturel, d’un cas de force majeure, des faits constitutifs de
                vandalisme et dégradations volontaires.
            </li>
            <li>
                les conséquences d'un problème lié à la mauvaise qualité du
                carburant ou à une erreur de carburant.
            </li>
            <li>
                les conséquences d'un problème lié à une utilisation non
                conventionnelle du véhicule.
            </li>
        </ul>
        <h2 class="page-content__subtitle">11. Paiement Des Prestations</h2>
        <p>
            BACT SAS règlera directement les réparations aux réparateurs agréés
            pour autant que la demande préalable d'intervention ait été
            respectée. <br />
            Le bénéficiaire qui donnerait un tel accord au nom de BACT SAS ou
            qui réglerait une facture sans que BACT SAS ait été avertie de
            l'intervention faite sur le véhicule engagerait sa responsabilité
            pour le règlement de la facture. <br />
            En tout état de cause, aucune facture ne sera réglée par BACT SAS
            sans que BACT SAS n'ait été informée au préalable et avant toute
            intervention sur le véhicule de la réparation.
        </p>
        <h2 class="page-content__subtitle">12. Responsabilité</h2>
        <p>
            BACT SAS ne saurait être tenu pour responsable des manquements ou
            retards dont le réparateur agréé serait fautif.
        </p>
        <h2 class="page-content__subtitle">
            13. Protection Des Donnees A Caractere Personnel
        </h2>
        <p>
            Ce paragraphe a pour objectif de vous informer de la manière dont
            vos informations personnelles sont collectées et traitées par la
            société BACT SAS (ci-après dénommée « BACT SAS ») en tant que
            responsable de traitement.
        </p>
        <p>
            Tout candidat au contrat de prestations de services “Révisions et
            Pièces d’usure” est informé que ses données à caractère personnel
            font l’objet de traitements dans le cadre de sa demande d’adhésion.
            BACT SAS a nommé un délégué à la protection des données personnelles
            joignable aux coordonnées suivantes : thibaud@joinsteer.com. Les
            traitements de données à caractère personnel effectués par Nous,
            ainsi que leurs conditions et modalités de mise en œuvre (personnes
            concernées, modes de collecte, finalités, destinataires et durées de
            conservation des données) Ces droits s’exercent auprès de BACT SAS.
            Pour des raisons de sécurité, cette demande devra être accompagnée
            d'un justificatif d'identité. Afin de permettre un traitement
            efficace des demandes, les personnes exerçant leurs droits ci-dessus
            sont priées d'indiquer clairement le droit qu’elles souhaitent
            exercer ainsi que tout élément facilitant leur identification
            (numéro de contrat par exemple).
        </p>
        <h2 class="page-content__subtitle">14. Prescription Et Réclamation</h2>
        <p>
            Toute action dérivant du présent Contrat est prescrite pour cinq ans
            à compter de l'événement qui y donne naissance selon les
            dispositions de l’article 2224 du Code civil. La prescription est
            interrompue par une des causes ordinaires d'interruption de la
            prescription précisées aux articles 2240 et suivants du Code civil
            (reconnaissance par BACT SAS du droit du Bénéficiaire ; demande en
            justice, même en référé ; acte d'exécution forcée ; désignation
            d'experts).
        </p>
        <p>
            Pour toute réclamation, s’adresser à BACT SAS, Service Relations
            Clientèle 930 Route des Dolines 06560 Valbonne- Email :
            hello@joinsteer.com
        </p>
        <p>
            En cas d’échec de ce processus, conformément aux articles L612-1 et
            suivants du Code de la consommation, l’Adhérent a la possibilité de
            saisir le Médiateur de la consommation par voie postale à l’adresse
            suivante : Médiateur du Conseil national des professions de
            l’automobile (CNPA), 50 rue Rouget de Lisle, 92158 Suresnes Cedex,
            ou en ligne : www.mediateur-cnpa.fr.
        </p>
        <h2 class="page-content__subtitle">
            15. Clause Attributive De Compétence
        </h2>
        <p>
            Les parties s'engagent à rechercher une solution amiable à toutes
            les difficultés susceptibles de naître en cours d'exécution du
            présent contrat. Dans le cas où cependant aucun rapprochement ne
            pourrait être constaté entre les parties, le litige sera porté
            devant les tribunaux compétents du vendeur.
        </p>
        <p>
            La juridiction compétente est celle du siège ou d’un des
            établissements&nbsp; du vendeur.
        </p>
        <p>
            Les parties déclarent se soumettre à la juridiction des tribunaux
            français et renoncent à toute procédure dans tout autre pays.
        </p>
        <h2 class="page-content__subtitle">16. Intégralité De La Convention</h2>
        <p>
            Si l'une quelconque des stipulations des présentes conditions
            générales est déclarée nulle au regard d'une règle de droit en
            vigueur ou d'une décision judiciaire devenue définitive, elle sera
            réputée non écrite, mais les autres dispositions garderont toute
            leur force et leur portée.
        </p>
        <p>
            Le bénéficiaire déclare adhérer au contrat “Révisions et pièces
            d'usure” précité. Le bénéficiaire reconnaît avoir reçu et pris
            connaissance de la notice d'information relative à ce contrat
            précisant notamment le montant, la durée et les exclusions des
            garanties, dont il accepte les termes et conserve un exemplaire.
        </p>
    </div>
</template>

<style lang="scss" scoped>
.page-content {
    color: $dark-font;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 8rem;

    &__subtitle {
        color: black;
        font-weight: 400;
        font-family: $bebas;
        font-size: 2.5rem;
        margin: 4rem 0 1rem 0;
    }

    &__title {
        color: black;
        font-weight: 400;
        font-family: $bebas;
        font-size: 3rem;
        margin-bottom: 4rem;
    }

    &__list {
        p {
            margin: 0;
        }
    }
}
</style>
