<script setup lang="ts">
import { usePage } from '@inertiajs/vue3';

const props = defineProps<{ id: number }>();

const id = props.id;
const page = usePage();
const user = page.props.auth?.user;

const handleClick = () => {
    if (user?.has_nova_access) {
        window.location.href = `/admin/resources/vehicles/${id}`;
    }
};
</script>

<template>
    <span
        class="vehicle-id"
        v-if="id > 0"
        :class="{ 'is-clickable': user?.has_nova_access }"
        @click="handleClick"
    >
        <span class="vehicle-id__prefix">JS</span>
        <span class="vehicle-id__number">{{ id }}</span>
    </span>
</template>

<style scoped lang="scss">
.vehicle-id {
    padding: 0.125rem 0.25rem;
    border-radius: 2px;
    background: #00313a;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;
    letter-spacing: 0.64px;
    font-family: $bebas;
    vertical-align: middle;
    margin-left: 0.25rem;

    &.is-clickable {
        cursor: pointer;
        &:hover {
            background: lighten(#00313a, 5%);
        }
    }

    &__prefix {
        color: $primary;
    }
}
</style>
