<script setup lang="ts">
import Breadcrumb from "../../Components/Breadcrumb.vue";
import {
    Article,
    DynamicContent,
    Question,
    Review,
    VehicleMake,
    VehicleModel,
} from "../../types";
import ModelCard from "@/Components/Cards/ModelCard.vue";
import { useI18n } from "vue-i18n";
import SectionsLandingList from "@/Components/Sections/SectionsLandingList.vue";
const { t } = useI18n();
import { Head } from "@inertiajs/vue3";

defineProps<{
    vehicleMake: VehicleMake;
    dynamicContent: Array<DynamicContent>;
    faqJoinsteer: Array<Question>;
    faqStory: Array<Question>;
    models: Array<VehicleModel>;
    totalVehicles: number;
    breadcrumbs: Array<{ label: string; path?: string }>;
    minMonthly: number;
    topModels: Array<VehicleModel>;
    articles: Array<Article>;
    customerReview?: Review;
    reviewSchema: JSON;
    faqSchema: JSON;
}>();

const getOptions = (vehicleModel) => {
    const options = [];
    options["url"] = route("vehicleModel.show", {
        vehicleMake: vehicleModel.vehicle_make,
        vehicleModel: vehicleModel,
    });
    if (vehicleModel.vehicle_specifications_count > 0) {
        options["textAction"] = "Voir toutes les finitions";
        options["class"] = "model";
    } else {
        options["textAction"] = "Voir les véhicules disponibles";
        options["class"] = "model";
    }

    return options;
};
</script>

<template>
    <Head>
        <title>{{ vehicleMake.seo_data.title }}</title>
        <meta
            head-key="description"
            name="description"
            :content="vehicleMake.seo_data.content"
        />
        <component :is="'script'" type="application/ld+json">
            {{ reviewSchema }}
        </component>
        <component :is="'script'" type="application/ld+json" v-if="faqSchema">
            {{ faqSchema }}
        </component>
    </Head>

    <div class="container landing-make">
        <Breadcrumb :items="breadcrumbs"></Breadcrumb>
        <h1 class="landing-make__title main">
            <img
                v-if="vehicleMake.logo_url"
                :src="vehicleMake.logo_url"
                :alt="vehicleMake.designation"
                class="landing-make__title--logo"
            />
            {{
                t("landingPage.title", {
                    make: vehicleMake.designation,
                    minMonthly: $filters.currency(minMonthly, true),
                })
            }}
        </h1>

        <div class="landing-make__filters">
            <p class="landing-make__filters--item">
                {{
                    t("landingPage.filtersItem", models.length, {
                        count: models.length,
                    })
                }}
            </p>

            <p class="landing-make__filters--item">
                {{
                    t("landingPage.filters", {
                        total: totalVehicles,
                        make: vehicleMake.designation,
                        minMonthly: $filters.currency(minMonthly, true),
                    })
                }}
            </p>
        </div>

        <div class="landing-make__wrapper models">
            <ModelCard
                :item="model"
                v-for="model in models"
                :key="model.id"
                :options="getOptions(model)"
                title-level="h2"
            />
        </div>
    </div>

    <SectionsLandingList
        :dynamic-content="dynamicContent"
        :faq-joinsteer="faqJoinsteer"
        :faq-story="faqStory"
        :articles="articles"
        :vehicle-make="vehicleMake"
        :customer-review="customerReview"
        :is-landing="true"
        :top-models="topModels"
        :min-monthly="minMonthly"
        :item-seo="vehicleMake.designation"
    />
</template>
