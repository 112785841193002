<template>
    <svg
        width="16"
        height="9"
        viewBox="0 0 16 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.18545 0C4.29395 2.64571e-05 4.39998 0.0324236 4.48996 0.0930465C4.57995 0.153669 4.6498 0.239761 4.69058 0.340307C4.73137 0.440852 4.74122 0.551278 4.71889 0.657457C4.69657 0.763636 4.64307 0.860739 4.56524 0.936341L1.86128 3.6403H15.4471C15.5193 3.63928 15.5911 3.65263 15.6581 3.67957C15.7252 3.70651 15.7862 3.7465 15.8376 3.79723C15.8891 3.84796 15.9299 3.9084 15.9578 3.97506C15.9857 4.04171 16.0001 4.11324 16.0001 4.1855C16.0001 4.25775 15.9857 4.32928 15.9578 4.39593C15.9299 4.46259 15.8891 4.52303 15.8376 4.57376C15.7862 4.62449 15.7252 4.66448 15.6581 4.69142C15.5911 4.71836 15.5193 4.73171 15.4471 4.73069H1.86128L4.56524 7.43465C4.61756 7.48489 4.65934 7.54506 4.68811 7.61164C4.71689 7.67823 4.73209 7.74988 4.73283 7.82241C4.73357 7.89495 4.71982 7.9669 4.69241 8.03405C4.66499 8.1012 4.62445 8.16221 4.57316 8.2135C4.52187 8.2648 4.46086 8.30534 4.3937 8.33275C4.32655 8.36017 4.2546 8.37391 4.18207 8.37318C4.10954 8.37244 4.03788 8.35724 3.9713 8.32846C3.90471 8.29968 3.84454 8.25791 3.7943 8.20559L0.15968 4.57096C0.0574751 4.46872 6.10352e-05 4.33006 6.10352e-05 4.1855C6.10352e-05 4.04093 0.0574751 3.90227 0.15968 3.80003L3.7943 0.165404C3.84512 0.11307 3.90592 0.0714658 3.97311 0.0430546C4.0403 0.0146434 4.1125 2.90002e-06 4.18545 0Z"
            fill="currentColor"
        />
    </svg>
</template>
