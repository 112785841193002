<script lang="ts" setup>
import { DynamicContent, Question, VehicleMake } from "@/types";
import { useI18n } from "vue-i18n";
import AccordionList from "@/Components/Accordions/AccordionList.vue";
import TitleTabulatedMultiLine from "@/Components/Typography/TitleTabulatedMultiLine.vue";
import MaskedText from "@/Components/Typography/MaskedText.vue";
import { getValueOf } from "@/helpers/dynamicContent";

import Audi from "../../../../resources/images/vehicleMake/interior/desktop/audi.webp";
import AudiSmall from "../../../../resources/images/vehicleMake/interior/mobile/audi.webp";
import AstonMartin from "../../../../resources/images/vehicleMake/interior/desktop/astonmartin.webp";
import AstonMartinSmall from "../../../../resources/images/vehicleMake/interior/mobile/astonmartin.webp";
import Ferrari from "../../../../resources/images/vehicleMake/interior/desktop/ferrari.webp";
import FerrariSmall from "../../../../resources/images/vehicleMake/interior/mobile/ferrari.webp";
import Lamborghini from "../../../../resources/images/vehicleMake/interior/desktop/lamborghini.webp";
import LamborghiniSmall from "../../../../resources/images/vehicleMake/interior/mobile/lamborghini.webp";
import LandRover from "../../../../resources/images/vehicleMake/interior/desktop/landrover.webp";
import LandRoverSmall from "../../../../resources/images/vehicleMake/interior/mobile/landrover.webp";
import Mercedes from "../../../../resources/images/vehicleMake/interior/desktop/mercedes.webp";
import MercedesSmall from "../../../../resources/images/vehicleMake/interior/mobile/mercedes.webp";
import Mini from "../../../../resources/images/vehicleMake/interior/desktop/mini.webp";
import MiniSmall from "../../../../resources/images/vehicleMake/interior/mobile/mini.webp";
import Porsche from "../../../../resources/images/vehicleMake/interior/desktop/porsche.webp";
import PorscheSmall from "../../../../resources/images/vehicleMake/interior/mobile/porsche.webp";
import Home from "../../../../resources/images/home.webp";
import HomeSmall from "../../../../resources/images/home-mobile.webp";

const { t } = useI18n();

defineProps<{
    vehicleMake: VehicleMake;
    dynamicContent: Array<DynamicContent>;
    faqStory: Array<Question>;
    minMonthly: number;
    itemSeo: string;
}>();

const matchingImages: Record<string, { mobile: string; desktop: string }> = {
    audi: {
        mobile: AudiSmall,
        desktop: Audi,
    },
    "aston-martin": {
        mobile: AstonMartinSmall,
        desktop: AstonMartin,
    },
    bmw: {
        mobile: HomeSmall,
        desktop: Home,
    },
    ferrari: {
        mobile: FerrariSmall,
        desktop: Ferrari,
    },
    lamborghini: {
        mobile: LamborghiniSmall,
        desktop: Lamborghini,
    },
    "land-rover": {
        mobile: LandRoverSmall,
        desktop: LandRover,
    },
    mclaren: {
        mobile: HomeSmall,
        desktop: Home,
    },
    mercedes: {
        mobile: MercedesSmall,
        desktop: Mercedes,
    },
    mini: {
        mobile: MiniSmall,
        desktop: Mini,
    },
    porsche: {
        mobile: PorscheSmall,
        desktop: Porsche,
    },
    volvo: {
        mobile: HomeSmall,
        desktop: Home,
    },
};
</script>

<template>
    <section class="story container">
        <img
            class="story__image"
            :srcset="`${matchingImages[vehicleMake.ref].mobile}  600w,
                ${matchingImages[vehicleMake.ref].desktop}  1280w`"
            :src="matchingImages[vehicleMake.ref].desktop"
            sizes="(max-width: 600px) 600px,
                         1280px"
            alt="Un peu d'histoire"
        />

        <div class="story__wrapper">
            <div class="story__intro">
                <TitleTabulatedMultiLine
                    is-bold
                    data-scroll
                    data-scroll-offset="200, 200"
                    :labels="[
                        {
                            isInterpolated: false,
                            content: t('sections.story.titleTabulate.one'),
                            gap: '0',
                        },
                        {
                            isInterpolated: true,
                            interpolatedContent: {
                                keypath: 'sections.story.titleTabulate.two',
                                slots: [
                                    {
                                        name: 'make',
                                        value: itemSeo,
                                    },
                                    {
                                        name: 'minMonth',
                                        value: `${minMonthly}&nbsp€/MOIS EN LLD`,
                                        color: '#809EA3',
                                    },
                                ],
                            },
                            gap: '2.5ch',
                        },
                    ]"
                    :level="2"
                />

                <AccordionList :items="faqStory" icon-color-closed="#AAB2B2" />
            </div>

            <div class="story__content">
                <h3 class="story__content__title">Un peu d'histoire</h3>

                <MaskedText>
                    <p>
                        {{
                            getValueOf(
                                dynamicContent,
                                "description-story",
                                true,
                            )
                        }}
                    </p>
                </MaskedText>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.story {
    &__wrapper {
        margin-top: clampGenerator(1.75, 2.75);
    }

    &__image {
        display: block;
        width: 100%;
    }

    &__intro {
        h2 {
            margin: 0;
        }

        .accordions {
            margin-top: clampGenerator(0.5, 4);
        }
    }

    &__content {
        &__title {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 700;
        }
    }

    @media (max-width: $breakpoint-l) {
        &__content {
            margin-top: clampGenerator(2, 4);
        }
    }

    @media (min-width: $breakpoint-l) {
        &__wrapper {
            display: flex;
            justify-content: space-between;
            gap: 4rem;
        }

        &__intro {
            flex-grow: 1;

            .accordions {
                max-width: 35rem;
            }
        }
        &__content {
            margin-top: 1.55rem;
            width: 35rem;
        }
    }
}
</style>
