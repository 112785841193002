<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
    onlyStroke?: boolean;
}>();

const svgProps = computed(() => {
    if (props.onlyStroke) {
        return {
            stroke: "currentColor",
            "stroke-width": 2,
            fill: "transparent",
        };
    } else {
        return {
            fill: "currentColor",
        };
    }
});
</script>

<template>
    <svg viewBox="0 0 79 56" xmlns="http://www.w3.org/2000/svg">
        <path
            id="Vector"
            d="M78.75 54.7457L77.8103 53.1412C77.1525 51.9143 76.1187 49.8379 74.6152 48.8941L63.9022 42.8537L59.2975 44.175L53.753 24.8268C52.6253 22.0897 50.6519 19.7302 48.0206 18.2201L0 -10L3.85292 0.66513C4.32278 2.26962 5.3565 3.49655 6.57816 4.53475L35.71 24.8268L9.39738 13.6898C9.39738 13.7842 11.5588 21.4291 11.5588 21.4291C11.7467 22.1841 12.1226 22.9392 12.5925 23.5054C12.7804 23.7886 13.0624 24.0717 13.3443 24.3549L36.6498 40.5885L17.761 32.6605L20.2983 42.0042C20.7682 43.7975 22.2718 45.3076 23.2115 46.2514L42.2882 65.5996C44.3556 62.1075 49.2422 58.8041 56.7601 55.7839C60.0492 54.4626 63.1504 53.6131 66.1575 53.1412C71.1381 52.3862 75.4609 52.7637 78.75 54.7457Z"
            v-bind="svgProps"
        />
    </svg>
</template>
