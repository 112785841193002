<script setup>
import { useI18n } from "vue-i18n";
import TitleTabulatedMultiLine from "@/Components/Typography/TitleTabulatedMultiLine.vue";
import Button from "@/Components/Buttons/Button.vue";
import Tag from "@/Components/Home/Tag/Tag.vue";
import { Link } from "@inertiajs/vue3";
import Cross from "@/Components/Icons/Cross.vue";

const { t } = useI18n();
</script>
<template>
    <section
        class="section"
        data-scroll
        data-scroll-css-progress
        data-scroll-offset="200, 0"
    >
        <div class="section__container container">
            <div class="section__top">
                <div class="section__top__tags">
                    <Cross />
                    <Tag>{{ t("home.passion.tags[0]") }}</Tag>
                    <Tag>{{ t("home.passion.tags[1]") }}</Tag>
                </div>
                <div>
                    <TitleTabulatedMultiLine
                        data-scroll
                        data-scroll-offset="30%,0"
                        :level="2"
                        :labels="[
                            {
                                isInterpolated: false,
                                content: t('home.passion.title[0]'),
                            },
                            {
                                isInterpolated: false,
                                content: t('home.passion.title[1]'),
                                gap: '4.5ch',
                            },
                        ]"
                    />
                </div>

                <div class="section__top__background">
                    <picture>
                        <source
                            media="(max-width: 640px)"
                            srcset="
                                ../../../../../resources/images/home/road-passion-mobile.webp
                            "
                        />

                        <img
                            src="../../../../../resources/images/home/road-passion-desktop.webp"
                            alt=""
                            loading="lazy"
                        />
                    </picture>
                </div>
            </div>

            <div class="section__content">
                <p>
                    <b>{{ t("home.passion.text[0]") }}</b>
                    {{ t("home.passion.text[1]") }}
                </p>
                <Button
                    :as="Link"
                    :href="route('vehicles.index')"
                    variante="arrow"
                    >{{ t("home.passion.cta") }}</Button
                >
            </div>
        </div>
    </section>
</template>
<style lang="scss" scoped>
html.lenis {
    .section {
        &__top {
            &__background {
                img {
                    transform: scale(1.2);
                }
            }

            &__tags {
                > * {
                    &:first-child {
                        opacity: 0;
                        transform: rotate(0deg) scale(0);
                    }
                    &:not(:first-child) {
                        transform: translateX(-80vw);
                    }
                }
            }
        }

        &.is-inview {
            .section__top__tags {
                > * {
                    &:first-child {
                        opacity: 1;
                        transition: all 1.5s ease-in-out;
                        transform: rotate(720deg) scale(1);
                    }
                    &:nth-child(2) {
                        transition: all 0.8s ease-in-out;
                        transform: translateX(0);
                    }
                    &:nth-child(3) {
                        transition: all 0.8s ease-in-out 0.75s;
                        transform: translateX(0);
                    }
                }
            }

            .section__top__background {
                img {
                    transform-origin: center center;
                    transform: translate(
                            calc(var(--progress) * 15%),
                            calc(var(--progress) * -10%)
                        )
                        scale(1.2);
                    @include medium-up {
                        transform: translate(
                                calc(var(--progress) * 10%),
                                calc(var(--progress) * -10%)
                            )
                            scale(1.2);
                    }
                }
            }
        }
    }
}

.section {
    position: relative;
    --cross-size: #{clampGenerator(2, 7.5, 40, 120)};
    color: white;

    width: 100%;

    &__container {
        display: grid;
        height: 100%;
        grid-template-columns: var(--grid-columns);
    }

    &__top {
        align-self: center;
        z-index: 2;

        &__tags,
        h2 {
            position: relative;
            z-index: 2;
        }

        &__tags {
            display: inline-block;
            position: relative;
            padding-left: clampGenerator(3, 6, 40, 120);
            > * {
                position: relative;
            }
            svg {
                position: absolute;
                width: var(--cross-size);
                height: var(--cross-size);
                left: 0;
                bottom: calc(var(--cross-size) / 3);
            }

            p {
                position: relative;
                --tag-translate: 0.25rem;

                &:first-of-type {
                    left: var(--tag-translate);
                }
                &:last-of-type {
                    left: calc(-1 * var(--tag-translate));
                }
            }
        }

        &__background {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            overflow: hidden;

            img {
                height: 100%;
                object-fit: cover;
                object-position: center;
                width: 100%;
            }
        }
    }

    &__content {
        align-self: end;
        padding-bottom: var(--hp-y-space);
        color: rgba(255, 255, 255, 0.87);
        z-index: 2;

        p {
            line-height: var(--description-line-height);
        }
    }

    @include medium-up {
        height: calc(100dvh - 100px);
        background-color: black;

        &__top {
            &__tags {
                p {
                    --tag-translate: 0.4rem;
                }
            }
        }

        &__content {
            grid-column: 3/4;
            padding-bottom: 70px;
        }
    }

    @include medium-down {
        &__top {
            position: relative;
            padding-top: 50%;
            padding-bottom: 2rem;
            text-align: center;

            &__background {
                left: calc(calc(var(--margin-container) * -1) / 2);
                right: calc(calc(var(--margin-container) * -1) / 2);
            }
        }

        &__content {
            padding-top: 20px;
            color: $dark-font;
        }
    }
}
</style>
