<template>
    <svg viewBox="0 0 237 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor">
            <path
                d="M10.4376 5.047H22.7306V11.1652C22.7306 16.6322 17.8876 17.1989 14.0066 17.1989C10.5796 17.1989 6.41558 16.799 5.39558 12.922H0.892578C1.85558 19.182 7.12358 21.2218 13.9786 21.2218C21.4856 21.2218 27.1496 18.785 27.2066 11.109V1.026H10.4376V5.047Z"
            />
            <path
                d="M44.9447 0.460083C36.1357 0.460083 30.5547 3.12277 30.5547 10.1188V11.5631C30.5547 18.5631 36.1347 21.2228 44.9447 21.2228C53.7547 21.2228 59.3347 18.5601 59.3347 11.5631V10.1188C59.3387 3.12277 53.7537 0.460083 44.9447 0.460083ZM55.0007 11.5597C55.0007 16.2907 50.0147 17.2819 44.9447 17.2819C39.8747 17.2819 34.8887 16.2907 34.8887 11.5597V10.1158C34.8887 5.38484 39.8747 4.39368 44.9447 4.39368C50.0147 4.39368 55.0007 5.38484 55.0007 10.1158V11.5597Z"
            />
            <path
                d="M92.9035 15.528L76.0495 1.02795H71.4604V20.8561H75.9364V6.38098L92.7905 20.8561H97.3795V1.02795H92.9035V15.528Z"
            />
            <path
                d="M119.932 9.297L111.377 7.99719C107.637 7.43119 105.569 6.6379 105.569 5.0509H120.044L116.051 1.02991H107.468C103.559 1.02991 101.35 2.10592 101.35 5.36292V6.12805C101.35 9.15905 104.465 10.9999 109.05 11.6779L118.793 13.152C120.775 13.463 121.74 13.8029 121.74 14.8229V15.2228C121.74 16.4408 119.869 17.1782 114.205 17.2062C109.362 17.2062 105.537 16.1858 104.943 12.9288H100.581C100.781 18.7358 106.643 21.2291 114.235 21.2291C121.967 21.2291 126.103 19.2173 126.103 15.5363V14.317C126.106 11.534 123.897 9.892 119.932 9.297Z"
            />
            <path
                d="M123.702 5.047H134.523V20.8531H138.997V5.047H149.818V1.026H123.702V5.047Z"
            />
            <path
                d="M157.415 12.7531H170.7V8.81702H157.415V5.04895H169.433L173.426 1.02795H152.939V20.8561H175.345V16.8331H157.415V12.7531Z"
            />
            <path
                d="M219.799 13.0641L225.039 12.5822C229.373 12.2142 232.039 10.798 232.039 6.85999C232.039 3.20699 229.376 1.026 225.039 1.026H205.125V20.8541H209.601V13.9708L212.32 13.7159L227.785 20.8551H236.736L219.799 13.0641ZM209.599 10.2032V5.047H225.319C226.679 5.047 227.619 5.55699 227.619 6.85999C227.619 8.13499 226.684 8.67325 225.319 8.78625L209.599 10.2032Z"
            />
            <path
                d="M183.372 12.7531H196.657V8.81702H183.372V5.04895H195.386L199.379 1.02795H178.897V20.8561H201.303V16.8331H183.373L183.372 12.7531Z"
            />
            <path d="M67.5968 1.02698H63.1558V20.855H67.5968V1.02698Z" />
        </g>
    </svg>
</template>
