<script lang="ts" setup>
import { ref } from "vue";
import Button from "@/Components/Buttons/Button.vue";
/* import Badge from "@/Components/Badge/Badge.vue"; */
import MoreDropdown from "@/Components/MoreDropdown/MoreDropdown.vue";
import Modal from "@/Modal/Modal.vue";
import Logo from "@/Components/Icons/Logo.vue";
import { Simulation } from "@/types";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { useForm } from "@inertiajs/vue3";

const toast = useToast();

const props = withDefaults(
    defineProps<{ disabled?: boolean; data: Simulation }>(),
    {
        disabled: false,
    },
);

const showDeleteModal = ref(false);
const callbackRequestReceived = ref(false);

const form = useForm({});
const callbackForm = useForm({});

const { t } = useI18n();

const deleteItem = () => {
    showDeleteModal.value = false;
    form.delete(
        route("simulations.destroy", {
            simulation: props.data.id,
        }),
        {
            onSuccess: () => {
                toast.success(t("toast.deleteSimulation"));
            },
        },
    );
};

const askForCallback = () => {
    callbackForm.post(
        route("simulations.callback", { simulation: props.data }),
        {
            onSuccess: () => {
                callbackRequestReceived.value = true;
                toast.success(t("tunnel.callbackRequestReceived"));
            },
        },
    );
};
</script>
<template>
    <div class="item" :class="{ '-disabled': disabled }">
        <header class="item__header">
            <Logo />
            <h3 class="item__status">
                {{ t("simulation.title[0]") }}
                <span>{{ t("simulation.title[1]") }}</span>
            </h3>
        </header>
        <div class="item__section">
            <div class="item__imageWrapper">
                <!-- <Badge class="item__badge">éligible</Badge> -->
                <a :href="data.vehicle.url">
                    <img
                        :src="data.vehicle.previewData?.image_url"
                        :alt="`Image : ${data.vehicle.display_name2}`"
                    />
                </a>
                <p>
                    <b>
                        {{ t("simulation.visits", data.vehicle.visit_count) }}
                    </b>
                    {{ t("simulation.week") }}
                </p>
            </div>
            <div class="item__content">
                <MoreDropdown
                    class="item__more"
                    :options="[
                        {
                            label: t('simulation.actions.edit'),
                            url: route('simulations.resume', {
                                simulation: data,
                            }),
                        },
                        {
                            label: t('simulation.actions.download'),
                            url: route('simulations.pdf', { simulation: data }),
                            newTab: true,
                        },
                        {
                            label: t('simulation.actions.delete'),
                            action: () => (showDeleteModal = true),
                        },
                    ]"
                />
                <h2 class="item__title">{{ data.vehicle.display_name2 }}</h2>
                <p>
                    {{
                        t("simulation.date", {
                            created_at: $filters.date(
                                data.created_at,
                                "DD/MM/YY",
                            ),
                        })
                    }}
                </p>
                <p class="item__desc">
                    {{
                        t("simulation.description", {
                            duration: data.current_revision.duration,
                            first_rent: $filters.number(
                                data.current_revision.first_rent,
                            ),
                            mileage: $filters.number(
                                data.current_revision.mileage,
                            ),
                        })
                    }}

                    <br />
                    {{ t("simulation.shipping") }}
                </p>
                <footer class="item__footer">
                    <p class="item__price">
                        <b>{{
                            $filters.currency(
                                data.current_revision.price,
                                false,
                            )
                        }}</b>
                        <span>{{ t("vehicle.perMonth") }}</span>
                    </p>
                    <Button
                        type="button"
                        @click="askForCallback"
                        :disabled="
                            callbackRequestReceived || callbackForm.processing
                        "
                        :loading="callbackForm.processing"
                    >
                        {{
                            t(
                                callbackRequestReceived
                                    ? "tunnel.callbackRequestReceived"
                                    : "tunnel.priorityCallbackRequest",
                            )
                        }}
                    </Button>
                </footer>
            </div>
        </div>
        <Modal :show="showDeleteModal" @close="showDeleteModal = false">
            <template #content>
                <h1 class="modal__title">
                    {{
                        t("simulation.modal.title", {
                            vehicle: data.vehicle.display_name2,
                        })
                    }}
                </h1>
                <p class="modal__confirm">
                    {{ t("simulation.modal.text") }}
                </p>

                <div class="modal__actions">
                    <Button type="submit" variante="danger" @click="deleteItem">
                        {{ t("simulation.modal.action") }}
                    </Button>
                    <Button
                        type="button"
                        variante="secondary"
                        @click="showDeleteModal = false"
                    >
                        {{ t("actions.cancel") }}
                    </Button>
                </div>
            </template>
        </Modal>
    </div>
</template>
<style lang="scss" scoped>
.item {
    $this: &;
    &.-disabled {
        img {
            opacity: 0.1;
        }
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    &__badge {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        margin: 0;
    }

    &__content {
        position: relative;
        padding: 1rem 0.5rem 2rem 0.5rem;
        @include medium-up {
            padding: $space-m $space-l;
        }
        > *:first-child {
            margin-top: 0;
        }
    }
    &__desc {
        opacity: 0.5;
    }
    &__footer {
        margin-top: $space-l;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: $space-m;
    }
    &__header {
        background: $dark-bg;
        color: white;
        border-radius: 0.25rem 0.25rem 0 0;
        padding: 1rem 1rem 1rem 40px;
        position: relative;
        overflow: hidden;
        @include medium-up {
            padding: 1.25rem 1.25rem 1.25rem 78px;
        }
        svg {
            position: absolute;
            top: 50%;
            left: -1rem;
            width: 78px;
            height: 78px;
            opacity: 0.04;
            transform: translateY(-50%);
            @include medium-up {
                left: 0.5rem;
            }
        }
        > * {
            margin: 0;
        }
    }
    &__imageWrapper {
        background: $grey-card;
        position: relative;
        display: grid;
        text-align: center;
        grid-template-rows: 1fr 3rem;
    }
    &__price {
        display: inline-flex;
        align-items: center;
        gap: 0.25rem;
        margin: 0;
        @include medium-down {
            font-size: 0.8rem;
            b {
                font-size: 1.25em;
            }
        }
        b {
            font-size: 1.5em;
        }
        span {
            opacity: 0.5;
        }
    }
    &__more {
        position: absolute;
        top: 0.5rem;
        right: 0.25rem;
        @include medium-up {
            top: 1rem;
            right: 0.5rem;
        }
    }
    &__section {
        border: 1px solid $grey-card;
        border-top: none;
        border-radius: 0 0 0.25rem 0.25rem;
        @include medium-up {
            display: grid;
            grid-template-columns: clampGenerator(16.25, 19.625) 1fr; // (260px <-> 314px) + 1fr;
        }
    }
    &__status {
        font-size: $font-size-m;
        font-weight: 400;
        font-family: $bebas;
        text-transform: uppercase;
        span {
            color: $primary;
        }
    }
    &__title {
        margin: 0;
        padding-right: 2rem; //need this to avoid text superposition with the more button
        + * {
            margin-top: 0.25rem;
        }
    }
}
.modal {
    &__title {
        font-weight: 600;
        padding: 0;
        margin: 0;
        font-size: 1.5rem;
    }

    &__confirm {
        opacity: 0.6;
        line-height: 1.5rem;
        margin: 1rem 0 1.5rem;
        padding: 0;
    }

    &__actions {
        button {
            width: 100%;
            + * {
                margin-top: 0.5rem;
            }
        }
    }
}
</style>
