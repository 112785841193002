<script setup lang="ts">
import { usePage } from "@inertiajs/vue3";

const page = usePage();

defineProps<{ items: { label: string; path?: string }[] }>();
</script>

<template>
    <div
        class="breadcrumb"
        :class="page.component === 'Vehicle/Show' ? 'vehicle-detail' : ''"
    >
        <div
            v-for="(item, index) in items"
            :key="item.label"
            class="breadcrumb__items"
        >
            <a v-if="item.path" :href="item.path" class="breadcrumb__item">
                <img
                    v-if="page.component === 'Vehicle/Show'"
                    src="../../images/icons/arrow-goback.svg"
                    alt=">"
                    width="24"
                    height="24"
                    class="breadcrumb__icon"
                />
                {{ item.label }}
            </a>
            <span v-else class="breadcrumb__item--current">{{
                item.label
            }}</span>
            <img
                v-if="index < items.length - 1"
                src="../../images/icons/arrow-breadcrumb.svg"
                alt=">"
                width="12"
                height="12"
                class="breadcrumb__icon"
            />
        </div>
    </div>
</template>
<style lang="scss">
.breadcrumb {
    display: flex;
    margin: 2rem 0 1.5rem 0;
    align-content: center;
    align-items: center;
    @media (max-width: $breakpoint-l) {
        display: none;
        &.vehicle-detail {
            margin-top: 1rem;
            display: flex;
        }
    }
    &.marketplace {
        padding: 0 1.5rem 1.5rem 1.5rem;

        @include medium-up {
            padding: 0 2.5rem;
            margin-bottom: 0;
        }

        + .marketplace-content {
            padding-top: 1.5rem;
        }
    }
    &__items {
        display: flex;
        align-content: center;
        align-items: center;
    }
    &__item {
        text-decoration: none;
        color: $dark-font;
        font-size: 1rem;
        line-height: 1.5;
        opacity: 0.5;
        display: flex;
        align-content: center;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
        &--current {
            opacity: 0.5;
            font-weight: 700;
        }
    }
    &__icon {
        margin: 0 0.5rem;
    }
}
</style>
