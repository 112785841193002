<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Article } from "@/types";
import PressCarousel from "@/Components/Sections/Press/PressCarousel.vue";
const { t } = useI18n();
import { usePage } from "@inertiajs/vue3";

const page = usePage();

const props = defineProps<{
    items: Article[];
}>();
</script>
<template>
    <div class="press">
        <section class="section container" v-if="items.length > 0">
            <h2 class="section__title" v-if="page.component === 'Home'">
                {{ t("sections.press.title") }}
            </h2>
            <h2 class="section__title" v-else>
                {{ t("sections.press.titleLanding") }}
            </h2>
            <div class="section__carousel">
                <PressCarousel :items="props.items" />
            </div>
        </section>
    </div>
</template>
<style lang="scss" scoped>
.press {
    background: black;
    overflow: hidden;
}
.section {
    width: calc(100vw - 4rem);
    padding: 2rem;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include medium-up {
        flex-direction: row;
    }

    &__title {
        font-size: 2rem;
        color: white;
        font-family: $bebas;
        display: inline-block;
        text-transform: uppercase;
        border-right: none;
        border-bottom: 0.5px solid white;
        padding: 1rem 0 1rem 0;

        @include medium-up {
            padding: 3rem 2rem 3rem 0;
            border-right: 1px solid white;
            border-bottom: none;
        }

        @include large-up {
            padding: 3rem 5rem 3rem 0;
        }
    }
    &__carousel {
        margin-left: 0;
        max-width: 100%;
        margin-top: 0;
        position: relative;

        @include medium-up {
            max-width: 600px;
            margin-left: 2rem;
        }

        @include large-up {
            margin-top: 1rem;
            max-width: 840px;
        }
    }
}
</style>
