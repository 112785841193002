<script setup lang="ts">
import ModelCard from "@/Components/Cards/ModelCard.vue";
import Button from "@/Components/Buttons/Button.vue";
import { VehicleMake, VehicleModel } from "@/types";
import { useI18n } from "vue-i18n";
import { usePage } from "@inertiajs/vue3";

const { t } = useI18n();
const page = usePage();

const props = defineProps<{
    vehicleMake: VehicleMake;
    topModels: Array<VehicleModel>;
    itemSeo: string;
}>();

const getOptions = (item) => {
    if (page.component === "Vehicle/LandingMake") {
        return getOptionsLandingMake(item);
    } else {
        return getOptionsLandingModel(item);
    }
};

function getOptionsLandingMake(vehicleModel) {
    const options = [];
    options["url"] = route("vehicleModel.show", {
        vehicleMake: vehicleModel.vehicle_make,
        vehicleModel: vehicleModel,
    });
    if (vehicleModel.vehicle_specifications_count > 0) {
        options["textAction"] = "Voir toutes les finitions";
        options["class"] = "model";
    } else {
        options["textAction"] = "Voir les véhicules disponibles";
        options["class"] = "model";
    }

    return options;
}

function getOptionsLandingModel(spec) {
    const options = [];
    options["url"] = route("vehicleSpecification.show", {
        vehicleMake: props.vehicleMake,
        vehicleModel: spec.vehicle_model,
        vehicleSpecification: spec,
    });

    options["textAction"] = "Voir les véhicules disponibles";
    options["class"] = "spec";

    return options;
}
</script>

<template>
    <section class="top-offer container">
        <h2 class="landing-make__title top">
            <img
                v-if="vehicleMake.logo_url"
                :src="vehicleMake.logo_url"
                :alt="vehicleMake.designation"
                class="landing-make__title--logo"
            />
            {{
                t("landingPage.topTitle", {
                    make: itemSeo,
                })
            }}
        </h2>
        <h3 class="landing-make__subtitle">
            {{
                t("landingPage.topSubtitle", {
                    make: vehicleMake.designation,
                })
            }}
        </h3>
        <div class="landing-make__wrapper top">
            <ModelCard
                :item="model"
                v-for="model in topModels"
                :key="model.id"
                :options="getOptions(model)"
                :is-top-model="true"
                title-level="h4"
            ></ModelCard>
        </div>
        <div class="landing-make__action">
            <Button
                as="a"
                :href="
                    route('home') +
                    '/marketplace?menu[make]=' +
                    vehicleMake.designation
                "
            >
                Voir tous les véhicules
                {{ vehicleMake.designation }}
            </Button>
        </div>
    </section>
</template>
