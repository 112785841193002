<script setup lang="ts">
import { AlgoliaWidgetObject } from "../../types/index";
import { useI18n } from "vue-i18n";
import { PropType } from "vue";

const { t } = useI18n();

defineProps({
    items: {
        type: Array as PropType<AlgoliaWidgetObject[]>,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    translate: {
        type: Boolean,
        required: false,
        default: true,
    },
});
</script>

<template>
    <div class="facette-button__wrapper">
        <label
            v-for="item in items"
            :key="`facette-${name}-${item.value}`"
            class="facette-button"
            :class="{ 'facette-button--selected': item.isRefined }"
        >
            <input
                type="checkbox"
                :value="item.value"
                @input="$emit('refine', item.value)"
                :name="name"
                :checked="item.isRefined"
                class="facette-button__input"
            />
            <span>{{
                translate ? t(`${name}.${item.label}`) : item.label
            }}</span>
        </label>
    </div>
</template>

<style lang="scss" scoped>
.facette-button {
    color: #000;
    display: inline-block;
    border-radius: 4px;
    padding: 0.75rem;
    cursor: pointer;
    border: 1px solid $light-bg;
    background: #fff;
    transition:
        background-color $transition-time,
        border-color $transition-time;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    &:hover,
    &--selected {
        background: $primary;
    }

    &--selected {
        border-color: $primary;
    }

    &__input {
        @include hidden;
    }
}
</style>
