<script setup lang="ts"></script>

<template>
    <div class="container page-content">
        <h1 class="page-content__title">
            POLITIQUE DE CONFIDENTIALITÉ POUR BACT SAS
        </h1>
        <p>
            Chez Joinsteer, accessible à partir du site https://www.
            joinsteer.com/, l’une de nos principales priorités est la
            confidentialité de nos visiteurs. Cette politique de confidentialité
            contient les types d’informations recueillies et enregistrées par
            Joinsteer et la façon dont nous les utilisons.
        </p>
        <p>
            Si vous avez d’autres questions ou souhaitez plus d’informations sur
            notre Politique de confidentialité, n’hésitez pas à nous contacter.
        </p>
        <p>
            Cette politique de confidentialité s’applique uniquement à nos
            activités en ligne et est valable pour les visiteurs de notre site
            Web en ce qui concerne les informations qu’ils ont partagées et/ou
            collectées dans Joinsteer. Cette politique ne s’applique pas à toute
            information recueillie hors ligne ou par des canaux autres que ce
            site Web.
        </p>
        <h2 class="page-content__subtitle">Consentement</h2>
        <p>
            En utilisant notre site Web, vous acceptez notre Politique de
            confidentialité et acceptez ses conditions.
        </p>
        <h2 class="page-content__subtitle">
            Informations que nous collections
        </h2>
        <p>
            Les renseignements personnels que l’on vous demande de fournir, et
            les raisons pour lesquelles on vous demande de les fournir, vous
            seront clairement expliqués au moment où nous vous demanderons de
            fournir vos renseignements personnels.
        </p>
        <p>
            Si vous nous contactez directement, nous pouvons recevoir des
            informations supplémentaires vous concernant, telles que votre nom,
            votre adresse e-mail, votre numéro de téléphone, le contenu du
            message et/ou des pièces jointes que vous pouvez nous envoyer, et
            toute autre information que vous pouvez choisir de nous fournir.
        </p>
        <p>
            Lorsque vous vous inscrivez à un Compte, nous pouvons vous demander
            vos coordonnées, notamment votre nom, la raison sociale, l’adresse,
            l’adresse électronique et le numéro de téléphone.
        </p>
        <h2 class="page-content__subtitle">
            Comment nous utilisons vos informations
        </h2>
        <p>
            Nous utilisons les informations que nous recueillons de différentes
            façons, notamment pour :
        </p>
        <ul class="page-content__list">
            <li>
                <p>Fournir, exploiter et maintenir notre site Web</p>
            </li>
            <li>
                <p>Améliorez, personnalisez et élargissez notre site Web</p>
            </li>
            <li>
                <p>
                    Comprendre et analyser comment vous utilisez notre site Web
                </p>
            </li>
            <li>
                <p>
                    Développer de nouveaux produits, services, caractéristiques
                    et fonctionnalités
                </p>
            </li>
            <li>
                <p>
                    Communiquer avec vous, directement ou par l’intermédiaire de
                    l’un de nos partenaires, y compris pour le service à la
                    clientèle, pour vous fournir des mises à jour et d’autres
                    informations relatives au site Web, ainsi qu’à des fins de
                    marketing et de promotion.
                </p>
            </li>
            <li>
                <p>Vous envoyer des emails</p>
            </li>
            <li>
                <p>Détecter et prévenir la fraude</p>
            </li>
        </ul>
        <h2 class="page-content__subtitle">Fichiers journaux</h2>
        <p>
            Joinsteer suit une procédure standard d’utilisation des fichiers
            journaux. Ces fichiers enregistrent les visiteurs lorsqu’ils
            visitent des sites Web. Toutes les sociétés d’hébergement le font et
            font partie de l’analyse des services d’hébergement. Les
            informations recueillies par les fichiers journaux comprennent les
            adresses de protocole Internet (IP), le type de navigateur, le
            fournisseur d’accès Internet (FAI), la date et l’heure, les pages de
            renvoi/sortie et éventuellement le nombre de clics. Ceux-ci ne sont
            pas liés à des informations personnellement identifiables.
            L’information a pour objet d’analyser les tendances, d’administrer
            le site, de suivre les déplacements des utilisateurs sur le site Web
            et de recueillir des données démographiques. Notre politique de
            confidentialité a été créée avec l’aide du&nbsp;<a
                href="https://www.privacypolicygenerator.info/"
                >Générateur de Politique de Confidentialité</a
            >&nbsp;et du &nbsp;<a
                href="https://www.privacypolicyonline.com/privacy-policy-template/"
                >Modèle de Politique de Confidentialité</a
            >.
        </p>
        <h2 class="page-content__subtitle">Cookies et balises web</h2>
        <p>
            Comme tout autre site web, Joinsteer utilise des «cookies. » Ces
            cookies sont utilisés pour stocker des informations, y compris les
            préférences des visiteurs, et les pages du site Web que le visiteur
            a consultées ou visitées. L’information est utilisée pour optimiser
            l’expérience des utilisateurs en personnalisant le contenu de notre
            page Web en fonction du type de navigateur des visiteurs et/ou
            d’autres informations.
        </p>
        <p>
            Pour plus d’informations générales sur les cookies, veuillez
            lire&nbsp;&nbsp;<a
                href="https://www.cookieconsent.com/what-are-cookies/"
                >"Qu’est-ce que les Cookies"</a
            >.
        </p>
        <h2 class="page-content__subtitle">Google DoubleClick DART Cookie</h2>
        <p>
            Google est l’un des fournisseurs tiers sur notre site. Il utilise
            également des cookies, appelés cookies DART, pour diffuser des
            annonces aux visiteurs de notre site en fonction de leur visite sur
            www.website.com et d’autres sites sur Internet. Cependant, les
            visiteurs peuvent choisir de refuser l’utilisation des cookies DART
            en consultant la Politique de confidentialité du réseau publicitaire
            et de contenu Google à l’adresse suivante :<br />&nbsp;<a
                href="https://policies.google.com/technologies/ads"
                >https://policies.google.com/technologies/ads</a
            >
        </p>
        <h2 class="page-content__subtitle">
            Politiques de confidentialité des partenaires publicitaires
        </h2>
        <p>
            Vous pouvez consulter cette liste pour trouver la politique de
            confidentialité de chacun des partenaires publicitaires de
            Joinsteer.
        </p>
        <p>
            Les serveurs publicitaires tiers ou les réseaux publicitaires
            utilisent des technologies comme les cookies, JavaScript ou les
            Balises Web qui sont utilisés dans leurs publicités respectives et
            les liens qui apparaissent sur Joinsteer et qui sont envoyés
            directement au navigateur des utilisateurs. Ils reçoivent
            automatiquement votre adresse IP lorsque cela se produit. Ces
            technologies sont utilisées pour mesurer l’efficacité de leurs
            campagnes publicitaires et/ou pour personnaliser le contenu
            publicitaire que vous voyez sur les sites Web que vous visitez.
        </p>
        <p>
            Notez que Joinsteer n’a aucun accès ou contrôle sur ces cookies
            utilisés par des annonceurs tiers.
        </p>
        <h2 class="page-content__subtitle">
            Politiques de confidentialité des tiers
        </h2>
        <p>
            La politique de confidentialité de Joinsteer ne s’applique pas aux
            autres annonceurs ou sites Web. Par conséquent, nous vous
            conseillons de consulter les politiques de confidentialité
            respectives de ces serveurs publicitaires tiers pour des
            informations plus détaillées. Il peut inclure leurs pratiques et
            instructions sur la façon de se désinscrire de certaines options.
            Vous pouvez trouver une liste complète de ces politiques de
            confidentialité et leurs liens ici: Politique de Confidentialité
            Liens.
        </p>
        <p>
            Vous pouvez choisir de désactiver les cookies via les options de
            votre navigateur individuel. Pour en savoir plus sur la gestion des
            cookies avec des navigateurs Web spécifiques, vous pouvez les
            trouver sur les sites Web respectifs des navigateurs.
            <br />Qu’est-ce que les cookies?
        </p>
        <h2 class="page-content__subtitle">
            CCPA droits de confidentialité (ne pas vendre des renseignements
            personnels)
        </h2>
        <p>
            En vertu de la CCPA, entre autres droits, les consommateurs
            californiens ont le droit de :
        </p>
        <p>
            Demander à une entreprise qui recueille les données personnelles
            d’un consommateur de divulguer les catégories et les éléments précis
            de données personnelles qu’elle a recueillis sur les consommateurs.
        </p>
        <p>
            Demander à une entreprise de supprimer toute donnée personnelle sur
            le consommateur qu’elle a recueillie.
        </p>
        <p>
            Demander à une entreprise qui vend des données personnelles d’un
            consommateur de ne pas les vendre.
        </p>
        <p>
            Si vous faites une demande, nous avons un mois pour vous répondre.
            Si vous souhaitez exercer l’un de ces droits, veuillez nous
            contacter.
        </p>
        <h2 class="page-content__subtitle">
            RGPD droits de protection des données
        </h2>
        <p>
            Nous tenons à vous assurer que vous connaissez tous vos droits en
            matière de protection des données. Chaque utilisateur a droit à ce
            qui suit:
        </p>
        <p>
            Droit d’accès – Vous avez le droit de demander des copies de vos
            données personnelles. Nous pouvons vous facturer des frais modiques
            pour ce service.
        </p>
        <p>
            Droit de rectification – Vous avez le droit de nous demander de
            corriger toute information que vous jugez inexacte. Vous avez
            également le droit de nous demander de compléter les renseignements
            que vous jugez incomplets.
        </p>
        <p>
            Droit à l’effacement – Vous avez le droit de nous demander d’effacer
            vos données personnelles, sous certaines conditions.
        </p>
        <p>
            Le droit de limiter le traitement – Vous avez le droit de nous
            demander de limiter le traitement de vos données personnelles, sous
            certaines conditions.
        </p>
        <p>
            Droit d’opposition au traitement – Vous avez le droit de vous
            opposer au traitement de vos données personnelles, sous certaines
            conditions.
        </p>
        <p>
            Droit à la portabilité des données – Vous avez le droit de nous
            demander de transférer les données que nous avons collectées à une
            autre organisation, ou directement à vous, sous certaines
            conditions.
        </p>
        <p>
            Si vous faites une demande, nous avons un mois pour vous répondre.
            Si vous souhaitez exercer l’un de ces droits, veuillez nous
            contacter.
        </p>
        <h2 class="page-content__subtitle">Information des enfants</h2>
        <p>
            Une autre partie de notre priorité consiste à protéger les enfants
            lorsqu’ils utilisent internet. Nous encourageons les parents et les
            tuteurs à observer leurs activités en ligne, à y participer, à les
            surveiller et à les orienter.
        </p>
        <p>
            Joinsteer ne recueille pas sciemment de renseignements personnels
            identifiables auprès d’enfants de moins de 13 ans. Si vous pensez
            que votre enfant a fourni ce type d’information sur notre site Web,
            nous vous encourageons fortement à communiquer avec nous
            immédiatement et nous ferons de notre mieux pour supprimer
            rapidement ces informations de nos dossiers.
        </p>
        <p data-rte-preserve-empty="true"></p>
        <h2 class="page-content__subtitle">Vie Privée</h2>
        <p>
            Ce site web est hébergé par Squarespace. Squarespace collecte des
            informations personnelles lorsque vous visitez ce site web, incluant
            :
        </p>
        <ul class="page-content__list">
            <li>
                <p>Informations sur votre navigateur.</p>
            </li>
            <li>
                <p>Pages web visitées avant de visiter ce site web.</p>
            </li>
            <li><p>Adresse IP.</p></li>
        </ul>
        <p>
            Squarespace a besoin de ces informations afin d’opérer correctement
            ce site web, de protéger and d’améliorer ses plateformes et ses
            services. Squarespace analyse les donnée de façon cryptée et
            anonyme.
        </p>
        <p>
            Ce site web utilises des polices provenant des services Google et
            Adobe. Afin d’afficher correctement ce site web, les serveurs qui
            hébèrgent ces polices peuvent recevoir des données personneles
            incluant :
        </p>
        <ul class="page-content__list">
            <li>
                <p>Informations sur votre navigateur.</p>
            </li>
            <li><p>Adresse IP.</p></li>
        </ul>
        <p>
            Ce site web utilises des cookies et technologies similaires, qui
            sont de petits fichiers texte téléchargés par votre navigateur
            lorsque vous accédez à n’importe quel site web.
        </p>
        <ul class="page-content__list">
            <li>
                <p>
                    Ces fonction sont requises afin de donner la possibilité à
                    Squarespace de sécuriser ses plateformes et ses
                    utilisateurs.
                </p>
            </li>
            <li>
                <p>
                    Les cookies d’analyse et de performance sont utilisés sur ce
                    site web uniquement lorsque vous les autorisez (bannière
                    d’accord affichée lors de votre visite). Nous utilisons les
                    cookies d’analyse afin de connaître le traffic du site web.
                </p>
            </li>
        </ul>
        <p>
            Ces informations peuvent inclure des détails sur votre utilisation
            de ce site web, incluant :
        </p>
        <ul class="page-content__list">
            <li><p>Cliques</p></li>
            <li>
                <p>Liens Internes</p>
            </li>
            <li>
                <p>Pages Visitées</p>
            </li>
            <li><p>Scrolling</p></li>
            <li><p>Recherches</p></li>
            <li><p>Durées</p></li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
.page-content {
    color: $dark-font;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 8rem;
    &__subtitle {
        color: black;
        font-weight: 400;
        font-family: $bebas;
        font-size: 2.5rem;
        margin: 4rem 0 1rem 0;
    }
    &__title {
        color: black;
        font-weight: 400;
        font-family: $bebas;
        font-size: 3rem;
        margin-bottom: 4rem;
    }
    &__list {
        p {
            margin: 0;
        }
    }
}
</style>
