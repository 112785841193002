<script setup lang="ts">
import { Head } from "@inertiajs/vue3";
import CarVisualization from "@/Components/Home/CarVisualization/CarVisualization.vue";
import Hero from "@/Components/Home/Sections/Hero.vue";
import AccelerateSection from "@/Components/Home/Sections/AccelerateSection.vue";
import DreamSection from "@/Components/Home/Sections/DreamSection.vue";
import RequirementSection from "@/Components/Home/Sections/RequirementSection.vue";
import TechnologySection from "@/Components/Home/Sections/TechnologySection.vue";
import PassionSection from "@/Components/Home/Sections/PassionSection.vue";
import StoryTellingSection from "@/Components/Home/Sections/StoryTellingSection.vue";
import InfiniteHorizontalBanner from "@/Components/Home/InfiniteHorizontalBanner/InfiniteHorizontalBanner.vue";
import Press from "@/Components/Sections/Press/Press.vue";
import { Article, Review } from "@/types";
import ReviewList from "@/Components/Sections/Review/ReviewList.vue";
import { onBeforeUnmount, onMounted, provide } from "vue";
import { useClient } from "@/Composables/utils/useClient";
import { useGlobalEmitter } from "@/Composables/utils/useGlobalEmitter";
import { useI18n } from "vue-i18n";

defineProps<{
    articles: Array<Article>;
    customerReview?: Review;
    showVideo: boolean;
    reviewSchema: JSON;
}>();

let locomotive: any = null;

const { t } = useI18n();

const { device } = useClient();
provide("device", device.value);

const { emitter } = useGlobalEmitter();

// Modification de l'initialisation de Locomotive Scroll
const initLocomotiveScroll = async () => {
    if (typeof window !== "undefined") {
        document.documentElement.classList.add("homepage");
        emitter.emit("loading:progress", "start");
        try {
            const LocomotiveScroll = (await import('locomotive-scroll')).default;
            locomotive = new LocomotiveScroll();
        } catch (error) {
            console.error('Failed to initialize LocomotiveScroll:', error);
        }
    }
};

onMounted(() => {
    // Delay initialization to ensure we're on client side
    setTimeout(initLocomotiveScroll, 0);
});

onBeforeUnmount(() => {
    if (typeof window !== "undefined") {
        document.documentElement.classList.remove("homepage");
        if (locomotive) locomotive.destroy();
    }
});
</script>

<template>
    <Head>
        <title>{{ t("home.aboutUs") }}</title>
        <component :is="'script'" type="application/ld+json">
            {{ reviewSchema }}
        </component>
    </Head>

    <div class="home">
        <Hero />

        <DreamSection id="content-home" />
        <TechnologySection />
        <AccelerateSection />

        <!-- TODO : Voir avec le client pour le titre de la section-->
        <ReviewList :review="customerReview" item-seo="Joinsteer" />
        <div class="home__img">
            <picture>
                <source
                    media="(max-width: 640px)"
                    srcset="
                        ../../../resources/images/home/joinsteer-garage-mobile.webp
                    "
                />
                <img
                    src="../../../resources/images/home/joinsteer-garage-desktop.webp"
                    alt=""
                    loading="lazy"
                />
            </picture>
        </div>
        <InfiniteHorizontalBanner />
        <CarVisualization />
        <RequirementSection />
        <PassionSection />
        <Press :items="articles" />
        <StoryTellingSection />
    </div>
</template>
<style>
html.lenis {
    height: auto;
}
.lenis.lenis-smooth {
    scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}
.lenis.lenis-stopped {
    overflow: hidden;
}
.lenis.lenis-scrolling iframe {
    pointer-events: none;
}
img {
    max-width: 100%;
    vertical-align: middle;
}
</style>
<style lang="scss" scoped>
.home {
    --description-line-height: 1.5;
    --grid-columns: 1;
    --hp-y-space: 2rem;

    @include medium-up {
        --grid-columns: 1fr #{clampGenerator(3, 10)} 1fr;
        --hp-y-space: #{clampGenerator(2.5, 5, 40, 120)};
    }

    &__img {
        * {
            width: 100%;
            display: block;
        }
    }
    &__button {
        a {
            display: inline-block;
        }
    }
}

@include medium-up {
    .mobile-only {
        display: none;
    }
}
</style>
