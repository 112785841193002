<script lang="ts">
import { createWidgetMixin } from "vue-instantsearch/vue3/es";
import { connectHits } from "instantsearch.js/es/connectors";
import VehicleCard from "../Cards/VehicleCard.vue";
import Pagination from "@/Components/Algolia/Pagination.vue";

export default {
    components: { Pagination, VehicleCard },
    mixins: [createWidgetMixin({ connector: connectHits })],
};
</script>

<template>
    <div v-if="state" class="marketplace-content__hits-wrapper">
        <template v-for="hit in state.hits">
            <slot name="item" :item="hit">
                <VehicleCard :item="hit"></VehicleCard>
            </slot>
        </template>
    </div>
    <Pagination />
</template>

<style lang="scss">
.marketplace-content__hits-wrapper {
    list-style: none;
    padding: 0;
    margin: 1rem 0 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    flex-grow: 1;
}

@media (min-width: 400px) {
    .marketplace-content__hits-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
}

@include medium-up {
    .marketplace-content__hits-wrapper {
        justify-content: center;
        margin-top: 0;
    }
}
</style>
