<script lang="ts" setup>
import { PropType, ref } from "vue";
import Cross from "@/Components/Icons/Cross.vue";
import CloseAccount from "@/Components/Icons/CloseAccount.vue";
import OpenAccount from "@/Components/Icons/OpenAccount.vue";
import CloseLarge from "@/Components/Icons/CloseLarge.vue";
import MinusAlgolia from "@/Components/Icons/MinusAlgolia.vue";
import PlusAlgolia from "@/Components/Icons/PlusAlgolia.vue";
import {
    DEFAULT_ACCORDION_BORDER_COLOR,
    DEFAULT_ACCORDION_ICON_COLOR,
    DEFAULT_ACCORDION_ICON_COLOR_CLOSED,
    DEFAULT_ACCORDION_TITLE_COLOR,
} from "@/constants/components";
type IconType = "circled" | "algolia" | "account" | "cross";

const props = defineProps({
    open: {
        type: Boolean,
        default: false,
    },
    large: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String as PropType<IconType>,
        default: "algolia",
    },
    iconColor: {
        type: String,
        required: false,
        default: DEFAULT_ACCORDION_ICON_COLOR,
    },
    iconColorClosed: {
        type: String,
        required: false,
        default: DEFAULT_ACCORDION_ICON_COLOR_CLOSED,
    },
    borderColor: {
        type: String,
        required: false,
        default: DEFAULT_ACCORDION_BORDER_COLOR,
    },
    titleColor: {
        type: String,
        required: false,
        default: DEFAULT_ACCORDION_TITLE_COLOR,
    },
    titleFontWeight: {
        type: Number,
        required: false,
        default: 500,
    },
});

const isOpen = ref(props.open);

const toggleOpen = () => {
    isOpen.value = !isOpen.value;
};

const forceOpen = () => {
    isOpen.value = true;
};

defineExpose({
    forceOpen,
});

let closeIconName, openIconName;
switch (props.icon) {
    case "algolia":
        closeIconName = MinusAlgolia;
        openIconName = PlusAlgolia;
        break;
    case "account":
        closeIconName = CloseAccount;
        openIconName = OpenAccount;
        break;
    case "cross":
        closeIconName = CloseLarge;
        openIconName = Cross;
        break;
    default:
        closeIconName = MinusAlgolia;
        openIconName = PlusAlgolia;
        break;
}
</script>

<template>
    <div :class="`accordion accordion--${icon}`">
        <button
            class="accordion__header"
            :class="{ 'accordion__header--large': large }"
            @click="toggleOpen"
        >
            <slot name="header" />
            <div
                :class="`accordion__header__toggle accordion__header__toggle--${icon}`"
            >
                <!--                <Transition name="accordion-icon">-->
                <component
                    :is="closeIconName"
                    :class="`accordion__header__toggle--icon --${icon}`"
                    v-show="isOpen"
                    :color="iconColorClosed"
                />
                <!--                </Transition>-->
                <!--                <Transition name="accordion-icon">-->

                <component
                    :is="openIconName"
                    :class="`accordion__header__toggle--icon --${icon}`"
                    v-show="!isOpen"
                    :color="iconColor"
                    width="24"
                    height="24"
                />
                <!--                </Transition>-->
            </div>
        </button>
        <!--Transitions : à voir si on le remet en fonction du retour client-->
        <!--        <Transition name="accordion-body">
            <div class="accordion__body" v-show="isOpen">
                <slot />
            </div>
        </Transition>-->
        <div class="accordion__body" v-show="isOpen">
            <slot />
        </div>
    </div>
</template>

<style lang="scss">
.accordion {
    &--account {
        padding: 1.5rem;
        border: 1px solid $light-bg;
        border-radius: 4px;

        .accordion__header {
            padding-bottom: 0;

            &:after {
                display: none;
            }
        }
    }

    @include medium-up {
        &--account {
            padding: 1.5rem;
        }
    }

    &__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.25rem;
        font-weight: v-bind("titleFontWeight");
        line-height: 1.5rem;
        border: 0;
        background: transparent;
        width: 100%;
        cursor: pointer;
        text-align: left;
        padding: 0 0 1.25rem 0;
        color: v-bind("titleColor");

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            border-bottom: 1px solid v-bind("borderColor");
        }

        &--large {
            font-size: 1.5rem;
        }

        @media (max-width: $breakpoint-m) {
            font-size: 1rem;
        }

        &__toggle {
            margin-left: 1rem;
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 1.5rem;

            &--account {
                background: $dark-font;
                width: 40px;
                height: 40px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 0;

                img {
                    position: static;
                    width: 24px;
                    height: 24px;
                }
            }

            &--icon {
                position: absolute;
                right: 0;

                &.--account {
                    right: auto;
                }
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__body {
        padding-top: 1.5rem;

        .product-accordions & {
            padding: 2rem 0;
        }
    }
}

// Transitions : à voir si on ajoute l'animation en fonction du retour client
/*.accordion-body-enter-active,
.accordion-body-leave-active {
    transition:
        opacity $transition-time ease,
        max-height $transition-time ease,
        padding-top $transition-time ease;
}

.accordion-body-enter-from,
.accordion-body-leave-to {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
}

.accordion-body-enter-to,
.accordion-body-leave-from {
    opacity: 1;
    max-height: 1000px;
    padding-top: 1.5rem;
}

.accordion-icon-enter-active,
.accordion-icon-leave-active {
    transition: opacity $transition-time ease;
}

.accordion-icon-enter-from,
.accordion-icon-leave-to {
    opacity: 0;
}

.accordion-icon-enter-to,
.accordion-icon-leave-from {
    opacity: 1;
}*/
</style>
