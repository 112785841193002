<script lang="ts" setup>
import AccordionList from "@/Components/Accordions/AccordionList.vue";
import TitleTabulatedMultiLine from "@/Components/Typography/TitleTabulatedMultiLine.vue";
import { useI18n } from "vue-i18n";
import { AccordionContent, VehicleMake } from "@/types";
import { getAccordionContent } from "@/helpers/landing";

const { t } = useI18n();

const props = defineProps<{
    vehicleMake: VehicleMake;
    itemSeo: string;
}>();

const accordionKeys: string[] = [
    "premium",
    "handpicked",
    "highQualityStandard",
    "perfectPartner",
];

const accordionItems: AccordionContent[] = getAccordionContent(
    "travelCompanion",
    accordionKeys,
    props.itemSeo,
    t,
);
</script>
<template>
    <section class="travelCompanion">
        <div class="travelCompanion__wrapper container">
            <div class="travelCompanion__content">
                <TitleTabulatedMultiLine
                    is-bold
                    data-scroll
                    data-scroll-offset="200, 200"
                    :labels="[
                        {
                            isInterpolated: false,
                            content: t(
                                'sections.travelCompanion.titleTabulate.one',
                            ),
                            gap: '0',
                        },
                        {
                            isInterpolated: false,
                            content: t(
                                'sections.travelCompanion.titleTabulate.two',
                            ),
                            gap: '2.5ch',
                        },
                        {
                            isInterpolated: false,
                            content: t(
                                'sections.travelCompanion.titleTabulate.three',
                            ),
                            gap: '5ch',
                        },
                    ]"
                    :level="2"
                />

                <AccordionList
                    :items="accordionItems"
                    icon-color="#E8FF2E"
                    icon-color-closed="#809EA3"
                    border-color="white"
                    text-color="white"
                    :text-opacity="0.87"
                    title-color="white"
                />
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
.travelCompanion {
    background-color: $dark-font;
    color: white;

    &__wrapper {
        padding: clampGenerator(2, 7) 0;
    }

    &__content {
        .accordions {
            margin-top: clampGenerator(2, 5);
        }
    }

    @media (min-width: $breakpoint-l) {
        &__wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &:after {
                content: "";
                width: calc(100% - 38rem);
                max-width: 35rem;
                aspect-ratio: 651 / 630;
                background-image: url("../../../images/icons/travel-partner.svg");
                background-size: contain;
            }
        }

        &__content {
            width: 38rem;
        }
    }
}

h2 {
    margin: 0;
}
</style>
