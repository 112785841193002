<script lang="ts" setup>
import TitleTabulatedMultiLine from "@/Components/Typography/TitleTabulatedMultiLine.vue";
import { useI18n } from "vue-i18n";
import { AccordionContent, VehicleMake } from "@/types";
import AccordionList from "@/Components/Accordions/AccordionList.vue";
import { getAccordionContent } from "@/helpers/landing";

const { t } = useI18n();

const props = defineProps<{
    vehicleMake: VehicleMake;
    itemSeo: string;
}>();

const accordionKeys: string[] = [
    "qualityAssurance",
    "maintenance",
    "insurance",
];

const accordionItems: AccordionContent[] = getAccordionContent(
    "noMoreConcession",
    accordionKeys,
    props.itemSeo,
    t,
);
</script>
<template>
    <section class="noMoreConcessions container">
        <div class="noMoreConcessions__intro">
            <TitleTabulatedMultiLine
                color="#809EA3"
                is-bold
                data-scroll
                data-scroll-offset="200, 200"
                :labels="[
                    {
                        isInterpolated: false,
                        content: t(
                            'sections.noMoreConcession.titleTabulate.one',
                        ),
                        gap: '0',
                    },
                    {
                        isInterpolated: false,
                        content: t(
                            'sections.noMoreConcession.titleTabulate.two',
                        ),
                        gap: '2.5ch',
                    },
                    {
                        isInterpolated: false,
                        content: t(
                            'sections.noMoreConcession.titleTabulate.three',
                            { make: itemSeo },
                        ),
                        gap: '5ch',
                    },
                ]"
                :level="2"
            />

            <p class="noMoreConcessions__intro__subtitle">
                {{
                    t("sections.noMoreConcession.subtitle", {
                        make: itemSeo,
                    })
                }}
            </p>

            <p class="noMoreConcessions__intro__description">
                {{
                    t("sections.noMoreConcession.description", {
                        make: itemSeo,
                    })
                }}
            </p>
        </div>

        <div class="noMoreConcessions__accordions">
            <AccordionList
                :items="accordionItems"
                icon-color-closed="#AAB2B2"
                text-color="#333333"
                :text-opacity="1"
            />
        </div>
    </section>
</template>

<style lang="scss" scoped>
.noMoreConcessions {
    &__intro {
        h2 {
            margin: 0;
        }

        &__subtitle {
            margin: clampGenerator(1, 2.125) 0;
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.5rem;
        }

        &__description {
            color: #809ea3;
            line-height: 1.5rem;
        }
    }

    @media (max-width: 76rem) {
        &__accordions {
            margin-top: 2rem;
        }
    }

    @media (min-width: 76rem) {
        display: flex;
        justify-content: space-between;
        gap: 1.2rem;

        &__intro {
            &__subtitle,
            &__description {
                max-width: 34rem;
            }
        }

        &__accordions {
            width: 35rem;
            flex-shrink: 0;
        }
    }
}
</style>
