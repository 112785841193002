import { getCurrentInstance } from "vue";

export const useGlobalEmitter = () => {
    const internalInstance = getCurrentInstance();
    const emitter =
        internalInstance?.appContext.config.globalProperties.$emitter;
    return {
        emitter,
    };
};
