<template>
    <h3 class="subtitle">
        <slot></slot>
    </h3>
</template>
<style lang="scss" scoped>
.subtitle {
    font-family: $bebas;
    color: $light-blue;
    font-size: clampGenerator(1.75, 2.75, 40, 120);
    font-weight: 600;
    text-transform: uppercase;
}
</style>
