<script setup lang="ts">
import { VehicleAlgoliaRecord } from "../../types";
import { useI18n } from "vue-i18n";
import "vue3-carousel/dist/carousel.css";
import { ref } from "vue";
import Button from "@/Components/Buttons/Button.vue";
import VehicleInfo from "@/Components/VehicleInfo.vue";
import CarouselCard from "@/Components/Cards/CarouselCard.vue";

const { t } = useI18n();
const isMouseover = ref(false);

// const isFavorite = ref(false);

defineProps<{ item: VehicleAlgoliaRecord }>();

// TODO: ajouter fonctionnalité ajouter aux favoris
// const addFavorite = () => {
//     isFavorite.value = !isFavorite.value;
//     console.log("add favorite");
// };
</script>

<template>
    <div
        class="vehicle-card"
        @mouseenter="isMouseover = true"
        @mouseleave="isMouseover = false"
        data-link="parent"
    >
        <div class="vehicle-card__header">
            <div class="vehicle-card__header__top">
                <h2
                    class="vehicle-card__title vehicle-card__text vehicle-card__text--light"
                >
                    {{ item.make }}
                </h2>

                <div class="vehicle-card__header__top__price">
                    <p>
                        <span class="font-weight-semibold"
                            >{{ $filters.currency(item.monthly_price, true) }}
                        </span>
                        <span class="month">{{ t("vehicle.perMonth") }}</span>
                    </p>
                </div>
            </div>
            <div class="vehicle-card__header__bottom">
                <h3 class="vehicle-card__subtitle vehicle-card__text">
                    <a
                        :href="
                            route('vehicles.show', {
                                slug: item.slug,
                                vehicle: item,
                            })
                        "
                        data-link="item"
                        >{{ item.model_card }} {{ item.specification_card }}</a
                    >
                </h3>
            </div>
        </div>

        <div class="vehicle-card__slider">
            <CarouselCard
                :url="
                    route('vehicles.show', { slug: item.slug, vehicle: item })
                "
                :vehicle="item"
                :is-mouseover="isMouseover"
                v-if="item.photos"
            />
        </div>

        <div class="vehicle-card__footer">
            <div class="vehicle-card__footer__info">
                <VehicleInfo
                    :value="item.mileage ? $filters.number(item.mileage) : '-'"
                    unit="km"
                />
                <VehicleInfo :value="item.power" unit="cv" />
                <VehicleInfo :value="item.registration_at" />
            </div>

            <div class="vehicle-card__footer__action">
                <Button
                    as="a"
                    :href="
                        route('vehicles.show', {
                            slug: item.slug,
                            vehicle: item,
                        })
                    "
                    >{{ t("vehicle.choose") }}</Button
                >
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.vehicle-card {
    $self: &;

    display: grid;
    row-gap: 0.5rem;
    padding: 1rem 0.5rem;

    background-color: $grey-card;
    border-radius: 0.25rem;

    &__text {
        color: $dark-font;

        &--light {
            opacity: 0.5;
        }
    }

    &__title,
    &__subtitle {
        margin: 0;
    }

    &__title {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    &__subtitle {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        font-weight: 500;
        font-size: 1rem;
    }

    &__header {
        overflow: hidden;

        p {
            margin: 0;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2px;
            column-gap: 0.125rem;

            &__price {
                flex-shrink: 0;
                font-size: 0.875rem;

                .month {
                    color: rgba($dark-font, 0.5);
                    font-weight: 400;
                }
            }
        }
    }

    &__footer {
        margin-top: 0.125rem;
        height: 40px;

        &__info {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            height: 100%;
        }

        &__action {
            display: none;
        }
    }

    // HOVER STYLES
    @media (hover: hover) {
        &:hover {
            background-color: $dark-font;
            color: #ffffff;

            #{$self}__header {
                &__top {
                    &__price {
                        .month {
                            color: rgba(white, 0.5);
                        }
                    }
                }
            }

            #{$self}__text {
                color: #ffffff;
            }

            #{$self}__footer {
                &__info {
                    display: none;
                }

                &__action {
                    display: block;
                }
            }
        }
    }
}
</style>
