<script setup lang="ts">
import Simulator from "@/Layouts/Simulator.vue";
import { Simulation, Vehicle, VehiclePreview } from "@/types";
import { ref } from "vue";
import { router } from "@inertiajs/vue3";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";

const progress = ref<number>(0);

const { t } = useI18n();

defineProps<{
    simulation: Simulation;
    vehicle: Vehicle;
    vehiclePreview: VehiclePreview;
}>();

onMounted(() => {
    const interval = setInterval(() => {
        progress.value += 25;
        if (progress.value >= 125) {
            clearInterval(interval);
            router.visit(route("user.financingRequests"));
        }
    }, 1750);
});
</script>

<template>
    <Simulator
        :show-message="false"
        :vehicle-preview="vehiclePreview"
        :vehicle="vehicle"
        ref="simulator"
        :dark="true"
        :current-tunnel-step="4"
    >
        <div class="thanks">
            <div>
                <img
                    class="thanks__image-preview"
                    :src="vehiclePreview.image_url"
                    :alt="vehicle.display_name2"
                />
            </div>

            <p class="thanks__first">
                {{
                    t("tunnel.thanksName", {
                        name: $page.props.auth.user.firstname,
                    })
                }}
            </p>

            <p class="thanks__second">
                {{ t("tunnel.joinsteerTakeOver") }}
            </p>

            <p class="thanks__informations">
                {{ t("tunnel.informationsVerifications") }} <br />
                {{ t("tunnel.before48h") }}
            </p>

            <div class="loader">
                <svg
                    width="76"
                    height="81"
                    viewBox="0 0 76 81"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M64.5778 58.7374C61.7155 66.1558 56.2884 72.3035 49.28 76.0637C42.2678 79.8259 34.1395 80.9475 26.3719 79.2263L29.4895 74.2633C35.2366 75.1102 41.105 74.136 46.2705 71.4769C51.4382 68.8167 55.6422 64.6042 58.2919 59.4317L64.5778 58.7374Z"
                        stroke="white"
                        stroke-width="0.5"
                        :fill="progress >= 25 ? 'white' : 'transparent'"
                    />
                    <path
                        d="M8.1801 57.5628L8.1371 57.4628L1.4541 56.4888L1.5471 56.7888C3.10032 61.6538 5.73539 66.1039 9.25451 69.8049C12.7736 73.5058 17.0854 76.3616 21.8661 78.1578L22.0161 78.2138L25.2681 73.0348L25.0291 72.9568C21.2868 71.7387 17.8478 69.7355 14.9421 67.0812C12.0364 64.427 9.731 61.1828 8.1801 57.5658"
                        stroke="white"
                        stroke-width="0.5"
                        :fill="progress >= 50 ? 'white' : 'transparent'"
                    />
                    <path
                        d="M5.85158 49.5351L5.71503 49.5505L5.77109 50.0473L5.90757 50.0319C6.02642 51.0088 6.1971 51.9788 6.41891 52.9377L0.689507 52.1029C0.592084 51.5013 0.514704 50.9398 0.453537 50.3874L0.453503 50.3871C-0.275184 43.878 0.944204 37.2998 3.9561 31.4853L7.44454 37.0803C5.9591 41.0542 5.41432 45.3171 5.85158 49.5351Z"
                        stroke="white"
                        stroke-width="0.5"
                        :fill="progress >= 75 ? 'white' : 'transparent'"
                    />
                    <path
                        d="M13.1373 27.8225L13.1371 27.8222L13.1285 27.8315C11.8556 29.2083 10.7236 30.709 9.74973 32.3112L6.57988 27.2252C9.11197 23.7182 12.3031 20.7383 15.9746 18.4519L16.6979 24.3409L13.1373 27.8225Z"
                        stroke="white"
                        stroke-width="0.5"
                        :fill="progress >= 100 ? 'white' : 'transparent'"
                    />
                    <path
                        d="M74.4199 45.991C73.899 44.8201 73.0868 43.8021 72.0609 43.034L64.1679 38.576L61.0419 39.469L57.1219 25.769C56.3071 23.6841 54.7979 21.9442 52.8489 20.843L17.2319 0L20.3099 8.516C20.6869 9.7061 21.4303 10.7466 22.4339 11.489L40.9819 24.324L24.1679 17.259L24.5939 18.765C25.5799 22.255 25.9319 23.5 25.9379 23.521C26.1153 24.1016 26.3854 24.6497 26.7379 25.144C26.9021 25.3735 27.0837 25.5902 27.2809 25.792L41.6809 35.766L30.2599 30.966L32.3159 38.346C32.747 39.5958 33.5004 40.7097 34.4999 41.575L48.6699 55.875L48.8879 55.512C50.3879 53.012 53.8699 50.679 59.2469 48.564C61.4149 47.6869 63.6723 47.0498 65.9789 46.664C69.5929 46.103 72.5479 46.489 74.7639 47.815L74.9899 47.49L75.2099 47.362L74.5389 46.214L74.4199 45.991ZM65.8799 46.014C63.5261 46.4066 61.2224 47.0558 59.0099 47.95C53.7449 50.021 50.2309 52.33 48.5539 54.817L34.9639 41.109C34.048 40.3225 33.354 39.31 32.9509 38.172L31.2599 32.1L47.2439 38.817L27.7359 25.317C27.57 25.1455 27.4169 24.962 27.2779 24.768C26.966 24.3327 26.7266 23.8498 26.5689 23.338C26.5489 23.268 25.6689 20.138 25.2279 18.591L25.1729 18.398L46.5419 27.377L22.8329 10.964C21.9391 10.302 21.2768 9.37505 20.9399 8.315L18.4679 1.485L52.5209 21.413C54.3321 22.4341 55.737 24.0469 56.4999 25.981L60.5909 40.281L64.0839 39.281L71.7299 43.599C72.646 44.3107 73.3713 45.2383 73.8409 46.299L74.0749 46.715C71.4646 45.7031 68.619 45.4602 65.8749 46.015"
                        fill="white"
                    />
                </svg>
            </div>

            <div class="thanks__footer">
                {{ t("tunnel.finalAgreement") }}
            </div>
        </div>
    </Simulator>
</template>

<style lang="scss" scoped>
.loader {
    margin-top: 1.5rem;
}

.thanks {
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &__image-preview {
        max-width: 100%;
        max-height: 220px;
        margin: 2rem 0;

        @include medium-down {
            margin: 0 0 1rem 0;
            max-height: 140px;
        }
    }

    &__first {
        color: $primary;
        font-weight: 600;
        font-size: 2rem;
        padding: 0;
        margin: 0;

        @include medium-down {
            font-size: 1.5rem;
        }
    }

    &__second {
        font-weight: 600;
        font-size: 2rem;
        padding: 0;
        margin: 0.5rem 0 0 0;

        @include medium-down {
            font-size: 1.5rem;
        }
    }

    &__informations {
        text-align: center;
        line-height: 1.5rem;
        margin-top: 1rem;
        opacity: 0.8;

        @include medium-down {
            font-size: 0.875rem;

            br {
                display: none;
            }
        }
    }

    &__simulations-link {
        display: inline-block;
        margin-top: 1.5rem;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__footer {
        font-size: 1rem;
        font-weight: 300;
        opacity: 0.8;
        line-height: 1.5rem;
        text-align: center;

        @media (min-width: $breakpoint-m) and (min-height: 840px) {
            position: absolute;
            bottom: 2rem;
        }
    }
}
</style>
