<script setup lang="ts">
import { useI18n } from "vue-i18n";

import SavedSearch from "@/Components/SavedSearch.vue";
import SortBy from "@/Components/Algolia/SortBy.vue";
import Filter from "@/Components/Icons/Filter.vue";

const { t } = useI18n();

const emit = defineEmits(["toggle"]);

defineProps<{ indexName: string; reverseColor: boolean }>();
</script>

<template>
    <div class="hits-action">
        <div class="hits-action__save">
            <ais-stats>
                <template #default="{ nbHits }">
                    <p class="hits-action__stats">
                        {{
                            t("nbVehicleHit", {
                                nbHits: $filters.number(nbHits),
                            })
                        }}
                    </p>
                </template>
            </ais-stats>

            <SavedSearch :reverse-color="reverseColor" />
        </div>

        <div class="hits-action__sort-by">
            <SortBy
                class="hits-action__sort--desktop"
                :index-name="indexName"
                :reverse-color="reverseColor"
                variante="primary"
            />
            <button
                class="hits-action__sort--mobile hits-sort-by__button"
                :class="{ 'reverse-color': reverseColor }"
                @click="emit('toggle')"
                :aria-label="t('toggleFilter')"
            >
                <Filter />
                {{ t("filter") }}
                <ais-stats>
                    <template #default="{ nbHits }">
                        <span class="hits-sort-by__button__stats">
                            ({{ $filters.number(nbHits) }})
                        </span>
                    </template>
                </ais-stats>
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.hits-action {
    background-color: $dark-bg;
    padding: 1rem;
    color: #fff;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 4px;

    &__stats {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.2;
        margin: 0 0 0.25rem;
    }
    &__sort {
        &--desktop {
            display: block;
        }
        &--mobile {
            display: none;
        }
        @media (max-width: 960px) {
            &--desktop {
                display: none;
            }
            &--mobile {
                display: block;
                svg {
                    margin-right: 0.25rem;
                }
                &.reverse-color {
                    background: $primary;
                    color: $dark-bg;
                    svg {
                        color: $dark-bg;
                    }
                }
                > .ais-Stats > .hits-sort-by__button__stats {
                    margin-left: 0.25rem;
                }
            }
        }
    }
}

.hits-sort-by {
    &__button {
        background: transparent;
        border: none;
        cursor: pointer;
        line-height: 1;
        padding: 1rem 0 1rem 1rem;
        transition: color $transition-time;
        border-radius: 4px;

        svg {
            color: $primary;
        }

        span,
        img,
        svg {
            vertical-align: middle;
        }
        span {
            margin-right: 0.25rem;
        }

        &:hover {
            color: $primary;
        }

        &__icon {
            margin-right: 0.5rem;
        }

        &__stats {
            display: none;
        }
    }

    &__options {
        position: absolute;
        top: 100%;
        right: 0;
        margin: 0.25rem 0 0;
        padding: 0 1rem;
        background-color: $dark-bg;
        border-radius: 4px;
        color: #fff;
        list-style: none;
        z-index: 70;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition:
            opacity $transition-time,
            max-height $transition-time,
            padding $transition-time;

        li:first-child .hits-sort-by__option {
            border-top: none;
            padding-top: 0;
        }

        li:last-child .hits-sort-by__option {
            padding-bottom: 0;
        }

        &--show {
            max-height: 500px;
            padding: 1rem;
            opacity: 1;
        }
    }
    &__option {
        background: transparent;
        border: none;
        line-height: 1;
        padding: 0.75rem 0;
        width: 100%;
        text-align: left;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        cursor: pointer;

        &.is-active,
        &:hover,
        &:focus {
            color: $primary;
        }
        &.is-active {
            cursor: not-allowed;
        }
    }
}

@media (min-width: 960px) {
    .hits-action {
        position: sticky;
        top: 5.65rem;
        z-index: 3;
    }
}

@media (max-width: 960px) {
    .hits-action {
        position: fixed;
        padding: 0;
        bottom: 0;
        left: 3rem;
        right: 3rem;
        display: grid;
        grid-template-columns: 4fr 1fr;
        gap: 0.5rem;
        background: transparent;
        z-index: 50;

        &__save {
            order: 2;
        }

        &__stats {
            display: none;
        }
    }

    .hits-sort-by {
        &__button {
            background: $dark-bg;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.75rem 0;
            &.reverse-color {
                background: $primary;
                color: $dark-bg;
                svg {
                    color: $dark-bg;
                }
            }

            &__arrow {
                display: none;
            }

            &__stats {
                display: inline-block;
                opacity: 0.6;
            }
        }

        &__options {
            bottom: 100%;
            top: auto;
            left: 0;
            right: 0;
            margin: 0 0 0.25rem 0;
        }
    }
}
</style>
