import { onBeforeUnmount, onMounted, ref } from "vue";
import { isClientSide, isMobile } from "@/helpers/client";
import { throttle } from "lodash-es";

export enum Device {
    mobile = "mobile",
    desktop = "desktop",
}

export const useClient = () => {
    if (!isClientSide()) {
        return { device: ref<Device>(Device.mobile) };
    }

    const device = ref<Device>(isMobile() ? Device.mobile : Device.desktop);

    const handleResize = throttle(() => {
        device.value = isMobile() ? Device.mobile : Device.desktop;
    }, 500);

    onMounted(() => {
        window.addEventListener("resize", handleResize);
    });

    onBeforeUnmount(() => {
        window.removeEventListener("resize", handleResize);
    });

    return {
        device,
    };
};
