<script setup lang="ts">
import Sort from "@/Components/Icons/Sort.vue";
import { useI18n } from "vue-i18n";
import { useToggle } from "@/Composables/utils/useToggle";
import { PropType } from "vue";
import Checkbox from "@/Components/Forms/Input/Checkbox.vue";
import ArrowDown from "@/Components/Icons/ArrowDown.vue";

type VarianteType = "primary" | "secondary";

const { t } = useI18n();

const props = defineProps({
    indexName: {
        type: String,
        default: null,
    },
    reverseColor: {
        type: Boolean,
        default: false,
    },
    variante: {
        type: String as PropType<VarianteType>,
        default: "primary",
    },
});

const { show: showSortOptions, toggle: toggleOptions } = useToggle({
    defaultValue: false,
});

const sortOptions = [
    {
        value: props.indexName,
        label: t("sorts.default"),
    },
    {
        value: `${props.indexName}_price_asc`,
        label: t("sorts.priceAsc"),
    },
    {
        value: `${props.indexName}_price_desc`,
        label: t("sorts.priceDesc"),
    },
    {
        value: `${props.indexName}_mileage_asc`,
        label: t("sorts.mileageAsc"),
    },
    {
        value: `${props.indexName}_mileage_desc`,
        label: t("sorts.mileageDesc"),
    },
    {
        value: `${props.indexName}_power_asc`,
        label: t("sorts.powerAsc"),
    },
    {
        value: `${props.indexName}_power_desc`,
        label: t("sorts.powerDesc"),
    },
    {
        value: `${props.indexName}_year_asc`,
        label: t("sorts.yearAsc"),
    },
    {
        value: `${props.indexName}_year_desc`,
        label: t("sorts.yearDesc"),
    },
];
</script>

<template>
    <ais-sort-by
        :items="sortOptions"
        :class-names="{
            'ais-SortBy': 'hits-sort-by',
        }"
    >
        <template #default="{ items, currentRefinement, refine }">
            <button
                v-if="variante === 'primary'"
                class="hits-sort-by__button"
                :class="{
                    'reverse-color': reverseColor,
                }"
                @click.stop="toggleOptions"
            >
                <span>
                    <Sort width="1rem" class="hits-sort-by__button__icon" />
                    <span>{{ t("sortBy") }}</span>
                </span>
                <ArrowDown
                    width="1rem"
                    height="1rem"
                    color="white"
                    class="hits-sort-by__button__arrow"
                />

                <ais-stats v-if="variante === 'primary'">
                    <template #default="{ nbHits }">
                        <span class="hits-sort-by__button__stats">
                            ({{ $filters.number(nbHits) }})
                        </span>
                    </template>
                </ais-stats>
            </button>
            <ul
                class="hits-sort-by__options"
                :class="{
                    'hits-sort-by__options--show':
                        showSortOptions || variante === 'secondary',
                    secondary: variante === 'secondary',
                }"
            >
                <li v-for="item in items" :key="item.value" :value="item.value">
                    <button
                        v-if="variante === 'primary'"
                        class="hits-sort-by__option"
                        :class="{
                            'is-active': item.value === currentRefinement,
                        }"
                        :disabled="item.value === currentRefinement"
                        @click.prevent="
                            () => {
                                refine(item.value);
                                showSortOptions = false;
                            }
                        "
                    >
                        {{ item.label }}
                    </button>

                    <div
                        v-else
                        class="hits-sort-by__option"
                        :class="{
                            secondary: variante === 'secondary',
                        }"
                    >
                        {{ item.label }}
                        <Checkbox
                            :checked="item.value === currentRefinement"
                            @change.prevent="
                                (event) => {
                                    refine(event.target.value);
                                    showSortOptions = false;
                                }
                            "
                            type="checkbox"
                            :value="item.value"
                        />
                    </div>
                </li>
            </ul>
        </template>
    </ais-sort-by>
</template>

<style scoped lang="scss">
.hits-sort-by {
    &__button {
        background: transparent;
        border: none;
        cursor: pointer;
        line-height: 1;
        padding: 1rem 0 1rem 1rem;
        transition: color $transition-time;
        border-radius: 4px;

        svg:first-child {
            color: $primary;
        }

        &__arrow {
            color: white;
        }

        span,
        img,
        svg {
            vertical-align: middle;
        }
        span {
            margin-right: 0.25rem;
        }

        &:hover {
            color: $primary;
            .hits-sort-by__button__arrow {
                color: $primary;
            }
        }

        &__icon {
            margin-right: 0.5rem;
        }

        &__stats {
            display: none;
        }
    }

    &__options {
        position: absolute;
        top: 100%;
        right: 0;
        margin: 0.25rem 0 0;
        padding: 0 1rem;
        background-color: $dark-bg;
        border-radius: 4px;
        color: #fff;
        list-style: none;
        z-index: 70;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition:
            opacity $transition-time,
            max-height $transition-time,
            padding $transition-time;

        li:first-child .hits-sort-by__option {
            border-top: none;
            padding-top: 0;
        }

        li:last-child .hits-sort-by__option {
            padding-bottom: 0;
        }

        &--show {
            max-height: 500px;
            padding: 1rem;
            opacity: 1;
        }
        &.secondary {
            position: relative;
            background-color: transparent;
            .hits-sort-by__option {
                &.secondary {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: black;
                }
            }
        }
    }
    &__option {
        background: transparent;
        border: none;
        line-height: 1;
        padding: 0.75rem 0;
        width: 100%;
        text-align: left;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        cursor: pointer;

        &.is-active,
        &:hover,
        &:focus {
            color: $primary;
        }
        &.is-active {
            cursor: not-allowed;
        }
    }
}

@media (max-width: $breakpoint-m) {
    .hits-sort-by {
        &__button {
            background: $dark-bg;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 0;
            &.reverse-color {
                background: $primary;
                color: $dark-bg;
                svg {
                    color: $dark-bg;
                }
            }

            &__arrow {
                display: none;
            }

            &__stats {
                display: inline-block;
                opacity: 0.6;
            }
        }

        &__options {
            bottom: 100%;
            top: auto;
            left: 0;
            right: 0;
            margin: 0 0 0.25rem 0;
        }
    }
}
</style>
