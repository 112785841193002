<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{ date: string }>();
const formattedDate = computed(() => {
    const date = props.date;
    return date.split("");
});
</script>
<template>
    <span
        class="bigdate"
        v-for="(char, index) in formattedDate"
        :key="index"
        :class="{ number: !isNaN(Number(char)), char: isNaN(Number(char)) }"
    >
        {{ char }}
    </span>
</template>

<style lang="scss" scoped>
.bigdate {
    &.number {
        color: $primary;
        background-color: $dark-font;
        border-radius: 2px;
        margin: 0 0.25rem;
        padding: 0.75rem;
    }
    &.char {
        color: $light-bg;
        margin: 0 0.25rem;
    }
}
</style>
