<script lang="ts" setup>
import { ref } from "vue";
import ChevronDown from "@/Components/Icons/ChevronDown.vue";

const showFullDescription = ref(false);
</script>

<template>
    <div
        class="maskedTest"
        :data-state="showFullDescription ? 'unmasked' : 'masked'"
    >
        <div
            class="maskedTest__content"
            :class="{
                '--masked': !showFullDescription,
            }"
        >
            <slot></slot>
        </div>

        <div class="maskedTest__button">
            <button @click="showFullDescription = !showFullDescription">
                <ChevronDown color="#D8DCDC" />
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.maskedTest {
    $self: &;

    &__content {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    &__button {
        text-align: center;
        transition: 300ms;
        cursor: pointer;

        button {
            background: transparent;
            border: none;
            padding: 0;
            cursor: pointer;
        }
    }

    &[data-state="unmasked"] {
        #{$self}__button {
            transform: rotate(180deg);
        }
    }

    &[data-state="masked"] {
        #{$self}__content {
            max-height: clampGenerator(18.75, 31.25);

            --mask: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 1) 0,
                    rgba(0, 0, 0, 1) 40%,
                    rgba(0, 0, 0, 0) 95%,
                    rgba(0, 0, 0, 0) 0
                )
                100% 50% / 100% 100% repeat-x;
            -webkit-mask: var(--mask);
            mask: var(--mask);
        }
    }
}
</style>
