<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.463 16.0692C18.5381 16.1449 18.6403 16.1875 18.747 16.1875C18.8536 16.1875 18.9558 16.1449 19.0309 16.0692L19.8788 15.2213C19.9561 15.1487 20 15.0474 20 14.9413C20 14.8353 19.9561 14.7339 19.8788 14.6613L12.5279 7.31012C12.4155 7.19758 12.263 7.13428 12.104 7.13414H11.896C11.737 7.13428 11.5845 7.19758 11.4721 7.31012L4.1212 14.6613C4.04387 14.7339 4 14.8353 4 14.9413C4 15.0474 4.04387 15.1487 4.1212 15.2213L4.96907 16.0692C5.04416 16.1449 5.14639 16.1875 5.25302 16.1875C5.35966 16.1875 5.46189 16.1449 5.53698 16.0692L12 9.60588L18.463 16.0692Z"
            fill="#E8FF2E"
        />
    </svg>
</template>
