<script lang="ts" setup>
import { ref, computed } from "vue";
import TextInput from "../Forms/Input/TextInput.vue";
import { useI18n } from "vue-i18n";
import CurrentRefinement from "@/Components/Algolia/CurrentRefinement.vue";

const { t } = useI18n();

type Item = {
    value: string;
    label: string;
    count: number;
    isRefined: boolean;
};

const emit = defineEmits([
    "refine",
    "search",
    "toggleShowMore",
    "update:search-query",
]);

const props = withDefaults(
    defineProps<{
        items: Item[];
        searchLabel: string;
        multiple: boolean;
        localSearch: boolean;
        isShowingMore: boolean;
        canToggleShowMore: boolean;
        entity: string;
        searchQuery?: string;
    }>(),
    {
        multiple: false,
        localSearch: false,
        isShowingMore: false,
        canToggleShowMore: false,
        searchQuery: undefined,
    },
);

const search = ref("");
const isSelectAll = ref(false);

const displayItems = computed(() => {
    return props.items.filter((item) => {
        return item.label.toLowerCase().includes(search.value.toLowerCase());
    });
});

const hasSelected = computed(() => {
    return (
        props.items.filter((item) => {
            return item.isRefined;
        }).length > 0
    );
});

const searchForItems = (value) => {
    if (props.localSearch) {
        search.value = value;
    } else {
        isSelectAll.value = true;
        emit("search", value);
    }
};

const selectAll = () => {
    props.items.forEach((item) => emit("refine", item.value));
    isSelectAll.value = false;
};

const handleInput = (newValue: string) => {
    searchForItems(newValue);
    emit("update:search-query", newValue);
};
</script>

<template>
    <TextInput
        :placeholder="searchLabel"
        type="search"
        class="RefinementList__search"
        variante="facette"
        :model-value="searchQuery"
        @input="handleInput(($event.target as HTMLInputElement).value)"
    />

    <CurrentRefinement :included-attributes="entity" :is-into-facet="true" />

    <button
        class="select-all-button"
        @click="selectAll"
        v-if="entity === 'specification' && isSelectAll"
    >
        {{ t("actions.selectAll") }}
    </button>
    <div
        class="RefinementList__wrapper"
        :class="{
            'RefinementList__wrapper--has-selected': hasSelected && !multiple,
            'not-scrollable': entity === 'make',
        }"
    >
        <label
            class="RefinementList"
            v-for="item in displayItems"
            :class="{ 'RefinementList--selected': item.isRefined }"
            :key="`facette-${item.value}`"
        >
            <span>
                <span class="RefinementList__labelText">{{ item.label }}</span>
                <span class="RefinementList__count"
                    >({{ item.count.toLocaleString() }})</span
                >
            </span>
            <input
                class="RefinementList__checkbox"
                :class="{ 'RefinementList__checkbox--rounded': !multiple }"
                type="checkbox"
                :value="item.value"
                :checked="item.isRefined"
                @input.prevent="$emit('refine', item.value)"
            />
        </label>
        <button
            class="RefinementList__showMore"
            @click="$emit('toggleShowMore')"
            v-if="canToggleShowMore && displayItems.length > 9"
        >
            {{
                !isShowingMore
                    ? t("actions.showingMore")
                    : t("actions.showingLess")
            }}
        </button>

        <span v-if="!displayItems.length" class="no-results-label">{{
            t("noResults")
        }}</span>
    </div>
</template>

<style lang="scss">
.RefinementList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;

    &__search {
        margin-bottom: 1.125rem;
    }

    &__wrapper {
        max-height: calc(2.5rem * 6 - 1rem);
        padding-right: 1rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
        }

        &::-webkit-scrollbar-track {
            background: $light-bg;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $dark-bg;
            border-radius: 0;
            border: 0;
        }

        &--has-selected {
            .RefinementList__labelText {
                color: $light-bg;
            }
            .RefinementList--selected .RefinementList__labelText {
                color: #000;
            }
        }

        &.not-scrollable {
            max-height: none;
            overflow-y: visible;
        }
    }

    &__count,
    &__labelText {
        color: #000;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
    }

    &__count {
        color: $light-bg;
        padding-left: 0.2em;
    }
    &__checkbox {
        margin-left: auto;
        appearance: none;
        border: 1px solid $light-bg;
        width: 1.5rem;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 1.5rem;
        transition:
            background-color 0.2s ease-in-out,
            border-color 0.2s ease-in-out;

        &:checked {
            background: $primary url("../../../images/icons/check.svg")
                no-repeat center center / 1rem;
            border-color: $primary;
        }

        &--rounded {
            border-radius: 1.5rem;
        }
    }

    &__showMore {
        appearance: none;
        background: transparent;
        border: none;
        padding: 0;
        color: #000;
        opacity: 0.5;
        font-size: 0.875rem;
        &:hover {
            text-decoration: underline;
        }
    }
}

.select-all-button {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color $transition-time;
    text-decoration: none;
    opacity: 0.4;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding: 0;
    @include large-up {
        margin-bottom: 1rem;
    }
}
</style>
