<template>
    <svg viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M122.287 122.388H105.787V227.488H122.287V122.388Z"
            fill="currentColor"
        />
        <path
            d="M122.287 0.487793H105.787V105.588H122.287V0.487793Z"
            fill="currentColor"
        />
        <path
            d="M227.487 105.788H122.387V122.288H227.487V105.788Z"
            fill="currentColor"
        />
        <path
            d="M105.587 105.788H0.487061V122.288H105.587V105.788Z"
            fill="currentColor"
        />
    </svg>
</template>
