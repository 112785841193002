<script setup lang="ts">
import Breadcrumb from "../../Components/Breadcrumb.vue";
import {
    Article,
    DynamicContent,
    Question,
    Review,
    VehicleMake,
    VehicleModel,
    VehicleSpecification,
} from "../../types";
import ModelCard from "@/Components/Cards/ModelCard.vue";
import { useI18n } from "vue-i18n";
import SectionsLandingList from "@/Components/Sections/SectionsLandingList.vue";
import { Head } from "@inertiajs/vue3";
const { t } = useI18n();

const props = defineProps<{
    vehicleMake: VehicleMake;
    vehicleModel: VehicleModel;
    vehiclesSpecification: Array<VehicleSpecification>;
    dynamicContent: Array<DynamicContent>;
    faqJoinsteer: Array<Question>;
    faqStory: Array<Question>;
    breadcrumbs: Array<{ label: string; path?: string }>;
    minMonthly: number;
    topModels: Array<VehicleModel>;
    articles: Array<Article>;
    customerReview?: Review;
    reviewSchema: JSON;
    faqSchema: JSON;
}>();

const getOptions = (spec) => {
    const options = [];
    options["url"] = route("vehicleSpecification.show", {
        vehicleMake: props.vehicleModel.vehicle_make,
        vehicleModel: props.vehicleModel,
        vehicleSpecification: spec,
    });

    options["textAction"] = "Voir les véhicules disponibles";
    options["class"] = "spec";

    return options;
};
</script>
<template>
    <Head>
        <title>{{ vehicleModel.seo_data.title }}</title>
        <meta
            head-key="description"
            name="description"
            :content="vehicleModel.seo_data.content"
        />
        <component :is="'script'" type="application/ld+json">
            {{ reviewSchema }}
        </component>
        <component :is="'script'" type="application/ld+json" v-if="faqSchema">
            {{ faqSchema }}
        </component>
    </Head>

    <div class="container landing-make">
        <Breadcrumb :items="breadcrumbs"></Breadcrumb>
        <h1 class="landing-make__title main">
            <img
                v-if="vehicleModel.vehicle_make.logo_url"
                :src="vehicleModel.vehicle_make.logo_url"
                :alt="vehicleModel.vehicle_make.designation"
                class="landing-make__title--logo"
            />
            {{
                t("landingPage.title", {
                    make:
                        vehicleMake.designation +
                        " " +
                        vehicleModel.designation,
                    minMonthly: $filters.currency(minMonthly, true),
                })
            }}
        </h1>

        <div class="landing-make__filters" v-if="vehiclesSpecification">
            <p
                class="landing-make__filters--item"
                v-for="spec in vehiclesSpecification"
                :key="spec.id"
            >
                {{ spec.designation }} ({{
                    $filters.number(spec.vehicles_count)
                }})
            </p>
        </div>

        <div class="landing-make__wrapper models">
            <ModelCard
                :item="spec"
                v-for="spec in vehiclesSpecification"
                :key="spec.id"
                :options="getOptions(spec)"
                title-level="h2"
            />
        </div>
    </div>

    <SectionsLandingList
        :dynamic-content="dynamicContent"
        :faq-joinsteer="faqJoinsteer"
        :faq-story="faqStory"
        :min-monthly="minMonthly"
        :articles="articles"
        :vehicle-make="vehicleMake"
        :customer-review="customerReview"
        :is-landing="true"
        :top-models="topModels"
        :item-seo="vehicleMake.designation + ' ' + vehicleModel.designation"
    />
</template>
