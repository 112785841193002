<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.463 7.93081C18.5381 7.85509 18.6403 7.8125 18.747 7.8125C18.8536 7.8125 18.9558 7.85509 19.0309 7.93081L19.8788 8.77872C19.9561 8.8513 20 8.95263 20 9.05869C20 9.16474 19.9561 9.26608 19.8788 9.33866L12.5279 16.6899C12.4155 16.8024 12.263 16.8657 12.104 16.8659H11.896C11.737 16.8657 11.5845 16.8024 11.4721 16.6899L4.1212 9.33866C4.04387 9.26608 4 9.16474 4 9.05869C4 8.95263 4.04387 8.8513 4.1212 8.77872L4.96907 7.93081C5.04416 7.85509 5.14639 7.8125 5.25302 7.8125C5.35966 7.8125 5.46189 7.85509 5.53698 7.93081L12 14.3941L18.463 7.93081Z"
            fill="#E8FF2E"
        />
    </svg>
</template>
