import { onMounted, onBeforeUnmount, Ref, reactive, computed } from "vue";

export type SwipeDirection = "up" | "down" | "left" | "right" | "none";

type Position = {
    x: number;
    y: number;
};

export const useSwipe = (
    target: Ref,
    {
        allowSwipe,
        onEnd,
        minDiffToActive = 50,
    }: {
        allowSwipe?: () => boolean;
        onEnd: (direction: SwipeDirection) => void;
        minDiffToActive?: number;
    },
) => {
    const coordsStart = reactive<Position>({ x: 0, y: 0 });
    const coordsEnd = reactive<Position>({ x: 0, y: 0 });

    // to avoid too tiny swipes trigger
    const isValid = computed(
        () =>
            Math.max(Math.abs(diffX.value), Math.abs(diffY.value)) >=
            minDiffToActive,
    );

    const direction = computed((): SwipeDirection => {
        if (!isValid.value) return "none";
        if (Math.abs(diffX.value) > Math.abs(diffY.value)) {
            return diffX.value > 0 ? "left" : "right";
        } else {
            return diffY.value > 0 ? "up" : "down";
        }
    });

    const diffX = computed(() => coordsStart.x - coordsEnd.x);
    const diffY = computed(() => coordsStart.y - coordsEnd.y);

    const handleSwipeStart = (e: TouchEvent | MouseEvent) => {
        if (allowSwipe) {
            const result = allowSwipe();
            if (result === false) {
                return;
            }
        }
        //e.preventDefault();
        if ("touches" in e) {
            // Touch
            if (e.touches.length !== 1) return;
            coordsStart.x = e.touches[0].clientX;
            coordsStart.y = e.touches[0].clientY;
            coordsEnd.x = e.touches[0].clientX;
            coordsEnd.y = e.touches[0].clientY;

            //Add touch events
            target.value.addEventListener("touchmove", handleSwipeMove, opts);
            target.value.addEventListener("touchend", handleSwipeEnd);
            target.value.addEventListener("touchcancel", handleSwipeEnd);
        } else {
            // Mouse
            coordsStart.x = e.clientX;
            coordsStart.y = e.clientY;
            coordsEnd.x = e.clientX;
            coordsEnd.y = e.clientY;

            // Add mouse events
            target.value.addEventListener("mousemove", handleSwipeMove);
            target.value.addEventListener("mouseup", handleSwipeEnd);
            target.value.addEventListener("mouseleave", handleSwipeEnd);
        }
    };
    const handleSwipeMove = (e: TouchEvent | MouseEvent) => {
        e.preventDefault();
        if ("touches" in e) {
            if (e.touches.length !== 1) return;
            coordsEnd.x = e.touches[0].clientX;
            coordsEnd.y = e.touches[0].clientY;
        } else {
            coordsEnd.x = e.clientX;
            coordsEnd.y = e.clientY;
        }
    };
    const handleSwipeEnd = () => {
        onEnd?.(direction.value);

        // remove all events
        target.value.removeEventListener("touchend", handleSwipeEnd, opts);
        target.value.removeEventListener("touchmove", handleSwipeMove);
        target.value.removeEventListener("touchcancel", handleSwipeEnd);

        target.value.removeEventListener("mousemove", handleSwipeMove);
        target.value.removeEventListener("mouseup", handleSwipeEnd);
        target.value.removeEventListener("mouseleave", handleSwipeEnd);
    };

    const opts = { passive: false };

    onMounted(() => {
        if (target.value) {
            target.value.addEventListener("touchstart", handleSwipeStart, opts);
            target.value.addEventListener("mousedown", handleSwipeStart);
        }
    });

    onBeforeUnmount(() => {
        target.value.removeEventListener("touchstart", handleSwipeStart, opts);
        target.value.removeEventListener("mousedown", handleSwipeStart);
    });

    const triggerSwipe = (direction: SwipeDirection) => {
        onEnd?.(direction);
    };

    return {
        triggerSwipe,
    };
};
