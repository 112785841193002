<script setup lang="ts">
import Default from "@/Layouts/Default.vue";
import Account from "@/Layouts/Account.vue";
import { useI18n } from "vue-i18n";
import Button from "@/Components/Buttons/Button.vue";
import { ref } from "vue";
import Modal from "@/Modal/Modal.vue";
import { useForm } from "@inertiajs/vue3";

defineOptions({ layout: [Default, Account] });

const { t } = useI18n();

const form = useForm({});
const openingDeleteAccountModal = ref(false);

const deleteAccount = () => {
    form.delete(route("user.delete"));
};
</script>

<template>
    <div class="delete-section">
        <h1 class="delete-section__title">
            {{ t("account.deleteJoinsteerAccount") }}
        </h1>
        <p class="delete-section__confirm">{{ t("account.confirmDelete") }}</p>
        <Button
            type="button"
            variante="danger"
            @click="openingDeleteAccountModal = true"
        >
            {{ t("account.deleteJoinsteerAccount") }}
        </Button>

        <Modal
            :show="openingDeleteAccountModal"
            @close="openingDeleteAccountModal = false"
        >
            <template #content>
                <h1 class="delete-section__title">
                    {{ t("account.sureToDelete") }}
                </h1>
                <p class="delete-section__confirm">
                    {{ t("account.confirmDelete") }}
                </p>
                <div class="modal_actions">
                    <Button
                        type="button"
                        variante="danger"
                        @click="deleteAccount"
                        :loading="form.processing"
                    >
                        {{ t("account.deleteJoinsteerAccount") }}
                    </Button>
                    <Button
                        type="button"
                        variante="secondary"
                        @click="openingDeleteAccountModal = false"
                    >
                        {{ t("actions.cancel") }}
                    </Button>
                </div>
            </template>
        </Modal>
    </div>
</template>

<style scoped lang="scss">
.delete-section {
    padding: 2.5rem;
    border: 1px solid $light-bg;

    &__title {
        font-weight: 600;
        padding: 0;
        margin: 0;
        font-size: 1.5rem;
    }

    &__confirm {
        color: $dark-font;
        opacity: 0.6;
        line-height: 1.5rem;
        margin: 1.5rem 0;
        padding: 0;
    }

    @media (max-width: $breakpoint-m) {
        padding: 0;
        border: none;
    }
}

.modal_actions {
    display: grid;
    gap: 0.5rem;
}
</style>
