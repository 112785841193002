import { onBeforeUnmount, onMounted } from "vue";

type Props = {
    inCallback?: (entry: IntersectionObserverEntry) => void;
    outCallback?: (entry: IntersectionObserverEntry) => void;
    options?: IntersectionObserverInit;
};

export const useIntersectionObserver = ({
    options = {},
    inCallback,
    outCallback,
}: Props) => {
    let observer: IntersectionObserver;
    onMounted(() => {
        observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    inCallback?.(entry);
                } else {
                    outCallback?.(entry);
                }
            });
        }, options);
    });

    onBeforeUnmount(() => {
        observer.disconnect();
    });

    const addObservable = (observable: Element) => {
        observer.observe(observable);
    };

    return {
        addObservable,
    };
};
