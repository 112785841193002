<script setup lang="ts">
import { VehicleModel, VehicleSpecification } from "../../types";
import Button from "@/Components/Buttons/Button.vue";
import { Link } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

defineProps({
    item: {
        type: Object as () => VehicleModel | VehicleSpecification,
        required: true,
    },
    options: {
        type: Array,
        required: true,
    },
    isTopModel: {
        type: Boolean,
        required: false,
        default: false,
    },
    titleLevel: {
        type: String,
        required: true,
        default: "h2",
    },
});
</script>

<template>
    <Link
        class="vehicle-card-landing"
        :class="options['class']"
        :href="item.model_card_url"
    >
        <div class="vehicle-card-landing--top">
            <img
                v-if="item.illustration_url"
                :src="`${item.illustration_url}`"
                :alt="`${item.designation}`"
                class="vehicle-card-landing--img"
            />
            <img
                v-else
                src="../../../images/joinsteer-logo.svg"
                width="78"
                height="39"
                :alt="t('logoJoinsteer')"
                class="vehicle-card-landing--logo"
            />
        </div>

        <div class="vehicle-card-landing--content">
            <component class="vehicle-card-landing--title" :is="titleLevel"
                >LLD {{ item.full_designation }}</component
            >
            <div class="vehicle-card-landing--text">
                <div v-if="!isTopModel">
                    <h3 v-if="item.vehicles_count > 1">
                        {{
                            t("landingPage.text", {
                                minMonthly: $filters.currency(
                                    item.monthlyPaymentMin,
                                    true,
                                ),
                                maxMonthly: $filters.currency(
                                    item.monthlyPaymentMax,
                                    true,
                                ),
                            })
                        }}
                    </h3>
                    <h3 v-else>
                        {{
                            t("landingPage.textSingle", {
                                minMonthly: $filters.currency(
                                    item.monthlyPaymentMin,
                                    true,
                                ),
                            })
                        }}
                    </h3>
                </div>
                <h4>
                    {{
                        t("landingPage.vehicleCount", item.vehicles_count, {
                            count: $filters.number(item.vehicles_count),
                        })
                    }}
                </h4>
            </div>

            <Button class="vehicle-card-landing--action">{{
                options["textAction"]
            }}</Button>
        </div>
    </Link>
</template>

<style lang="scss" scoped>
.vehicle-card-landing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    background-color: #f2f5f6;
    text-decoration: none;
    height: 100%;
    @media (max-width: $breakpoint-l) {
        min-width: 80%;
    }
    &:hover,
    &:focus {
        .vehicle-card-landing--title {
            color: #fff;
        }
        .vehicle-card-landing--text {
            display: none;
        }

        .vehicle-card-landing--action {
            display: flex;
            width: 100%;
        }
    }
    &.model {
        &:hover,
        &:focus {
            .vehicle-card-landing--content {
                background-color: $dark-bg;
            }
        }
    }

    &.spec {
        &:hover,
        &:focus {
            background-color: $dark-bg;
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1rem;
        width: auto;
        height: 100px;
    }
    &--action {
        display: none;
        text-decoration: none;
        border-radius: 2px;
        margin-bottom: 5px;
    }
    &--top {
        position: relative;
        padding-bottom: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    &--img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &--logo {
        opacity: 0.5;
        width: 50%;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &--title {
        color: $dark-font;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.4;
        margin: 0 0 0.5rem 0;
    }
    &--text {
        color: $dark-font;
        opacity: 0.5;
        font-size: 1rem;
        h3,
        h4 {
            font-weight: 400;
            font-size: 1rem;
            margin: 0;
            line-height: 1.5;
        }
    }
}
</style>
