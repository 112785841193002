<script setup lang="ts">
import { ref, onMounted } from "vue";

const animated = ref(false);

const emit = defineEmits(["animationEnded"]);

onMounted(() => {
    animated.value = true;

    setTimeout(() => {
        emit("animationEnded");
    }, 4000);
});
</script>

<template>
    <div class="loader">
        <img
            src="../../../images/icons/counter-graduation.svg"
            alt="Loader"
            class="loader__graduation"
        />
        <img
            src="../../../images/icons/counter-indicator.svg"
            alt="Loader"
            class="loader__indicator"
            :class="{ animation: animated }"
        />
    </div>
</template>

<style lang="scss" scoped>
.loader {
    position: relative;

    &__indicator {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 10px;
        transform: rotate(-115deg);
        transform-origin: 50% calc(100% - 4px);
        &.animation {
            animation: change 4.5s ease-in-out;
        }
    }
}

@keyframes change {
    0% {
        transform: rotate(-115deg);
    }
    100% {
        transform: rotate(115deg);
    }
}
</style>
