<script setup lang="ts">
import Default from "@/Layouts/Default.vue";
import Account from "@/Layouts/Account.vue";
import OrderCard from "@/Components/Cards/OrderCard.vue";
import Pagination from "@/Components/Pagination.vue";
import { Order, PaginatedResponse } from "@/types";
import { useI18n } from "vue-i18n";

defineOptions({ layout: [Default, Account] });

defineProps<{
    orders: PaginatedResponse<Order>;
}>();

const { t } = useI18n();
</script>

<template>
    <div class="order">
        <template v-if="orders && orders.data.length > 0">
            <OrderCard
                v-for="order in orders.data"
                :key="order.id"
                :data="order"
            />

            <Pagination :data="orders" />
        </template>

        <h2 v-else>{{ t("order.notFound") }}</h2>
    </div>
</template>

<style scoped lang="scss"></style>
