<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { VehiclePreview } from "@/types";
import { ref } from "vue";
import { useSwipe } from "@/Composables/utils/useSwipe";
import VehicleId from "@/Components/VehicleId.vue";
import { usePage } from "@inertiajs/vue3";

const { t } = useI18n();

const sidebarRef = ref(null);
const page = usePage();

const allowSwipe = () => {
    if (!page.props.auth.user) {
        return false;
    }
    return true;
};


const isOpen = ref<boolean>(allowSwipe());

const { triggerSwipe } = useSwipe(sidebarRef, {
    minDiffToActive: 15,
    allowSwipe: allowSwipe,
    onEnd: (direction) => {
        if (direction === "up") {
            isOpen.value = true;
        } else if (direction === "down") {
            isOpen.value = false;
        }
    },
});

const onClickOnBarContainer = () => {
    if (!page.props.auth.user) {
        return;
    }

    const swipeDirection = isOpen.value ? "down" : "up";
    triggerSwipe(swipeDirection);
};

defineProps<{
    vehiclePreview: VehiclePreview;
}>();

const forceOpen = (open) => {
    isOpen.value = open;
};

defineExpose({
    forceOpen,
});
</script>
<template>
    <div :class="{ simulator__sidebar: true, '-active': isOpen }">
        <div class="simulator__sidebar__container" @click="onClickOnBarContainer()" ref="sidebarRef">
            <div class="simulator__sidebar__bar" v-if="allowSwipe()"></div>
        </div>
        <div class="simulator__sidebar__content">
            <div>
                <div class="vehicle-preview">
                    <h2 class="vehicle-preview__title">
                        {{ t("tunnel.yourCar") }}
                        <VehicleId :id="vehiclePreview.id" />
                    </h2>
                    <div class="vehicle-preview__content">
                        <div class="vehicle-preview__image">
                            <img
                                :src="vehiclePreview.image_url"
                                alt="Illustration"
                            />
                        </div>
                        <div>
                            <span class="vehicle-preview__make">{{
                                vehiclePreview.make
                            }}</span>
                            <span class="vehicle-preview__description">{{
                                vehiclePreview.description
                            }}</span>
                        </div>
                    </div>
                </div>

                <slot />
            </div>
        </div>
        <div class="simulator__sidebar__footer" v-if="$slots.footer">
            <div :class="{ 'responsive-footer': isOpen }">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.simulator__sidebar {
    background: $dark-bg;
    position: fixed;
    bottom: 0;
    right: 0;
    color: white;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    max-height: calc(100% - 20px);
    z-index: 2;
    $this: &;
    @include medium-down {
        overflow: hidden;
        border-radius: 1rem 1rem 0 0;
        align-content: end;

        &.-active {
            #{$this}__footer:not(.-reverse) {
                border-color: rgba(255, 255, 255, 0.25);
                background: rgba(0, 0, 0, 0.2);
            }
            #{$this}__footer.-reverse {
                grid-template-rows: 0fr;
                transition: grid-template-rows 0.5s ease-in;
            }
            #{$this}__content {
                opacity: 1;
                grid-template-rows: 1fr;
                transition:
                    grid-template-rows 0.5s ease-in 0s,
                    opacity 0.25s ease-in 0.4s;
                & > * {
                    overflow-y: auto;
                }
            }
        }
    }
    @include medium-up {
        top: 80px;
        width: clamp(360px, 50%, 527px);
    }

    &__container {
        position: absolute;
        width: 100%;
        height: 40px;
        top: 0px;
        cursor: pointer;

        &:hover {
            .simulator__sidebar__bar {
                &::before {
                    transform: rotate(-20deg) translateX(-8%);
                }

                &::after {
                    transform: rotate(20deg) translateX(8%);
                }
            }
        }
    }

    &__bar {
        position: absolute;
        @include medium-down {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 20px;
            cursor: pointer;

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 14px;
                height: 2px;
                background-color: white;
                transition: transform 0.25s ease-in-out;
            }

            &::before {
                top: 0;
                left: 0;
                transform-origin: center;
            }

            &::after {
                top: 0;
                right: 0;
                transform-origin: center;
            }
        }
    }

    &.-active {
        .simulator__sidebar__bar {
            @include medium-down {
                &::before {
                    transform: rotate(20deg) translateX(-8%);
                }

                &::after {
                    transform: rotate(-20deg) translateX(8%);
                }
            }
        }
    }

    &:not(.-active) {
        .simulator__sidebar__bar {
            @include medium-down {
                &::before {
                    transform: rotate(-20deg) translateX(-8%);
                }

                &::after {
                    transform: rotate(20deg) translateX(8%);
                }
            }
        }
    }

    &__content {
        overflow-y: auto;
        margin-bottom: auto;

        @include medium-down {
            display: grid;
            grid-template-rows: 0fr;
            opacity: 0;
            transition:
                grid-template-rows 0.5s ease-in-out 0.15s,
                opacity 0.25s ease-in-out 0s;

            > * {
                overflow: hidden;
            }
        }

        &__calculator {
            padding: 0 2.5rem 0.5rem 2.5rem;
        }
    }

    &__footer {
        border-top: 1px solid transparent;
        display: grid;
        grid-template-rows: 1fr;

        > * {
            padding: 0 2rem;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        $this: &;
        &:not(.-reverse) {
            @include medium-up {
                border-color: rgba(255, 255, 255, 0.25);
                background: rgba(0, 0, 0, 0.2);
            }
        }

        &.-reverse {
            border-radius: 1rem 1rem 0 0;
            background: $primary;
            transition: grid-template-rows 0.5s ease-in;
            #{$this}__title {
                color: $dark-font;
            }
            @include medium-up {
                display: none;
            }
        }

        &__content {
            width: 100%;
            padding: 2rem 0;

            &.--reduced-padding {
                padding: 1.5rem 0;
            }

            &__result {
                display: flex;
                justify-content: space-between;
            }

            & > * {
                width: 100%;
            }
        }

        &__title {
            font-size: 1.25rem;
            font-family: $bebas;
            color: $primary;
            text-transform: uppercase;

            @include medium-down {
                font-size: 1.25rem;
            }
        }
        &__img {
            max-width: 100px;
        }
        &__price {
            color: #8d9ea3;

            &__value {
                font-size: 1.5rem;
                color: white;
                font-weight: 700;
                transition: filter 0.35s ease-out;

                &.blur {
                    filter: blur(5px);
                }
            }
        }
    }
}

@include medium-down {
    .responsive-footer {
        .simulator__sidebar__footer__title {
            padding: 0;
            display: block;
        }
    }
}

.responsive-action {
    display: none;
    @include medium-down {
        display: block;
    }

    .continue-icon {
        margin-left: 0.5rem;
        rotate: 180deg;
    }
}
</style>
