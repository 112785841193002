import { AccordionContent } from "@/types";
import forEach from "lodash/forEach";
import { ComposerTranslation } from "vue-i18n";

export const getAccordionContent = (
    parent: string,
    keys: string[],
    item: string,
    t: ComposerTranslation,
): AccordionContent[] => {
    const accordionItems: AccordionContent[] = [];

    forEach(keys, (elem) => {
        const obj = {
            question: t(`sections.${parent}.accordions.${elem}.question`, {
                make: item ?? "",
            }),
            answer: t(`sections.${parent}.accordions.${elem}.answer`, {
                make: item ?? "",
            }),
        };

        accordionItems.push(obj);
    });

    return accordionItems;
};
