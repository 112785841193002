<script lang="ts" setup>
import Dots from "@/Components/Icons/Dots.vue";
import { useToggle } from "@/Composables/utils/useToggle";
import { Link } from "@inertiajs/vue3";

type Options = {
    label: string;
    action?: () => void;
    url?: string;
    newTab?: boolean;
};
defineProps<{
    options: Options[];
}>();

const { show, toggle } = useToggle({ defaultValue: false });
</script>
<template>
    <div class="dropdown" @click.stop>
        <Dots class="dropdown__button" @click.stop="toggle" />
        <ul class="dropdown__list" :class="{ '-active': show }">
            <li
                v-for="option in options"
                :key="option.label"
                class="dropdown__list__item"
            >
                <button @click="option.action" v-if="option.action">
                    {{ option.label }}
                </button>
                <div v-if="option.url">
                    <a
                        v-if="option.newTab"
                        :target="'_blank'"
                        :href="option.url"
                    >
                        {{ option.label }}
                    </a>
                    <Link v-else :href="option.url">{{ option.label }}</Link>
                </div>
            </li>
        </ul>
    </div>
</template>
<style lang="scss" scoped>
.dropdown {
    position: relative;
    display: inline-block;

    &__button {
        cursor: pointer;
        padding: 0.5rem;
        display: inline-block;
        margin: 0;
    }
    &__list {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1;
        background-color: white;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border: 1px rgba(217, 220, 220, 0.5) solid;
        font-size: 0.875rem;
        border-radius: 0.25rem;
        display: none;
        list-style: none;
        margin: 0;
        padding: 0;

        &__item {
            border-top: 1px solid $grey-card;

            a,
            button {
                display: inline-block;
                background: transparent;
                padding: 1rem;
                cursor: pointer;
                white-space: nowrap;
                border: none;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &.-active {
            display: block;
        }
    }
}
</style>
