<script setup lang="ts"></script>

<template>
    <div class="container page-content">
        <h1 class="page-content__title">MENTIONS LEGALES</h1>

        <h2 class="page-content__subtitle">Éditeur du site</h2>
        <p>
            Le site www.joinsteer.com est édité par la société BACT SAS, société
            par actions simplifiée au capital de 1 681 944 €, immatriculée au
            Registre du Commerce de Grasse et des Sociétés sous le numéro 879
            804 755 RCS Grasse, dont le siège social est situé à 930 route des
            dolines, Valbonne, France.
        </p>

        <h2 class="page-content__subtitle">Contact</h2>
        <p>contact@bact.fr</p>

        <h2 class="page-content__subtitle">Hébergement</h2>
        <p>
            Le site est hébergé par Google Ireland Limited, société de droit
            irlandais dont le siège social est situé à Gordon House, Barrow
            Street, Dublin 4, Irlande.
        </p>

        <h2 class="page-content__subtitle">Propriété intellectuelle</h2>
        <p>
            Le site est hébergé par Google Ireland Limited, société de droit
            irlandais dont le siège social est situé à Gordon House, Barrow
            Street, Dublin 4, Irlande.
        </p>

        <h2 class="page-content__subtitle">
            Protection des données personnelles
        </h2>
        <p>
            Conformément à la loi n°78-17 du 6 janvier 1978 modifiée, relative à
            l'informatique, aux fichiers et aux libertés, et au Règlement (UE)
            2016/679 du Parlement européen et du Conseil du 27 avril 2016
            (RGPD), les utilisateurs disposent d'un droit d'accès, de
            rectification, d'effacement et d'opposition aux données personnelles
            les concernant. Pour exercer ces droits, les utilisateurs peuvent
            contacter BACT SAS à l'adresse mentionnée ci-dessus.
        </p>

        <h2 class="page-content__subtitle">Conditions d'utilisation</h2>
        <p>
            L'utilisation du site www.joinsteer.com est régie par les termes et
            conditions d'utilisation disponibles sur le site. En utilisant le
            site, vous reconnaissez avoir pris connaissance de ces conditions et
            les avoir acceptées.
        </p>
    </div>
</template>

<style lang="scss" scoped>
.page-content {
    color: $dark-font;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 8rem;

    &__subtitle {
        color: black;
        font-weight: 400;
        font-family: $bebas;
        font-size: 2.5rem;
        margin: 4rem 0 1rem 0;
    }

    &__title {
        color: black;
        font-weight: 400;
        font-family: $bebas;
        font-size: 3rem;
        margin-bottom: 4rem;
    }

    &__list {
        p {
            margin: 0;
        }
    }
}
</style>
