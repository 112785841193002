<script setup lang="ts">
import algoliasearch from "algoliasearch/lite";
import Range from "../../Components/Forms/Input/Range.vue";
import { LandingData, Range as RangeType } from "../../types/index";
import MarketplaceHeader from "../../Components/Algolia/MarketplaceHeader.vue";
import WidgetPanel from "../../Components/Algolia/WidgetPanel.vue";
import Menu from "../../Components/Algolia/Menu.vue";
import ButtonFacette from "../../Components/Algolia/ButtonFacette.vue";
import Button from "@/Components/Buttons/Button.vue";
import HitsHeader from "../../Components/Algolia/HitsHeader.vue";
import { useI18n } from "vue-i18n";
import { onMounted, ref, watch } from "vue";
import { history } from "instantsearch.js/es/lib/routers";
import { singleIndex } from "instantsearch.js/es/lib/stateMappings";
import { usePage } from "@inertiajs/vue3";
import Breadcrumb from "../../Components/Breadcrumb.vue";
import VehicleHits from "../../Components/Algolia/VehicleHits.vue";
import { useIntersectionObserver } from "@/Composables/utils/useIntersectionObserver";
import { onBeforeUnmount } from "vue";
import SortBy from "@/Components/Algolia/SortBy.vue";
import ClearRefinement from "@/Components/Algolia/ClearRefinement.vue";
import {
  AisInstantSearch,
  AisConfigure,
  AisClearRefinements,
  AisMenu,
  AisDynamicWidgets,
  AisRangeInput,
  AisRefinementList,
  AisStats,
} from 'vue-instantsearch/vue3/es';


const page = usePage();

const props = withDefaults(
    defineProps<{
        research?: string | null;
        breadcrumbs?: Array<{ label: string; path?: string }> | null;
        landingData?: LandingData | undefined;
        initialMakesFacet?: Array<{
            value: string;
            label: string;
            count: number;
            isRefined: boolean;
        }>;
        minMonthly?: number;
    }>(),
    {
        research: null,
        breadcrumbs: null,
        landingData: undefined,
        initialMakesFacet: Array,
        minMonthly: 0,
    },
);

let footer: Element | null;

const toggleHeaderClass = (action: "add" | "remove") => {
    const header = document.getElementById("app-header");

    if (header) {
        if (action === "add") {
            header.classList.add("header--white");
        } else {
            header?.classList.remove("header--white");
        }
    }
};

const { addObservable } = useIntersectionObserver({
    options: {
        rootMargin: "-75px 0px 0px 0px",
    },
    inCallback: (entry) => {
        if (entry.target === footer) headerHitsReverseColor.value = true;

        if (entry.target.className === "marketplace-header") {
            toggleHeaderClass("add");
        }
    },
    outCallback: (entry) => {
        if (entry.target === footer) headerHitsReverseColor.value = false;

        if (entry.target.className === "marketplace-header") {
            toggleHeaderClass("remove");
        }
    },
});
const { t } = useI18n();

const openSidebar = ref(false);
const openFilter = ref(false);

const hitsPerPage = ref(16);

const searchClient = algoliasearch(
    page.props.algolia.appId,
    page.props.algolia.searchKey,
);

const routing = {
    router: history(),
    stateMapping: singleIndex(page.props.algolia.indexName),
};

const algoliaSort = {
    make: ["name:asc"],
    year: ["name:asc"],
    color: ["name:asc"],
    energyType: ["name:asc"],
};

const defaultSortBy = ref(["name:asc"]);
const countSortBy = ref(["isRefined", "count"]);

const isMakeSelected = ref(false);
const headerHitsReverseColor = ref(false);

const toggleFilter = () => {
    openSidebar.value = !openSidebar.value;
    document.body.classList.toggle("app-filter-open");
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const filtersOpen = () => {
    openFilter.value = !openFilter.value;
    document.body.classList.toggle("app-filter-open");
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

watch(
    () => openSidebar,
    () => {
        document.body.style.overflow = openSidebar.value ? "hidden" : "";
    },
);

const updateHitsPerPage = () => {
    if (page.component === "Vehicle/Index") {
        hitsPerPage.value = window.innerWidth <= 768 ? 20 : 36;
    } else {
        hitsPerPage.value = window.innerWidth <= 768 ? 3 : 16;
    }
};

onBeforeUnmount(() => {
    window.removeEventListener("resize", updateHitsPerPage);
});

// Pour la page modèle spécifique : on pré-filtre avec la marque, le model et la spécification si présente
const getFacetFilters = () => {
    const facetFilters: string[][] = [];

    if (props.landingData && props.landingData["vehicleMake"]) {
        isMakeSelected.value = true;

        facetFilters.push([
            `make:${props.landingData["vehicleMake"]["designation"]}`,
        ]);

        if (props.landingData["vehicleModel"]) {
            facetFilters.push([
                `model:${props.landingData["vehicleModel"]["designation"]}`,
            ]);
        }

        if (props.landingData["vehicleSpecification"]) {
            facetFilters.push([
                `specification:${props.landingData["vehicleSpecification"]["designation"]}`,
            ]);
        }
    }

    return facetFilters;
};

// Gestion de la liste des marques initiales
const makesFacet = ref<
    {
        value: string;
        label: string;
        count: number;
        isRefined: boolean;
    }[]
>([]);

const previousSelectedMake = ref("");
const isServer = ref(false);
const searchQueryMake = ref("");
const searchQueryModel = ref("");
const searchQuerySpec = ref("");
const searchQueryOptions = ref("");
const searchQueryColor = ref("");
const isMounted = ref(false);
const marketplaceHeaderRef = ref();

onMounted(() => {
    makesFacet.value = props.initialMakesFacet;
    const selectedMake = makesFacet.value.find((facet) => facet.isRefined);

    if (selectedMake) {
        previousSelectedMake.value = selectedMake.value;
    }

    // Boolean to allow display component only on client side
    isServer.value = typeof window === "undefined";

    footer = document.querySelector(".footer");
    if (footer) addObservable(footer);

    updateHitsPerPage();
    window.addEventListener("resize", updateHitsPerPage);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("menu[make]")) {
        isMakeSelected.value = true;
    }

    isMounted.value = true;
});

watch(
    () => marketplaceHeaderRef.value,
    (val) => {
        if (val.$refs.contentRef) {
            addObservable(val.$refs.contentRef);
        }
    },
);

watch(
    () => isMounted.value,
    (val) => {
        if (val) {
            footer = document.querySelector(".footer");

            if (footer) addObservable(footer);
        }
    },
);

const onStateChange = ({ uiState, setUiState }) => {
    const currentSelectedMake =
        uiState[page.props.algolia.indexName].menu?.make;
    isMakeSelected.value = !!currentSelectedMake;

    // Si on déselectionne la marque, on reset les facettes associées
    if (currentSelectedMake !== previousSelectedMake.value) {
        previousSelectedMake.value = currentSelectedMake;
        resetRefinementList(uiState, [
            "model",
            "specification",
            "options",
            "color",
        ]);
    }

    updateMakesFacet(currentSelectedMake);

    setUiState(uiState);
};

function resetRefinementList(uiState, facets) {
    const refinementList = uiState[page.props.algolia.indexName].refinementList;
    if (refinementList) {
        facets.forEach((facet) => {
            delete refinementList[facet];
        });
    }
}

function updateMakesFacet(currentSelectedMake) {
    const isRefined = (make) => make.value === currentSelectedMake;
    makesFacet.value = makesFacet.value.map((make) => ({
        ...make,
        isRefined: currentSelectedMake ? isRefined(make) : false,
    }));
}

const resetSearch = (value) => {
    switch (value) {
        case "make":
            searchQueryMake.value = "";
            break;
        case "model":
            searchQueryModel.value = "";
            break;
        case "spec":
            searchQuerySpec.value = "";
            break;
        case "options":
            searchQueryOptions.value = "";
            break;
        case "color":
            searchQueryColor.value = "";
            break;
    }
};
</script>

<template v-if="!isServer">
    <ais-instant-search
        :search-client="searchClient"
        :index-name="$page.props.algolia.indexName"
        :routing="routing"
        :on-state-change="onStateChange"
        v-if="isMounted"
    >
        <MarketplaceHeader
            @filters-open="filtersOpen"
            :search-client="searchClient"
            :index-name="$page.props.algolia.indexName"
            :research="research"
            :landing-data="landingData"
            :reverse-color="headerHitsReverseColor"
            :min-monthly="minMonthly"
            ref="marketplaceHeaderRef"
        />

        <div v-if="breadcrumbs">
            <Breadcrumb class="marketplace" :items="breadcrumbs" />
        </div>

        <div class="marketplace-content" id="marketplace-content">
            <div
                class="marketplace-sidebar filters"
                :class="openFilter ? 'open' : ''"
            >
                <div class="marketplace-sidebar__header">
                    <h2 class="marketplace-sidebar--title">
                        {{ t("sortBy") }}
                    </h2>
                    <button
                        @click="filtersOpen"
                        class="marketplace-sidebar--close-button"
                    >
                        <img
                            src="../../../images/icons/close_modal.svg"
                            width="24"
                            height="24"
                            alt="close filters"
                        />
                    </button>
                </div>
                <SortBy
                    :index-name="$page.props.algolia.indexName"
                    :reverse-color="false"
                    variante="secondary"
                />
            </div>
            <div class="marketplace-sidebar" :class="openSidebar ? 'open' : ''">
                <div class="marketplace-sidebar__header" ref="htmls">
                    <h2 class="marketplace-sidebar--title">
                        {{ t("vehicle.filters") }}
                    </h2>
                    <ais-clear-refinements>
                        <template #default="{ canRefine, refine, createURL }">
                            <a
                                class="clear-filters-button"
                                :href="createURL()"
                                @click.prevent="refine"
                                v-if="canRefine"
                            >
                                {{ t("actions.reset") }}
                            </a>
                        </template>
                    </ais-clear-refinements>

                    <button
                        @click="toggleFilter"
                        class="marketplace-sidebar--close-button"
                    >
                        <img
                            src="../../../images/icons/close_modal.svg"
                            width="24"
                            height="24"
                            alt="close filters"
                        />
                    </button>
                </div>

                <ais-dynamic-widgets
                    class="marketplace-sidebar__content"
                    :max-values-per-facet="500"
                >
                    <WidgetPanel
                        :title="t('vehicle.make')"
                        v-if="page.component === 'Vehicle/Index'"
                    >
                        <ais-menu attribute="make" :sort-by="defaultSortBy">
                            <template #default="{ refine }">
                                <Menu
                                    :items="makesFacet"
                                    @refine="
                                        (u) => {
                                            refine(u);
                                            resetSearch('make');
                                        }
                                    "
                                    :search-label="t('searchMake')"
                                    :local-search="true"
                                    :multiple="false"
                                    :can-toggle-show-more="false"
                                    entity="make"
                                    :is-showing-more="false"
                                    :search-query="searchQueryMake"
                                    @update:search-query="
                                        (newValue) =>
                                            (searchQueryMake = newValue)
                                    "
                                />
                            </template>
                        </ais-menu>
                    </WidgetPanel>

                    <WidgetPanel
                        :title="t('vehicle.model')"
                        v-if="page.component === 'Vehicle/Index'"
                        :disabled="!isMakeSelected"
                        :force-open="isMakeSelected"
                    >
                        <ais-refinement-list
                            attribute="model"
                            searchable
                            :sort-by="defaultSortBy"
                            :show-more="true"
                            :show-more-limit="500"
                        >
                            <template
                                #default="{
                                    items,
                                    searchForItems,
                                    toggleShowMore,
                                    isShowingMore,
                                    refine,
                                    canToggleShowMore,
                                }"
                            >
                                <ClearRefinement entity="model" />
                                <Menu
                                    @search="searchForItems"
                                    :items="items"
                                    @refine="
                                        (u) => {
                                            refine(u);
                                            resetSearch('model');
                                        }
                                    "
                                    :search-label="t('searchModel')"
                                    :local-search="false"
                                    :multiple="true"
                                    :can-toggle-show-more="canToggleShowMore"
                                    @toggleShowMore="toggleShowMore"
                                    :is-showing-more="isShowingMore"
                                    entity="model"
                                    :search-query="searchQueryModel"
                                    @update:search-query="
                                        (newValue) =>
                                            (searchQueryModel = newValue)
                                    "
                                />
                            </template>
                        </ais-refinement-list>
                    </WidgetPanel>

                    <WidgetPanel
                        :title="t('vehicle.specification')"
                        v-if="page.component === 'Vehicle/Index'"
                        :disabled="!isMakeSelected"
                        :force-open="isMakeSelected"
                    >
                        <ais-refinement-list
                            attribute="specification"
                            searchable
                            :sort-by="defaultSortBy"
                            :show-more="true"
                            :show-more-limit="500"
                        >
                            <template
                                #default="{
                                    items,
                                    refine,
                                    searchForItems,
                                    toggleShowMore,
                                    isShowingMore,
                                    canToggleShowMore,
                                }"
                            >
                                <ClearRefinement entity="specification" />
                                <Menu
                                    @search="searchForItems"
                                    :items="items"
                                    @refine="
                                        (u) => {
                                            refine(u);
                                            resetSearch('spec');
                                        }
                                    "
                                    :search-label="t('searchSpecification')"
                                    :local-search="false"
                                    :multiple="true"
                                    :can-toggle-show-more="canToggleShowMore"
                                    @toggleShowMore="toggleShowMore"
                                    :is-showing-more="isShowingMore"
                                    entity="specification"
                                    :search-query="searchQuerySpec"
                                    @update:search-query="
                                        (newValue) =>
                                            (searchQuerySpec = newValue)
                                    "
                                />
                            </template>
                        </ais-refinement-list>
                    </WidgetPanel>

                    <WidgetPanel
                        :title="t('vehicle.options')"
                        :disabled="!isMakeSelected"
                        :force-open="isMakeSelected"
                    >
                        <ais-refinement-list
                            attribute="options"
                            operator="and"
                            searchable
                            :sort-by="defaultSortBy"
                            :show-more="true"
                            :show-more-limit="500"
                        >
                            <template
                                #default="{
                                    items,
                                    refine,
                                    searchForItems,
                                    toggleShowMore,
                                    isShowingMore,
                                    canToggleShowMore,
                                }"
                            >
                                <ClearRefinement entity="options" />

                                <Menu
                                    @search="searchForItems"
                                    :items="items"
                                    @refine="
                                        (u) => {
                                            refine(u);
                                            resetSearch('options');
                                        }
                                    "
                                    :search-label="t('searchOption')"
                                    :local-search="false"
                                    :multiple="true"
                                    :can-toggle-show-more="canToggleShowMore"
                                    @toggleShowMore="toggleShowMore"
                                    :is-showing-more="isShowingMore"
                                    entity="options"
                                    :search-query="searchQueryOptions"
                                    @update:search-query="
                                        (newValue) =>
                                            (searchQueryOptions = newValue)
                                    "
                                />
                            </template>
                        </ais-refinement-list>
                    </WidgetPanel>

                    <WidgetPanel
                        :title="t('vehicle.exteriorColor')"
                        :disabled="!isMakeSelected"
                        :force-open="isMakeSelected"
                    >
                        <ais-refinement-list
                            attribute="color"
                            operator="or"
                            searchable
                            :sort-by="countSortBy"
                            :show-more="true"
                            :show-more-limit="500"
                        >
                            <template
                                #default="{
                                    items,
                                    refine,
                                    searchForItems,
                                    toggleShowMore,
                                    isShowingMore,
                                    canToggleShowMore,
                                }"
                            >
                                <ClearRefinement entity="color" />

                                <Menu
                                    @search="searchForItems"
                                    :items="items"
                                    @refine="
                                        (u) => {
                                            refine(u);
                                            resetSearch('color');
                                        }
                                    "
                                    :search-label="t('searchColor')"
                                    :local-search="false"
                                    :multiple="true"
                                    :can-toggle-show-more="canToggleShowMore"
                                    @toggleShowMore="toggleShowMore"
                                    :is-showing-more="isShowingMore"
                                    entity="color"
                                    :search-query="searchQueryColor"
                                    @update:search-query="
                                        (newValue) =>
                                            (searchQueryColor = newValue)
                                    "
                                />
                            </template>
                        </ais-refinement-list>
                    </WidgetPanel>

                    <WidgetPanel :title="t('vehicle.year')" :open="false">
                        <ais-range-input attribute="registration_at">
                            <template
                                #default="{ range, refine, currentRefinement }"
                            >
                                <Range
                                    :min-label="t('filters.minYear')"
                                    :max-label="t('filters.maxYear')"
                                    :value="currentRefinement"
                                    :range="range"
                                    @change="
                                        (value: RangeType) => refine(value)
                                    "
                                />
                            </template>
                        </ais-range-input>
                    </WidgetPanel>

                    <WidgetPanel :title="t('vehicle.mileage')" :open="false">
                        <ais-range-input attribute="mileage">
                            <template
                                #default="{ range, refine, currentRefinement }"
                            >
                                <Range
                                    unit="km"
                                    :min-label="t('filters.minMileage')"
                                    :max-label="t('filters.maxMileage')"
                                    :value="currentRefinement"
                                    :range="range"
                                    @change="
                                        (value: RangeType) => refine(value)
                                    "
                                />
                            </template>
                        </ais-range-input>
                    </WidgetPanel>

                    <WidgetPanel
                        :title="t('vehicle.monthlyPrice')"
                        :open="false"
                    >
                        <ais-range-input attribute="monthly_price">
                            <template
                                #default="{ range, refine, currentRefinement }"
                            >
                                <Range
                                    :max-label="t('filters.maxMonthlyPrice')"
                                    unit="€"
                                    :show-min="false"
                                    :value="currentRefinement"
                                    :range="range"
                                    @change="
                                        (value: RangeType) => refine(value)
                                    "
                                />
                            </template>
                        </ais-range-input>
                    </WidgetPanel>

                    <WidgetPanel :title="t('vehicle.energyType')" :open="false">
                        <ais-refinement-list
                            attribute="energy_type"
                            :sort-by="algoliaSort.energyType"
                        >
                            <template #default="{ items, refine }">
                                <ButtonFacette
                                    :items="items"
                                    @refine="refine"
                                    name="energyType"
                                />
                            </template>
                        </ais-refinement-list>
                    </WidgetPanel>
                </ais-dynamic-widgets>

                <div class="marketplace-sidebar--close-button validate-button">
                    <div
                        class="marketplace-sidebar--close-button validate-button--header"
                    >
                        <p
                            class="marketplace-sidebar--close-button validate-button--search"
                        >
                            Votre recherche
                        </p>
                        <ais-stats>
                            <template #default="{ nbHits }">
                                <p
                                    class="marketplace-sidebar--close-button validate-button--stats"
                                >
                                    {{ $filters.number(nbHits) }}
                                    <span
                                        class="marketplace-sidebar--close-button validate-button--stats item"
                                        >{{ t("vehicles") }}</span
                                    >
                                </p>
                            </template>
                        </ais-stats>
                    </div>
                    <Button
                        class="marketplace-sidebar--close-button validate-button--action"
                        type="button"
                        @click="toggleFilter"
                    >
                        Voir les véhicules disponibles
                    </Button>
                </div>
            </div>

            <div class="marketplace-content__hits" ref="htmls">
                <ais-configure
                    :hits-per-page.camel="hitsPerPage"
                    v-if="page.component !== 'Vehicle/Index'"
                    :facet-filters.camel="getFacetFilters()"
                />

                <ais-configure :hits-per-page.camel="hitsPerPage" v-else />

                <HitsHeader
                    :reverse-color="headerHitsReverseColor"
                    :index-name="$page.props.algolia.indexName"
                    @toggle="toggleFilter"
                />
                <VehicleHits />
            </div>
        </div>
    </ais-instant-search>
</template>

<style lang="scss">
footer.footer {
    @include medium-down {
        padding-bottom: 100px !important;
    }
}
.marketplace-content {
    padding-bottom: $space-m;
    margin: 0 calc(var(--margin-container) / 2);

    display: block;

    @include medium-up {
        padding: calc(var(--margin-container) / 2) 0 $space-xl 0;
        display: flex;
        gap: $space-l;
        align-items: flex-start;
    }

    &__hits {
        flex-grow: 1;
    }
}

.marketplace-sidebar {
    padding: 0;
    border-radius: 4px;
    box-sizing: border-box;
    transition:
        left $transition-time ease-in-out,
        padding $transition-time ease-in-out;
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: -100vw;
    z-index: 60;
    background-color: white;
    &.open {
        position: fixed;
        display: block;
        height: 100dvh;
        border: none;
        overflow: auto;
        padding: 0 20px;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 150;
    }

    &__header {
        position: sticky;
        top: 0;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $space-s $space-m 0;
        background-color: white;
    }

    &__content {
        padding: 0 $space-m $space-s $space-m;
    }

    &--title {
        color: $dark-font;
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 1.5;
        padding-bottom: 0;
    }

    &--close-button {
        background: transparent;
        border: none;
        display: none;

        @media (max-width: 960px) {
            display: inline-block;
        }

        &:focus {
            outline: none;
        }
        &.validate-button {
            position: sticky;
            bottom: 1rem;
            flex-direction: column;
            color: white;
            background-color: $dark-bg;
            border-radius: 4px;
            padding: 1rem;
            margin-top: 8rem;
            display: none;
            @media (max-width: $breakpoint-m) {
                display: flex;
            }

            &--header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                font-weight: 600;
                width: 100%;
            }
            &--search {
                font-family: $bebas;
                font-size: 1rem;
                text-transform: uppercase;
                color: $primary;
                font-weight: 600;
                letter-spacing: 0.64px;
                margin: 0;
            }
            &--stats {
                margin: 0;
                font-size: 1.25rem;
                display: flex;
                align-content: center;
                align-items: center;
                &.item {
                    color: white;
                    opacity: 0.5;
                    font-size: 0.875rem;
                    line-height: 2rem;
                    font-weight: 400;
                    margin-left: 0.5rem;
                }
            }
            &--action {
                border-radius: 2px;
                margin-top: 1rem;
            }
        }
    }

    &.filters {
        display: none;
        @media (max-width: 960px) {
            display: block;
        }
    }
}

.ais-ClearRefinements {
    display: flex;
    margin-left: 7rem;

    @include medium-up {
        margin-left: 4rem;
    }

    @include large-up {
        margin-left: 4rem;
        margin-top: 0.5rem;
    }
}

.clear-filters-button {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: color $transition-time;
    text-decoration: none;
    opacity: 0.4;
    font-size: 0.75rem;
    @include large-up {
        margin-bottom: 0.5rem;
    }

    &:hover {
        opacity: 1;
    }

    &:disabled {
        display: none;
    }
}
.clear-filters-facet {
    margin: 0 0 1rem 0;
    @include large-up {
        margin-bottom: 0;
    }
}

.ais-DynamicWidgets-widget {
    margin: 1.375rem 0;
    padding-bottom: 1.375rem;
    border-bottom: 1px solid $light-bg;

    &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}
@media (max-width: calc($breakpoint-m - 1px)) {
    .marketplace-header__title {
        @include hidden;
    }
}

@media (min-width: 960px) {
    .ais-SearchBox-input {
        border: 0;
    }

    .marketplace-sidebar {
        position: sticky;
        top: 5.65rem;
        left: 0;
        display: block;
        width: 270px;
        height: calc(100dvh - 5.68rem);
        overflow: auto;
        flex-shrink: 0;
        padding: 0;
        border: 1px solid $light-bg;
    }
}

@include large-up {
    .marketplace-header__search-box {
        min-width: 50rem;
    }
}

@media screen and (max-width: $breakpoint-m) {
    body.app-filter-open {
        overflow: hidden;
    }
}
</style>
