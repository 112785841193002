<template>
    <svg viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.33203 5.66797H12.6654H3.33203Z" fill="currentColor" />
        <path
            d="M3.33203 5.66797H12.6654"
            stroke="currentColor"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M1 1H15H1Z" fill="currentColor" />
        <path
            d="M1 1H15"
            stroke="currentColor"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M5.66797 10.332H10.3346H5.66797Z" fill="currentColor" />
        <path
            d="M5.66797 10.332H10.3346"
            stroke="currentColor"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
