<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import MessageLoader from "@/Components/MessageLoader.vue";

const { t } = useI18n();

defineProps<{
    isLoading: boolean;
}>();
</script>

<template>
    <div class="simulator__alexis">
        <img
            src="../../../images/alexis.png"
            alt="Alexis"
            width="48"
            height="48"
        />

        <div class="simulator__alexis__content">
            <span class="simulator__alexis__name">Alexis</span>
            <span class="simulator__alexis__job">
                {{ t("tunnel.coFounder") }}
            </span>
        </div>
    </div>
    <Transition mode="out-in">
        <div v-if="isLoading" class="simulator__message">
            <MessageLoader />
        </div>
        <div v-else class="simulator__message simulator__message--expanded">
            <slot />
        </div>
    </Transition>
</template>
<style lang="scss">
.simulator {
    &__alexis {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        &__content {
            margin-left: 1rem;
        }

        &__name {
            display: block;
            font-weight: 600;
            font-size: 1.25rem;
            @include medium-down {
                font-size: 1rem;
            }
        }

        &__job {
            display: block;
            font-size: 1.25rem;
            opacity: 0.6;
            @include medium-down {
                font-size: 1rem;
            }
        }
    }
    &__message {
        display: inline-block;
        box-sizing: border-box;
        background: white;
        max-width: 100%;
        padding: 1rem 1.5rem;
        border-radius: 0px 8px 8px 8px;
        border: 1px solid $grey-input;
        line-height: 1.5rem;
        margin-bottom: 1.5rem;

        @include medium-down {
            padding: 0.875rem 1rem;
            margin-bottom: 0.5rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
        }

        &--expanded {
            width: 100%;
        }
    }
}
</style>
