<script setup lang="ts">
import { Range as RangeType } from "../../../types";
import { computed } from "vue";
import InputInnerLabel from "@/Components/Forms/Input/InputInnerLabel.vue";
import { PropType, ref } from "vue";
import { watch } from "vue";
import { debounce } from "lodash-es";

const emit = defineEmits(["change"]);
const props = defineProps({
    value: {
        type: Object as PropType<RangeType>,
        default: null,
    },
    range: {
        type: Object as PropType<RangeType>,
        default: null,
    },
    showMin: {
        type: Boolean,
        default: true,
    },
    showMax: {
        type: Boolean,
        default: true,
    },
    minLabel: {
        type: String,
        default: "Minimum",
    },
    maxLabel: {
        type: String,
        default: "Maximum",
    },
    unit: {
        type: String,
        default: null,
    },
    attribute: {
        type: String,
        default: null,
    },
});

const userInputMin = ref(props.value.min ?? undefined);
const userInputMax = ref(props.value.max ?? undefined);

watch(
    userInputMin,
    debounce(() => {
        updateRefinementRange();
    }, 500),
);

watch(
    userInputMax,
    debounce(() => {
        updateRefinementRange();
    }, 500),
);

const updateRefinementRange = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let finalMinValue = null as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let finalMaxValue = null as any;

    if (userInputMin.value && props.showMin) {
        const intMinValue = userInputMin.value;
        finalMinValue = intMinValue;
    }

    if (userInputMax.value && props.showMax) {
        const intMaxValue = userInputMax.value;
        finalMaxValue = intMaxValue;
    }

    finalMinValue = parseFloat(finalMinValue);
    if (isNaN(finalMinValue)) {
        finalMinValue = props.range.min;
    }

    finalMaxValue = parseFloat(finalMaxValue);
    if (isNaN(finalMaxValue)) {
        finalMaxValue = props.range.max;
    }

    if (finalMinValue! <= finalMaxValue!) {
        console.debug("update range", finalMinValue, finalMaxValue);
        emit("change", {
            min: finalMinValue,
            max: finalMaxValue,
        });
    }
};

const hasInputError = computed(() => {
    if (
        userInputMin.value &&
        userInputMax.value &&
        userInputMin.value > userInputMax.value
    ) {
        console.debug("La valeur min ne peut être supérieure à la valeur max", {
            userInputMin: userInputMin.value,
            userInputMax: userInputMax.value,
        });
        return true;
    }

    return false;
});
</script>

<template>
    <div
        class="slider-input__wrapper"
        :class="{ 'slider-input__wrapper--uniq': !showMin || !showMax }"
    >
        <InputInnerLabel
            v-model="userInputMin"
            :label="minLabel"
            type="text"
            is-number
            :unit="unit"
            v-if="showMin"
            :has-error="hasInputError"
        />
        <div class="slider-input__separation" v-if="showMin"></div>
        <InputInnerLabel
            v-model="userInputMax"
            :label="maxLabel"
            type="text"
            is-fake-number
            :unit="unit"
            v-if="showMax"
            :has-error="hasInputError"
        />
    </div>
</template>

<style scoped lang="scss">
.slider-input {
    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 0.1fr 1fr;
        gap: 0.75rem;
        align-items: center;

        &--uniq {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__separation {
        &::before {
            content: "";
            display: block;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 1px;
            background: rgba(0, 0, 0, 0.2);
        }
    }
}
</style>
