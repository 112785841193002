<script setup lang="ts">
import TextInput from "@/Components/Forms/Input/TextInput.vue";
import Button from "@/Components/Buttons/Button.vue";
import { router, useForm, usePage } from "@inertiajs/vue3";
import { Vehicle, Simulation, VehiclePreview } from "@/types";
import Simulator from "@/Layouts/Simulator.vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const page = usePage();
const props = defineProps<{
    vehicle: Vehicle;
    simulation: Simulation;
    vehiclePreview: VehiclePreview;
}>();

const { t } = useI18n();

const form = useForm({
    code: page.props.flash.code ?? "",
});

const emailOtpForm = useForm({
    identifier: props.simulation.user.email,
    channel: "mail",
});

const currentIntermediateScreen = ref(1);
const sendByPhone = ref(true);

const goToScreen = (screenNumber) => {
    currentIntermediateScreen.value = screenNumber;
};

const sendByEmail = () => {
    emailOtpForm.post(route("auth.otp"), {
        onSuccess: () => {
            sendByPhone.value = false;
            form.reset();
        },
    });
};

const submit = () => {
    form.transform((data) => ({
        ...data,
        channel: sendByPhone.value ? "phone" : "mail",
        identifier: props.simulation.user.phone,
    })).post(route("auth.login"), {
        onSuccess: () => {
            router.visit(
                route("simulations.resume", {
                    simulation: props.simulation,
                }),
            );
        },
    });
};
</script>

<template>
    <Simulator
        :show-message="currentIntermediateScreen == 1"
        :vehicle-preview="vehiclePreview"
        :vehicle="vehicle"
        :go-to-screen="goToScreen"
        :current-tunnel-step="2"
        :current-intermediate-step="currentIntermediateScreen"
    >
        <template #message>
            <template v-if="sendByPhone">
                {{
                    t("tunnel.weSendYouCodeBySms", {
                        code: simulation.user.phone,
                    })
                }}
            </template>
            <template v-else>
                {{
                    t("tunnel.weSendYouCodeByEmail", {
                        email: simulation.user.email,
                    })
                }}
            </template>
        </template>

        <div
            class="mobile-hidden"
            :class="{ visible: currentIntermediateScreen == 1 }"
        >
            <template class="mobile-only">
                <Button
                    type="button"
                    @click="goToScreen(2)"
                    class="form-button"
                >
                    {{ t("actions.continue") }}
                </Button>
            </template>
        </div>

        <div
            class="mobile-hidden row-margin"
            :class="{ visible: currentIntermediateScreen == 2 }"
        >
            <form @submit.prevent="submit">
                <TextInput
                    id="code"
                    v-model="form.code"
                    type="text"
                    :label="
                        t('forms.whatsYour', {
                            attr: t('attributes.code'),
                        })
                    "
                    :message="form.errors.code"
                    :placeholder="t('attributes.code')"
                    required
                />

                <p class="send-mail" v-if="sendByPhone">
                    {{ t("messages.doNotReceiveCode") }}
                    <button
                        :disabled="emailOtpForm.processing"
                        type="button"
                        class="send-mail__btn"
                        @click="sendByEmail"
                    >
                        {{ t("tunnel.sendCodeByEmail") }}
                    </button>
                </p>

                <Button
                    type="submit"
                    class="form-button"
                    :disabled="!form.code"
                >
                    {{ t("actions.continue") }}
                </Button>
            </form>
        </div>
    </Simulator>
</template>

<style lang="scss" scoped>
.send-mail {
    font-size: 0.875rem;
    margin-bottom: 2rem;

    &__btn {
        background: none;
        border: none;
        outline: none;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
}
</style>
