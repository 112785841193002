<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.81202 3L3 3.81202L7.18798 8L3 12.188L3.81202 13L8 8.81202L12.188 13L13 12.188L8.81202 8L13 3.81202L12.188 3L8 7.18798L3.81202 3Z"
            fill="currentColor"
        />
    </svg>
</template>
