<script setup lang="ts">
import Marketplace from "../../Components/Algolia/Marketplace.vue";
import { onMounted, ref } from "vue";

defineProps({
    research: {
        type: String,
        required: false,
        default: "",
    },
    initialMakesFacet: {
        type: Array<{
            value: string;
            label: string;
            count: number;
            isRefined: boolean;
        }>,
        required: false,
        default: () => [],
    },
    fromVideo: {
        type: Boolean,
        default: false,
    },
});

const showOverlay = ref<boolean>(true);

onMounted(() => {
    showOverlay.value = false;
});
</script>

<template>
    <Transition :name="fromVideo ? 'page' : ''">
        <div class="overlay" v-if="showOverlay"></div>
    </Transition>
    <Marketplace
        :research="research"
        :initial-makes-facet="initialMakesFacet"
    />
</template>

<style lang="scss">
.overlay {
    position: fixed;
    width: 100vw;
    height: 100dvh;
    z-index: 1000;
    background-color: black;
}

.page-enter-active,
.page-leave-active {
    transition: all 2s 200ms;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
}
</style>
