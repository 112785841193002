<script lang="ts" setup>
const pageChange = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
</script>
<template>
    <ais-pagination :padding="1" @page-change="pageChange">
        <template
            #default="{
                currentRefinement,
                nbPages,
                pages,
                isFirstPage,
                isLastPage,
                refine,
                createURL,
            }"
        >
            <ul class="ais-Pagination-list">
                <li>
                    <a
                        class="ais-Pagination-link"
                        :href="createURL(currentRefinement - 1)"
                        @click.prevent="refine(currentRefinement - 1)"
                    >
                        <span
                            class="ais-Pagination-item previousPage"
                            :class="isFirstPage ? 'disabled' : ''"
                            >‹</span
                        >
                    </a>
                </li>

                <li v-if="currentRefinement > 1 && nbPages !== 3">
                    <a
                        :href="createURL(0)"
                        @click.prevent="refine(0)"
                        class="ais-Pagination-link"
                    >
                        <span class="ais-Pagination-item">1</span>
                    </a>
                </li>

                <li v-if="currentRefinement > 2">...</li>
                <li v-for="page in pages" :key="page">
                    <a
                        :href="createURL(page)"
                        class="ais-Pagination-link"
                        @click.prevent="refine(page)"
                    >
                        <span
                            class="ais-Pagination-item"
                            :class="
                                page === currentRefinement ? 'selected' : ''
                            "
                            >{{ page + 1 }}</span
                        >
                    </a>
                </li>
                <li v-if="currentRefinement < nbPages - 3">...</li>
                <li v-if="currentRefinement < nbPages - 2 && nbPages !== 3">
                    <a
                        :href="createURL(nbPages)"
                        @click.prevent="refine(nbPages)"
                        class="ais-Pagination-link"
                    >
                        <span class="ais-Pagination-item">{{ nbPages }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="ais-Pagination-link"
                        :href="createURL(currentRefinement + 1)"
                        @click.prevent="refine(currentRefinement + 1)"
                    >
                        <span
                            class="ais-Pagination-item nextPage"
                            :class="isLastPage ? 'disabled' : ''"
                            >›</span
                        >
                    </a>
                </li>
            </ul>
        </template>
    </ais-pagination>
</template>
