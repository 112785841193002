<script setup lang="ts">
import { Link } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";
import Breadcrumb from "@/Components/Breadcrumb.vue";

const { t } = useI18n();

const getBreadcrumbs = () => {
    const breadcrumbs = [
        { label: t("home.home"), path: route("vehicles.index") },
        { label: t("account.myAccount") },
    ];
    return breadcrumbs;
};
</script>

<template>
    <div class="container">
        <Breadcrumb :items="getBreadcrumbs()" />
    </div>
    <div class="container account">
        <nav class="account__nav">
            <h2 class="account__nav-title">{{ t("account.myAccount") }}</h2>
            <Link
                :href="route('user.edit')"
                class="account__nav-link"
                :class="{ active: $page.url === route('user.edit', {}, false) }"
                >{{ t("account.informations") }}</Link
            >
            <Link
                :href="route('user.financingRequests')"
                class="account__nav-link"
                :class="{
                    active:
                        $page.url ===
                        route('user.financingRequests', {}, false),
                }"
                >{{ t("account.financingRequests") }}</Link
            >
            <Link
                :href="route('user.orders')"
                class="account__nav-link"
                :class="{
                    active: $page.url === route('user.orders', {}, false),
                }"
                >Ma commande
            </Link>
            <Link
                :href="route('user.accountManagement')"
                class="account__nav-link"
                :class="{
                    active:
                        $page.url ===
                        route('user.accountManagement', {}, false),
                }"
                >{{ t("account.accountManagement") }}</Link
            >
        </nav>
        <div class="account__content">
            <slot></slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
.account {
    padding-bottom: 4rem;
    margin-top: 1rem;

    @include large-up {
        margin-top: 3rem;
    }

    &__nav {
        overflow-x: auto;
        display: flex;
        margin-bottom: 1.5rem;
    }
    &__nav-title {
        display: none;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.2;
    }
    &__nav-link {
        white-space: nowrap;
        padding: 1rem 0.75rem;
        text-decoration: none;
        border-bottom: 1px solid $dark-font;
        opacity: 0.3;

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }

        &.active,
        &:focus,
        &:hover {
            opacity: 1;
        }
    }

    @include large-up {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 4rem;

        &__nav {
            flex-direction: column;
            padding: 1.5rem;
            border: 1px solid $light-bg;
            border-radius: 4px;
            flex: 0 0 14rem;
        }

        &__nav-title {
            display: block;
        }

        &__nav-link {
            padding: 0.5rem 0;
            text-decoration: none;
            border-bottom: 0;
            opacity: 1;

            &.active,
            &:focus,
            &:hover {
                font-weight: 700;
            }
        }

        &__content {
            flex-grow: 1;
        }
    }
}
</style>
