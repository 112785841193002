<script setup lang="ts">
import Accordion from "@/Components/Accordions/Accordion.vue";
import Timeline from "@/Components/Timeline.vue";
import Calculator from "@/Components/Calculator.vue";
import { ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import {
    AccordionType,
    Vehicle,
    InitialsCalculatorParameters,
    VehicleMake,
    Review,
    Article,
    Simulation,
    Question,
} from "@/types";
import Gallery from "@/Components/Gallery.vue";
import SharePanel from "@/Components/Misc/SharePanel.vue";
import { router, useForm, usePage } from "@inertiajs/vue3";
import { Head } from "@inertiajs/vue3";
import Button from "@/Components/Buttons/Button.vue";
import Breadcrumb from "@/Components/Breadcrumb.vue";
import axios from "axios";
import { debounce } from "lodash-es";
import dayjs from "dayjs";
import VehicleId from "@/Components/VehicleId.vue";
import SectionsLandingList from "@/Components/Sections/SectionsLandingList.vue";
import Truck from "@/Components/Icons/Truck.vue";

const { t } = useI18n();

const page = usePage();
const props = defineProps<{
    vehicle: Vehicle;
    vehicleMake: VehicleMake;
    photos: Array<string>;
    breadcrumbs: Array<{ label: string; path?: string }>;
    initialsCalculatorParameters: InitialsCalculatorParameters;
    showMonthlyPrice: boolean;
    userConnected: boolean;
    initialMonthlyPrice: number;
    customerReview?: Review;
    reviewSchema: JSON;
    hasLocalPhotos: boolean;
    articles: Array<Article>;
    minMonthly: number;
    simulation: Simulation;
    simulationExists: boolean;
    faqJoinsteer: Array<Question>;
    vehicleSchema: JSON | null;
    minFirstRent: number | null;
}>();

const firstStep = ref(true);

const estimatedDeliveryDate = computed(() => {
    return dayjs(props.vehicle.estimated_delivery_date)
        .locale("fr")
        .format("DD MMMM YYYY");
});

const estimatedDeliveryShortDate = computed(() => {
    return dayjs(props.vehicle.estimated_delivery_date)
        .locale("fr")
        .format("DD/MM/YY");
});

const accordionRef = ref<AccordionType | null>(null);

const metaDescription = `LLD à partir de ${props.minMonthly}€/mois | Livrable chez vous le ${estimatedDeliveryDate.value} partout en France.`;
const description = `Dès ${new Intl.NumberFormat("fr-FR").format(
    props.minMonthly,
)}€/mois`;

const dates = [
    { label: "Signature du bon de commande", sublabel: "Aujourd'hui" },
    { label: "Signature du contrat de financement", sublabel: "3 à 5 jours" },
    { label: "Versement du premier loyer", sublabel: "2 à 3 jours" },
    {
        label: "Envoie du certificat d'immatriculation",
        sublabel: "2 à 3 jours",
    },
    { label: "Mise en transport du véhicule", sublabel: "3 à 5 jours" },
    { label: "Paiement des frais d'immatriculation", sublabel: "2 à 3 jours" },
    {
        label: "Livraison estimée",
        sublabel: `à partir du ${estimatedDeliveryDate.value}`,
    },
];
const scrollToAccordion = () => {
    if (accordionRef.value && accordionRef.value.$el) {
        accordionRef.value.$el.scrollIntoView({ behavior: "smooth" });
        accordionRef.value.forceOpen();
    }
};

const simulationForm = useForm({
    offer: props.initialsCalculatorParameters.offer,
    duration: props.initialsCalculatorParameters.duration,
    first_rent: props.initialsCalculatorParameters.firstRent,
    mileage: props.initialsCalculatorParameters.mileage,
});

const monthlyPrice = ref(props.initialMonthlyPrice);

const ctaLabel = computed(() => {
    return props.showMonthlyPrice
        ? "tunnel.getPreApproval"
        : "actions.customizeMonthlyPrice";
});

watch(
    () => page.props.auth.user,
    (newValue, oldValue) => {
        if (newValue != oldValue && newValue != null && oldValue == null) {
            getNewEstimation();
        }
    },
    { deep: true },
);

watch(
    simulationForm,
    debounce(() => {
        if (!simulationForm.processing && props.showMonthlyPrice) {
            getNewEstimation();
        }
    }, 500),
);

// On relance la création de révision ou de simulation si l'utilisateur change les paramètres de la calculette
watch(
    () => [
        simulationForm.offer,
        simulationForm.duration,
        simulationForm.first_rent,
        simulationForm.mileage,
    ],
    () => {
        firstStep.value = true;
    },
);

const getNewEstimation = () => {
    axios
        .get(route("vehicles.estimate", { vehicle: props.vehicle }), {
            params: {
                offer: simulationForm.offer,
                duration: simulationForm.duration,
                first_rent: simulationForm.first_rent ?? 0,
                mileage: simulationForm.mileage,
            },
        })
        .then((response) => {
            if (response.status == 200) {
                if (!response.data.error) {
                    monthlyPrice.value = response.data.estimation;
                }
            }
        });
};

const hasFirstRentError = computed(() => {
    return (
        simulationForm.first_rent &&
        simulationForm.first_rent > props.vehicle.max_first_rent
    );
});

const simulate = () => {
    if (props.userConnected) {
        if (firstStep.value) {
            if (typeof simulationForm.first_rent === "undefined") {
                simulationForm.first_rent = 0;
            }
            if (props.simulation) {
                // Si l'user a deja une simulation sur le véhicule, on crée une nouvelle révision
                simulationForm.post(
                    route("simulations.revision", {
                        simulation: props.simulation,
                    }),
                    {
                        preserveScroll: true,
                        onSuccess: () => {
                            if (
                                page.props.flash.data &&
                                page.props.flash.data.price
                            ) {
                                monthlyPrice.value =
                                    page.props.flash.data.price;
                            }
                            firstStep.value = false;
                        },
                    },
                );
            } else if (!props.simulation && props.simulationExists) {
                // Si l'user a deja une simulation sur un autre véhicule, on crée une simulation
                simulationForm.post(
                    route("simulations.create", { vehicle: props.vehicle }),
                    {
                        preserveScroll: true,
                        onSuccess: () => {
                            if (
                                page.props.flash.data &&
                                page.props.flash.data.price
                            ) {
                                monthlyPrice.value =
                                    page.props.flash.data.price;
                            }
                            firstStep.value = false;
                        },
                    },
                );
            } else {
                // Si l'utilisateur est connecté mais n'a pas de simulation associée
                if (hasFirstRentError.value != true) {
                    simulationForm.get(
                        route("simulations.start", { vehicle: props.vehicle }),
                    );
                }
            }
        } else {
            // L'utilisateur a passé la première étape de révision/simulation, on l'envoie sur le résumé de la simulation
            router.visit(
                route("simulations.resume", {
                    simulation: props.simulation,
                }),
                {
                    preserveState: true,
                },
            );
        }
    } else {
        if (hasFirstRentError.value != true) {
            simulationForm.get(
                route("simulations.start", { vehicle: props.vehicle }),
            );
        }
    }
};

const simulateMobile = () => {
    if (hasFirstRentError.value != true) {
        simulationForm.get(
            route("simulations.start", { vehicle: props.vehicle }),
        );
    }
};
</script>

<template>
    <Head>
        <title>{{ vehicle.display_name2 }}</title>
        <meta
            head-key="description"
            name="description"
            :content="metaDescription"
        />

        <meta property="og:title" :content="vehicle.display_name2" />
        <meta property="og:description" :content="metaDescription" />
        <meta property="og:type" content="website" />
        <meta property="og:url" :content="vehicle.url" />
        <meta property="og:image" :content="photos[0]" v-if="photos.length" />

        <meta property="twitter:title" :content="vehicle.display_name2" />
        <meta property="twitter:description" :content="metaDescription" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" :content="vehicle.url" />
        <meta
            property="twitter:image"
            :content="photos[0]"
            v-if="photos.length"
        />
        <component :is="'script'" type="application/ld+json">
            {{ reviewSchema }}
        </component>

        <component
            :is="'script'"
            type="application/ld+json"
            v-if="vehicleSchema"
        >
            {{ vehicleSchema }}
        </component>
    </Head>

    <div>
        <div class="container details">
            <Breadcrumb :items="breadcrumbs" />

            <div class="layout-2-cols">
                <div class="layout-2-cols__left">
                    <Gallery
                        :vehicle="vehicle"
                        :photos="photos"
                        :has-local-photos="hasLocalPhotos"
                        @discover="simulate"
                        :label="ctaLabel"
                    />

                    <div class="mobile">
                        <h1 class="mobile__title">
                            {{ vehicle.display_name2 }}
                            <VehicleId :id="vehicle.id" />
                        </h1>

                        <p class="product-summary__delivery">
                            {{ description }}
                        </p>
                    </div>

                    <!--                    Mobile-->
                    <div class="mobile margin-bottom">
                        <div
                            v-if="hasFirstRentError != true"
                            class="monthly-price"
                        >
                            <span class="monthly-price__label">
                                {{ t("tunnel.yourMonthlyPrice") }}
                            </span>
                            <span class="monthly-price__value">
                                <span
                                    class="showMonthlyPrice blur"
                                    v-if="showMonthlyPrice"
                                >
                                    {{ $filters.number(monthlyPrice) }}
                                </span>
                                <span v-else class="showMonthlyPrice blur">
                                    0000
                                </span>
                                <span> € {{ t("vehicle.perMonth") }} </span>
                            </span>
                        </div>

                        <div class="product-description__cta">
                            <Button
                                @click="simulateMobile"
                                variante="primary"
                                type="submit"
                                class="simulate-btn"
                                :disabled="hasFirstRentError == true"
                            >
                                {{ t("tunnel.getPreApprovalMobile") }}
                            </Button>

                            <div class="product-summary__count">
                                Cette voiture a été vue
                                <span>{{ vehicle.visit_count }} fois</span>
                                cette semaine.
                            </div>
                        </div>
                    </div>

                    <SharePanel :vehicle="vehicle" />

                    <div class="product-accordions">
                        <Accordion
                            :large="true"
                            icon="algolia"
                            title-color="black"
                            :title-font-weight="700"
                        >
                            <template #header> Performance </template>

                            <template #default>
                                <div class="performance">
                                    <div class="performance__item">
                                        <span class="performance__item__value">
                                            {{
                                                vehicle.registration_at
                                                    ? $filters.date(
                                                          vehicle.registration_at,
                                                          "YYYY",
                                                      )
                                                    : "-"
                                            }}
                                        </span>
                                        <span
                                            class="performance__item__attribute"
                                        >
                                            {{ t("vehicle.circulationYear") }}
                                        </span>
                                    </div>
                                    <div class="performance__item">
                                        <span class="performance__item__value">
                                            {{
                                                vehicle.mileage
                                                    ? $filters.number(
                                                          vehicle.mileage,
                                                      )
                                                    : "-"
                                            }}
                                        </span>
                                        <span
                                            class="performance__item__attribute"
                                        >
                                            {{ t("vehicle.mileage") }}
                                        </span>
                                    </div>
                                    <div class="performance__item">
                                        <span class="performance__item__value">
                                            {{
                                                vehicle.technical?.power ?? "-"
                                            }}
                                            <span
                                                class="performance__item__unit"
                                                >CV</span
                                            >
                                        </span>
                                        <span
                                            class="performance__item__attribute"
                                        >
                                            {{ t("vehicle.maximalPower") }}
                                        </span>
                                    </div>
                                </div>
                            </template>
                        </Accordion>
                        <Accordion
                            :large="true"
                            icon="algolia"
                            v-if="vehicle.extra_options.length > 0"
                            title-color="black"
                            :title-font-weight="700"
                        >
                            <template #header>{{
                                t("vehicle.options")
                            }}</template>

                            <template #default>
                                <div class="options">
                                    <div
                                        class="options__item"
                                        v-for="option in vehicle.extra_options"
                                        :key="`option-${option.id}`"
                                    >
                                        {{ option.designation }}
                                    </div>
                                </div>
                            </template>
                        </Accordion>
                        <Accordion
                            :large="true"
                            icon="algolia"
                            v-if="vehicle.default_options.length > 0"
                            title-color="black"
                            :title-font-weight="700"
                        >
                            <template #header>{{
                                t("vehicle.equipment")
                            }}</template>

                            <template #default>
                                <div class="options">
                                    <div
                                        class="options__item"
                                        v-for="option in vehicle.default_options"
                                        :key="`option-${option.id}`"
                                    >
                                        {{ option.designation }}
                                    </div>
                                </div>
                            </template>
                        </Accordion>
                        <Accordion
                            ref="accordionRef"
                            :large="true"
                            icon="algolia"
                            title-color="black"
                            :title-font-weight="700"
                        >
                            <template #header>
                                Informations de livraison
                            </template>

                            <template #default>
                                <div>
                                    <Timeline :items="dates" />
                                </div>
                            </template>
                        </Accordion>
                    </div>
                </div>
                <div class="layout-2-cols__right">
                    <div class="product-summary">
                        <h1 class="product-summary__title">
                            {{ vehicle.display_name2 }}
                            <VehicleId :id="vehicle.id" />
                        </h1>

                        <p class="product-summary__delivery">
                            {{ description }}
                        </p>

                        <div class="product-summary__attributes">
                            <div class="product-summary__attributes__item">
                                <span
                                    class="product-summary__attributes__item__value"
                                >
                                    {{
                                        vehicle.mileage
                                            ? $filters.number(vehicle.mileage)
                                            : "-"
                                    }}
                                </span>
                                <span
                                    class="product-summary__attributes__item__label"
                                    >km</span
                                >
                            </div>
                            <div class="product-summary__attributes__item">
                                <span
                                    class="product-summary__attributes__item__value"
                                >
                                    {{ vehicle.technical?.power ?? "-" }}
                                </span>
                                <span
                                    class="product-summary__attributes__item__label"
                                    >cv</span
                                >
                            </div>
                            <div class="product-summary__attributes__item">
                                <span
                                    class="product-summary__attributes__item__value"
                                >
                                    {{
                                        vehicle.registration_at
                                            ? $filters.date(
                                                  vehicle.registration_at,
                                                  "YYYY",
                                              )
                                            : "-"
                                    }}
                                </span>
                                <span
                                    class="product-summary__attributes__item__label"
                                    >{{ t("vehicle.year") }}</span
                                >
                            </div>
                        </div>
                        <div class="product-summary__deliverable">
                            <Truck />
                            <span class="product-summary__deliverable__item"
                                >Livrable chez vous le
                                {{ estimatedDeliveryShortDate }}</span
                            >
                            <button
                                class="product-summary__deliverable__action"
                                @click="scrollToAccordion"
                            >
                                {{ t("actions.showMore") }}
                            </button>
                        </div>

                        <hr class="separator" />

                        <form @submit.prevent="simulate">
                            <Calculator
                                :max-first-rent="vehicle.max_first_rent"
                                :min-first-rent="minFirstRent"
                                :has-first-rent-error="
                                    hasFirstRentError == true
                                "
                                :light-mode="true"
                                v-model:offer="simulationForm.offer"
                                v-model:duration="simulationForm.duration"
                                v-model:first-rent="simulationForm.first_rent"
                                v-model:mileage="simulationForm.mileage"
                            />

                            <!--                            Desktop-->
                            <div class="product-description__cta">
                                <div
                                    v-if="hasFirstRentError != true"
                                    class="product-description__cta--monthly-price"
                                >
                                    <span class="monthly-price__label">
                                        {{ t("tunnel.yourMonthlyPrice") }}
                                    </span>
                                    <span class="monthly-price__value">
                                        <span
                                            class="showMonthlyPrice"
                                            v-if="
                                                showMonthlyPrice &&
                                                !firstStep &&
                                                userConnected
                                            "
                                        >
                                            {{ $filters.number(monthlyPrice) }}
                                        </span>
                                        <span
                                            v-else
                                            class="showMonthlyPrice blur"
                                        >
                                            0000
                                        </span>
                                        <span>
                                            € {{ t("vehicle.perMonth") }}
                                        </span>
                                    </span>
                                </div>
                                <Button
                                    variante="primary"
                                    type="submit"
                                    class="simulate-btn"
                                    :disabled="hasFirstRentError == true"
                                    v-if="firstStep"
                                >
                                    {{ t("actions.customizeMonthlyPrice") }}
                                </Button>
                                <Button
                                    v-else
                                    variante="primary"
                                    type="submit"
                                    class="simulate-btn"
                                >
                                    {{ t("tunnel.getPreApproval") }}
                                </Button>
                                <div class="product-summary__count">
                                    Cette voiture a été vue
                                    <span>{{ vehicle.visit_count }} fois</span>
                                    cette semaine.
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <SectionsLandingList
            :vehicle="vehicle"
            :articles="articles"
            :vehicle-make="vehicleMake"
            :customer-review="customerReview"
            :is-landing="false"
            :is-vehicle-show="true"
            :dynamic-content="[]"
            :faq-joinsteer="faqJoinsteer"
            :faq-story="[]"
            :item-seo="vehicle.display_name2"
        />
    </div>
</template>

<style lang="scss" scoped>
.details {
    margin-bottom: clampGenerator(3, 8);
}

.mobile {
    display: none;

    @include medium-down {
        display: block;
    }

    &.margin-bottom {
        margin-bottom: 1.5rem;

        @include medium-down {
            position: fixed;
            z-index: 5;
            left: 1rem;
            right: 1rem;
            bottom: 0;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.75rem;
    }
}

.layout-2-cols {
    width: 100%;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;

    &__left {
        flex-grow: 1;
        width: calc(100% - 410px);
    }

    &__right {
        // only make sticky if the height of the content is bigger than the screen
        @media (min-height: 1082px) {
            position: sticky;
        }

        top: 80px;
        width: 410px;
        min-width: 410px;
        margin-left: 1.5rem;
    }

    @include medium-down {
        flex-direction: column;

        &__left {
            width: 100%;
        }

        &__right {
            display: none;
        }
    }
}

.monthly-price {
    background: $dark-bg;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0.75rem 0 0.75rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &__label {
        text-transform: uppercase;
        color: $primary;
        font-family: $bebas;
        font-size: 1.25rem;

        @include medium-down {
            font-size: 1rem;
        }
    }

    &__value {
        display: flex;
        align-items: flex-end;

        span {
            display: inline-block;

            &:first-child {
                font-size: 1.25rem;
                font-weight: 700;

                @include medium-down {
                    font-size: 1rem;
                }

                &.blur {
                    filter: blur(5px);
                }
            }

            &:last-child {
                font-size: 0.875rem;
                opacity: 0.5;
                margin-left: 0.5rem;
                padding-bottom: 0.2rem;

                @include medium-down {
                    padding-bottom: 0.05rem;
                }
            }
        }
    }
}

.separator {
    margin: 1.5rem 0;
    border-top: 1px solid $light-bg;
}

.simulate-btn {
    width: 100%;
}

.product-layout {
    display: grid;
    grid-template-columns: 1fr 414px;
    gap: 1.5rem;

    @media (max-width: $breakpoint-l) {
        grid-template-columns: 1fr;
    }
}

.product-summary {
    width: 100%;
    padding: 1.5rem;
    border: 1px solid $light-bg;
    border-radius: 0.25rem;
    box-sizing: border-box;

    @media (max-width: $breakpoint-l) {
        border: none;
        padding: 0;
    }

    &__title {
        font-size: 2rem;
        margin: 0;
        padding: 0;

        @media (max-width: $breakpoint-l) {
            font-size: 1.5rem;
        }
    }

    &__delivery {
        line-height: 1.5rem;
        font-weight: 600;
    }

    &__attributes {
        margin: 1rem 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;

        @media (max-width: $breakpoint-l) {
            display: none;
        }

        &__item {
            border: 1px solid $light-bg;
            border-radius: 0.25rem;
            padding: 1rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__value {
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }

    &__count {
        margin: 1rem 0;
        text-align: center;

        span {
            font-weight: 600;
        }
    }

    &__deliverable {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;

        &__item {
            color: $dark-font;
            font-size: 1rem;
            opacity: 0.5;
        }

        &__action {
            cursor: pointer;
            appearance: none;
            border: none;
            background: transparent;
            color: $dark-bg;
            font-size: 0.75rem;
            text-decoration-line: underline;
            margin-left: auto;
        }

        @media (max-width: $breakpoint-l) {
            display: none;
        }
    }
}

.product-description {
    margin: 2.5rem 0;

    @include medium-down {
        margin: 1rem 0 1.5rem 0;
    }

    &__content {
        line-height: 24px;
        font-size: 1rem;
        margin: 0;
        padding: 0;
        opacity: 0.6;
    }

    &__cta {
        color: white;
        background-color: $dark-bg;
        padding: 1rem;
        border-radius: 0 0 4px 4px;

        &--monthly-price {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;

            .monthly-price__label {
                font-size: 1rem;
                letter-spacing: 0.64px;
                font-weight: 400;
            }
        }

        @include medium-up {
            border-radius: 4px;
        }

        .simulate-btn {
            border-radius: 2px;
        }

        .product-summary__count {
            margin-bottom: 0;
        }
    }
}

.product-accordions {
    margin-top: 2.5rem;
    display: grid;
    row-gap: 2rem;
}

.toggle-mask-button {
    background: transparent;
    font-size: 0.75rem;
    text-decoration: underline;
    border: none;
    padding: 0;

    &:focus {
        outline: none;
    }

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

.performance {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &__value {
            font-family: $bebas;
            font-weight: 600;
            font-size: 2.5rem;
            margin-bottom: 0.625rem;
            letter-spacing: 0.25rem;
        }

        &__unit {
            margin-left: 0.5rem;
            font-family: $bebas;
            font-size: 1.125rem;
        }

        &__attribute {
            opacity: 0.6;
            font-size: 1.125rem;
        }
    }

    @media (max-width: $breakpoint-m) {
        grid-template-columns: repeat(2, 1fr);

        &__item {
            &__value {
                font-size: 2rem;
            }

            &__attribute {
                font-size: 1rem;
            }
        }
    }
}

.options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;

    &__item {
        font-size: 1.125rem;
        opacity: 0.6;
        line-height: 1.75rem;
    }

    @media (max-width: $breakpoint-l) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    @media (max-width: $breakpoint-m) {
        grid-template-columns: repeat(1, 1fr);

        &__item {
            font-size: 1rem;
            line-height: 1.125rem;
        }
    }
}
</style>
