<script setup lang="ts">
import { computed } from "vue";
import Button from "@/Components/Buttons/Button.vue";
import { ref } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { useI18n } from "vue-i18n";
import { Vehicle } from "@/types";

defineEmits(["discover"]);
const { t } = useI18n();

const props = defineProps<{
    vehicle: Vehicle;
    photos: Array<string>;
    hasLocalPhotos: boolean;
    label: string;
}>();

const INITIAL_IMAGES_COUNT = 7;
const showAllPhotoOnPage = ref(false);
const isViewerOpen = ref(false);

const isSold = computed(() => !!props.vehicle.sold_at);

// New computed property to filter photos and avoid duplications
const filteredPhotos = computed(() => {
    if (isSold.value) {
        return [props.photos[0]];
    }

    // Return a copy of the photos array with the second photo removed if it exists
    // This prevents the duplication issue where the first photo appears twice
    const photosCopy = [...props.photos];
    if (photosCopy.length > 1 && props.hasLocalPhotos) {
        //photosCopy.splice(0, 1);
    }
    return photosCopy;
});

const photosLength = computed(() => {
    return filteredPhotos.value.length;
});

const selectedPhotos = computed(() => {
    if (isSold.value) {
        return [filteredPhotos.value[0]];
    }

    // Take all photos or just the initial count based on user selection
    // No need to splice again since the filteredPhotos already has duplicates removed
    const photos = showAllPhotoOnPage.value
        ? filteredPhotos.value
        : filteredPhotos.value.slice(0, INITIAL_IMAGES_COUNT);

    return photos
});

const toggleViewer = () => {
    if (isSliding.value) {
        return;
    }

    if (isSold.value) {
        return;
    }

    isViewerOpen.value = !isViewerOpen.value;
    document.body.style.overflow = isViewerOpen.value ? "hidden" : "";
};

const isSliding = ref(false);

const toggleIsSliding = () => {
    console.log("toggleIsSliding");
    isSliding.value = true;
};

const toogleIsNotSliding = () => {
    console.log("toogleIsNotSliding");
    isSliding.value = false;
};

</script>
<template>
    <div class="gallery-container" :class="{ 'gallery-sold': isSold }">
        <div class="gallery-carousel" :class="{ 'hide-when-sold': isSold }">
            <Carousel
                :wrap-around="true"
                @drag="toggleIsSliding"
                @slide-end="toogleIsNotSliding"
            >
                <Slide v-for="(photo, index) in filteredPhotos" :key="index" >
                    <div class="gallery-carousel__item">
                        <img
                            :src="photo"
                            :alt="`${vehicle.display_name2} #${index + 1}`"
                            @click="toggleViewer"
                        />
                    </div>
                </Slide>
                <template #addons>
                    <Pagination v-if="!isSold" />
                </template>
            </Carousel>
        </div>

        <!-- Mobile sold image container -->
        <div v-if="isSold" class="mobile-sold-image">
            <img
                :src="filteredPhotos[0]"
                :alt="`${vehicle.display_name2}`"
                loading="lazy"
            />
        </div>

        <div
            class="gallery"
            :class="{
                'gallery--masked':
                    photosLength > INITIAL_IMAGES_COUNT && !showAllPhotoOnPage,
            }"
        >
            <button
                v-for="(photo, index) in selectedPhotos"
                class="gallery__item"
                @click="toggleViewer"
                :key="index"
            >
                <img
                    class="gallery-action-button gallery-action-button--expand"
                    src="../../images/icons/expand.svg"
                    alt="Open viewer"
                    width="24"
                    height="24"
                    v-if="!isSold"
                />
                <img
                    :src="photo"
                    :alt="`Joinsteer leasing - ${vehicle.display_name2} #${
                        index + 1
                    }`"
                    loading="lazy"
                />
            </button>
        </div>

        <div v-if="isSold" class="sold-overlay">
            <img src="/images/sold_icon_v2.svg" alt="Vendu" class="sold-icon" />
        </div>
    </div>

    <div class="gallery-footer" v-if="photosLength > INITIAL_IMAGES_COUNT">
        <Button
            variante="tertiary"
            type="button"
            @click="() => (showAllPhotoOnPage = !showAllPhotoOnPage)"
            :right-icon="showAllPhotoOnPage ? 'MinusAlgolia' : 'Plus'"
        >
            {{ t("actions.displayAllPhotos") }}
        </Button>
    </div>

    <Teleport to="body" v-if="isViewerOpen">
        <div class="viewer-photos">
            <button
                class="gallery-action-button gallery-action-button--close"
                @click="toggleViewer"
            >
                <img
                    src="../../images/icons/close_modal.svg"
                    alt="Open viewer"
                    width="24"
                    height="24"
                />
            </button>

            <div class="viewer-photos__container">
                <div class="viewer-photos__items">
                    <div
                        v-for="(photo, index) in filteredPhotos"
                        :key="index"
                        class="viewer-photos__item"
                    >
                        <img
                            :src="photo"
                            :alt="`Joinsteer leasing - ${
                                vehicle.display_name2
                            } #${index + 1}`"
                            loading="lazy"
                        />
                    </div>
                </div>

                <div class="viewer-photos__footer">
                    <span>{{ t("vehicle.forYou") }}</span>
                    <Button type="button" @click="$emit('discover')">
                        {{ t(label) }}
                    </Button>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
.gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;
    position: relative;

    @media (max-width: $breakpoint-l) {
        display: none;
    }

    &--masked {
        --mask: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 1) 0,
                rgba(0, 0, 0, 1) 90%,
                rgba(0, 0, 0, 0) 100%,
                rgba(0, 0, 0, 0) 0
            )
            100% 50% / 100% 100% repeat-x;
        -webkit-mask: var(--mask);
        mask: var(--mask);
    }

    &__item {
        background: $grey-card;
        height: 270px;
        overflow: hidden;
        position: relative;
        appearance: none;
        border: 0;
        padding: 0;

        &:first-child {
            height: auto;
        }

        & > img:not(.gallery-action-button) {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &:first-child {
            grid-column: 1 / span 2;

            & > img {
                object-fit: contain;
            }

            .gallery-action-button {
                display: block;
            }
        }
    }
}

.gallery-action-button {
    position: absolute;
    background: rgba($color: #ffffff, $alpha: 0.8);
    box-shadow: none;
    display: none;
    border-radius: 0.188rem;
    border: 5px transparent solid;
    box-sizing: border-box;
    height: 40px;
    width: 40px;

    &:focus {
        outline: none;
    }

    &:focus,
    &:hover {
        cursor: pointer;
        background: white;
    }

    &--expand {
        bottom: 1rem;
        right: 1rem;
    }

    &--close {
        top: 1rem;
        right: 1rem;
    }
}

.gallery-footer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    @media (max-width: $breakpoint-l) {
        display: none;
    }
}

.gallery-carousel {
    background: $grey-card;
    display: none;
    padding: 0 0 0 0;
    border-radius: 0.25rem;
    position: relative;

    @media (max-width: $breakpoint-l) {
        display: block;
    }

    &.hide-when-sold {
        @media (max-width: $breakpoint-l) {
            display: none;
        }
    }

    &__item {
        border-radius: 0.25rem;
        height: 284px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        & > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.carousel {
    --vc-pgn-width: 6px;
    --vc-pgn-height: 6px;
    --vc-pgn-border-radius: 6px;
    --vc-pgn-background-color: #{$light-bg};
    --vc-pgn-active-color: #{$dark-font};
    --vc-nav-color-hover: #{$light-bg};
}

.carousel__pagination {
    position: absolute;
    margin: 0;
    bottom: 0.5rem;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0;
    overflow: hidden;
    width: 100%;
}

.viewer-photos {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 100;
    padding: 2.5rem 5rem;
    overflow-y: auto;

    .gallery-action-button {
        display: flex;
        position: fixed;
        z-index: 50;
    }

    &__container {
        position: relative;
        width: 100%;
        max-width: 80rem;
        margin: 0 auto;
    }

    &__items {
        display: grid;
        gap: 1rem;
    }

    &__item {
        background: $grey-card;
        overflow: hidden;
        position: relative;

        & > img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__footer {
        position: sticky;
        bottom: 0;
        padding: 1rem 1.5rem;
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        margin: 0 auto;
        font-size: 1.25rem;
        color: $dark-font;
    }

    &-close-button {
        position: sticky;
        top: 1.5rem;
        right: 1.5rem;
    }

    @media (max-width: $breakpoint-m) {
        padding: 1rem;

        &__footer {
            display: none;
        }
    }
}

.gallery-container {
    position: relative;
    width: 100%;
}

.gallery-sold {
    pointer-events: none;
    /* Désactive les interactions avec la galerie si vendu */
}

.sold-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 20;
    pointer-events: none;
    padding: 1rem;

    @media (max-width: $breakpoint-m) {
        padding: 0.5rem;
    }
}

.sold-icon {
    max-width: 80px;
    width: 20%;
    // min-width: 80px;

    @media (max-width: $breakpoint-m) {
        max-width: 80px;
        min-width: 60px;
    }
}

.mobile-sold-image {
    display: none;
    background: $grey-card;
    border-radius: 0.25rem;
    overflow: hidden;

    @media (max-width: $breakpoint-l) {
        display: block;
        width: 100%;
        height: 284px; /* Same height as gallery-carousel__item */
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>
