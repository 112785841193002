<template>
    <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            d="M0 4.5V6h14.3v11.3H9.6A3 3 0 0 0 6.8 15a3 3 0 0 0-3 2.3H3v-3.8H1.5v5.3h2.4A3 3 0 0 0 6.8 21a3 3 0 0 0 2.8-2.3H16a3 3 0 0 0 2.8 2.3 3 3 0 0 0 3-2.3H24v-6.2L22.5 8l-.2-.5h-6.6v-3H0Zm.8 3V9h6.7V7.5H.7Zm15 1.5h5.4l1.3 3.8v4.4h-.9a3 3 0 0 0-2.9-2.2 3 3 0 0 0-2.8 2.3h-.2V9ZM1.4 10.5V12H6v-1.5H1.5Zm5.3 6c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.6 1.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5Zm12 0c.8 0 1.4.7 1.4 1.5s-.6 1.5-1.4 1.5c-.9 0-1.6-.7-1.6-1.5s.7-1.5 1.6-1.5Z"
            fill="#003C46"
        />
    </svg>
</template>
