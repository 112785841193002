<script setup lang="ts">
import { useI18n } from "vue-i18n";

defineProps<{
    entity?: string;
}>();

const { t } = useI18n();
</script>

<template>
    <ais-clear-refinements
        :included-attributes="[entity]"
        :class-names="{
            'ais-ClearRefinements': 'clear-filters-facet',
        }"
    >
        <template #default="{ canRefine, refine, createURL }">
            <a
                class="clear-filters-button"
                :href="createURL()"
                @click.prevent="refine"
                v-if="canRefine"
            >
                {{ t("actions.reset") }}
            </a>
        </template>
    </ais-clear-refinements>
</template>
