<script setup lang="ts">
import Account from "../../Layouts/Account.vue";
import Default from "../../Layouts/Default.vue";
import { useForm } from "@inertiajs/vue3";
import TextInput from "@/Components/Forms/Input/TextInput.vue";
import Button from "@/Components/Buttons/Button.vue";
import PhoneInput from "@/Components/Forms/Input/PhoneInput.vue";
import Accordion from "@/Components/Accordions/Accordion.vue";

import dayjs, { Dayjs } from "dayjs";
import { useI18n } from "vue-i18n";
import AddressInput from "@/Components/Forms/Input/AddressInput.vue";
import { Address, HTMLInputTypeAttribute } from "@/types";
import { useToast } from "vue-toastification";

defineOptions({ layout: [Default, Account] });

const props = defineProps<{
    currentUser: {
        firstname: string;
        lastname: string;
        email: string;
        phone: string;
        birthday_at: string;
        profile: {
            job?: string;
            contract?: string;
            fiscal_reference_income?: string;
            average_income?: string;
            monthly_charges?: string;
            credit_monthly_payment?: string;
            co_contractor?: {
                firstname?: string;
                lastname?: string;
                job?: string;
                birthday_at?: string;
            };
            address: Address;
            company: {
                director: string;
                name: string;
                siret: string;
                share_capital: number;
                lunch_at: string;
                sector: string;
                turnover: number;
                operating_result: number;
                monthly_leasing_charges: number;
                address: Address;
            };
        };
    };
}>();

const { t } = useI18n();
const toast = useToast();

let birthdayAt: Dayjs | null = null;
if (props.currentUser.birthday_at) {
    birthdayAt = dayjs(props.currentUser.birthday_at);
}
let coContractorBirthdayAt: Dayjs | null = null;
if (props.currentUser.profile.co_contractor?.birthday_at) {
    coContractorBirthdayAt = dayjs(
        props.currentUser.profile.co_contractor.birthday_at,
    );
}
let lunchAt: Dayjs | null = null;
if (
    props.currentUser.profile.company &&
    props.currentUser.profile.company.lunch_at
) {
    lunchAt = dayjs(props.currentUser.profile.company.lunch_at);
}

const form = useForm({
    ...props.currentUser,
    ...{
        // On formate la date pour que le navigateur l'affiche correctement
        birthday_at: birthdayAt?.format("YYYY-MM-DD") ?? "",
    },
});

const personalFinancialInfoForm = useForm({
    ...props.currentUser.profile,
    ...{
        co_contractor: {
            ...props.currentUser.profile.co_contractor,
            ...{
                birthday_at: coContractorBirthdayAt?.format("YYYY-MM-DD") ?? "",
            },
        },
    },
});

// Manipulation pour gérer les cas ou le profile n'a pas de compagny et ou la company n'as pas d'address
const company = props.currentUser.profile.company ?? {
    director: null,
    name: null,
    siret: null,
    share_capital: null,
    lunch_at: null,
    sector: null,
    turnover: null,
    operating_result: null,
    monthly_leasing_charges: null,
    address: null,
};

if (!company.address) {
    // company.address = null;
}

company.lunch_at = lunchAt?.format("YYYY-MM-DD") ?? "";

const companyForm = useForm(company);

type userFieldType = {
    attribute: string;
    type: HTMLInputTypeAttribute | "phone";
};

type fieldType = {
    attribute: string;
    type: HTMLInputTypeAttribute;
};

const userFields: userFieldType[] = [
    { attribute: "firstname", type: "text" },
    { attribute: "lastname", type: "text" },
    { attribute: "email", type: "email" },
    { attribute: "phone", type: "phone" },
    { attribute: "birthday_at", type: "date" },
];

const profileFields: fieldType[] = [
    { attribute: "job", type: "text" },
    { attribute: "contract", type: "text" },
    { attribute: "fiscal_reference_income", type: "number" },
    { attribute: "average_income", type: "number" },
    { attribute: "monthly_charges", type: "number" },
    { attribute: "credit_monthly_payment", type: "number" },
];

const coContractorField: fieldType[] = [
    { attribute: "firstname", type: "text" },
    { attribute: "lastname", type: "text" },
    { attribute: "job", type: "text" },
    { attribute: "birthday_at", type: "date" },
];

const companyField: fieldType[] = [
    { attribute: "director", type: "text" },
    { attribute: "name", type: "text" },
    { attribute: "siret", type: "text" },
    { attribute: "share_capital", type: "number" },
    { attribute: "lunch_at", type: "date" },
    { attribute: "sector", type: "text" },
    { attribute: "turnover", type: "number" },
    { attribute: "operating_result", type: "number" },
    { attribute: "monthly_leasing_charges", type: "number" },
];

const currentYear = new Date().getFullYear();

function submit() {
    form.post(route("user.update"), {
        onSuccess: () => toast.success(t("toast.success")),
        preserveScroll: true,
    });
}

function submitProfile() {
    personalFinancialInfoForm.post(route("user.updateProfile"), {
        onSuccess: () => toast.success(t("toast.success")),
        preserveScroll: true,
    });
}
function submitCompany() {
    companyForm.post(route("user.updateCompany"), {
        onSuccess: () => toast.success(t("toast.success")),
        preserveScroll: true,
    });
}
</script>

<template>
    <div>
        <Accordion :large="true" :open="true" icon="account">
            <template #header
                ><h1 class="account__title">
                    {{ t("account.informations") }}
                </h1></template
            >

            <template #default>
                <form @submit.prevent="submit">
                    <div class="account__form">
                        <component
                            v-for="{ attribute, type } in userFields"
                            :is="type === 'phone' ? PhoneInput : TextInput"
                            v-model="form[attribute]"
                            :label="t(`label.${attribute}`)"
                            :message="form.errors[attribute]"
                            :key="attribute"
                            :type="type"
                            :required="true"
                        />

                        <AddressInput
                            v-model="form.profile.address"
                            :label="t('label.address')"
                            :message="
                                form.errors['profile.address'] ||
                                form.errors['profile.address.line_1'] ||
                                form.errors['profile.address.city'] ||
                                form.errors['profile.address.country'] ||
                                form.errors['profile.address.postcode']
                            "
                        />
                    </div>
                    <Button type="submit" :disabled="form.processing">
                        {{ t("label.save") }}
                    </Button>
                </form>
            </template>
        </Accordion>

        <Accordion :large="true" :open="true" icon="account">
            <template #header
                ><h1 class="account__title">
                    Mon dossier de financement personnel
                </h1></template
            >

            <template #default>
                <form @submit.prevent="submitProfile">
                    <div class="account__form">
                        <TextInput
                            v-for="{ attribute, type } in profileFields"
                            v-model="personalFinancialInfoForm[attribute]"
                            :label="t(`profile.${attribute}`)"
                            :message="
                                personalFinancialInfoForm.errors[attribute]
                            "
                            :key="attribute"
                            :type="type"
                            v-bind="type === 'number' && { min: 0 }"
                        />
                        <TextInput
                            v-for="{ attribute, type } in coContractorField"
                            v-model="
                                personalFinancialInfoForm['co_contractor'][
                                    attribute
                                ]
                            "
                            :label="t(`co_contractor.${attribute}`)"
                            :message="
                                personalFinancialInfoForm.errors[
                                    `co_contractor.${attribute}`
                                ]
                            "
                            :key="attribute"
                            :type="type"
                        />
                    </div>
                    <Button
                        type="submit"
                        :disabled="personalFinancialInfoForm.processing"
                    >
                        {{ t("label.save") }}
                    </Button>
                </form>
            </template>
        </Accordion>

        <Accordion :large="true" :open="true" icon="account">
            <template #header
                ><h1 class="account__title">
                    Mon dossier de financement professionnel
                </h1></template
            >

            <template #default>
                <form @submit.prevent="submitCompany">
                    <div class="account__form">
                        <TextInput
                            v-for="{ attribute, type } in companyField"
                            v-model="companyForm[attribute]"
                            :label="
                                t(`company.${attribute}`, {
                                    currentYear: currentYear,
                                })
                            "
                            :message="companyForm.errors[attribute]"
                            :key="attribute"
                            :type="type"
                        />

                        <AddressInput
                            v-model="companyForm.address"
                            :label="t('company.address')"
                            :message="
                                companyForm.errors['address'] ||
                                companyForm.errors['address.line_1'] ||
                                companyForm.errors['address.city'] ||
                                companyForm.errors['address.country'] ||
                                companyForm.errors['address.postcode']
                            "
                        />
                    </div>
                    <Button type="submit" :disabled="companyForm.processing">
                        {{ t("label.save") }}
                    </Button>
                </form>
            </template>
        </Accordion>
    </div>
</template>

<style scoped lang="scss">
.accordion--account {
    margin-bottom: 1.5rem;
}

.account {
    &__title {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.2;
    }

    .input__field {
        flex: 0 0 calc(50% - 0.5rem);
    }

    @include medium-up {
        &__form {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
    @include large-up {
        &__form {
            display: block;
        }
    }
    @media (min-width: 75rem) {
        &__form {
            display: flex;
        }
    }
}
</style>
